import { FC, useEffect, useRef, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonText,
  IonList,
  IonIcon,
  IonListHeader,
  IonButtons,
  IonItemDivider,
  IonToggle
} from "@ionic/react";
import { arrowUndoCircleOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { RootState } from "../../reducers/rootReducer";
import { StatusItem } from "./items/StatusItem";
import { ModeloItem } from "./items/ModeloItem";
import { FrecuenciaItem } from "./items/FrecuenciaItem";
import { VoltajeNominalItem } from "./items/VoltajeNominalItem";
import { CorrienteNominalItem } from "./items/CorrienteNominalItem";
import { UbicacionItem } from "./items/UbicacionItem";
import { FechaInstalacionItem } from "./items/FechaInstalacionItem";
import { MarcaItem } from "./items/MarcaItem";
import { CapacidadItem } from "./items/CapacidadItem ";
import { TablerosItem } from "./items/TablerosItem";
import { ProteccionesItem } from "./items/ProteccionesItem";
import { MedicionesACItem } from "./items/MedicionesACItem";
import { MedicionesCCItem } from "./items/MedicionesCCItem";
import { CanalizacionesItem } from "./items/CanalizacionesItem";
import { AlimentadoresACItem } from "./items/AlimentadoresACItem";
import { AlimentadoresCCItem } from "./items/AlimentadoresCCItem";
import { CircuitosMediaTensionItem } from "./items/CircuitosMediaTensionItem";
import { TransformadoresMedicionItem } from "./items/TransformadoresMedicionItem";
import { TransformadoresItem } from "./items/TransformadoresItem";
import { GeneradoresItem } from "./items/GeneradoresItem";
import { TransferenciasItem } from "./items/TransferenciasItem";
import { startLoadImages } from '../../actions/images';
import { ImagesItem } from './items/ImagesItem';
import { BancoBateriasItem } from "./items/BancoBateriasItem";
import { RectificadorItem } from "./items/RectificadorItem";
import { InversorItem } from "./items/InversorItem";
import { UpsItem } from "./items/UpsItem";
import { AireAcondicionadoItem } from "./items/AireAcondicionadoItem";
import { CeldasMtoItem } from "./items/CeldasMtoItem";
import { TagItem } from "./items/TagItem";
import { DescriptionItem } from "./items/DescriptionItem";
import { NumeroItem } from "./items/NumeroItem";
import { UnidadesItem } from './items/UnidadesItem';
import { ObservacionesItem } from "./items/ObservacionesItem";
import { setActiveLoading } from "../../actions/ui";
import { startEquipoUpdated } from "../../actions/equipos";


export const EquipoFullScreen: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const componentMounted = useRef(true);
  useEffect(() => {
    if (componentMounted.current) {
      dispatch(startLoadImages());
    }
    return () => {
      componentMounted.current = false
    }

  }, [dispatch])

  const { activeEquipo: { tag, nombre_equipo, central, id, descripcion_equipo, type, etiqueta, numero_equipo} } = useSelector((state: RootState) => state.equipo)

  const [checked, setChecked] = useState(etiqueta);

  const equipo = {
    tag: tag,
    nombre_equipo: nombre_equipo,
    descripcion_equipo: descripcion_equipo,
    central: central,
    numero_equipo: numero_equipo,
    type: type,
    etiqueta: etiqueta,
  };
  
  const handeUpdate = (e: any) =>{ 
    setChecked(e);
    equipo.etiqueta = e;
    dispatch(setActiveLoading());
    dispatch(startEquipoUpdated(equipo));
  };


  const goBack = () => {
    history.replace('/equipos')    
  };

  return (
    <>
      <IonList>
        <IonListHeader>
          <IonLabel class="title">Equipo {tag}</IonLabel>
          <IonButtons>
            <IonButton onClick={goBack}><IonIcon className="icon-list" color="primary" icon={arrowUndoCircleOutline}></IonIcon></IonButton>
          </IonButtons>
        </IonListHeader>
        <IonItemDivider>
          <IonLabel>
            Información General
          </IonLabel>
        </IonItemDivider>
        <TagItem />
        <IonItem>
          <IonLabel className="item-label" position="stacked" color="primary">
            Nombre del Equipo
          </IonLabel>
          <IonText><p>{nombre_equipo}</p></IonText>
        </IonItem>
        <DescriptionItem />
        <NumeroItem />
        <IonItem>
          <IonLabel className="item-label" position="stacked" color="primary">Central</IonLabel>
          <IonText><p>{central}</p></IonText>
        </IonItem>
        <ImagesItem idEquipo={id} history={history} />
        <UbicacionItem />
        <StatusItem />
        <FechaInstalacionItem />
        <IonItem>
            <IonLabel className="item-label" position="stacked" color="primary">Etiqueta</IonLabel>
            <IonToggle checked={checked} onIonChange={(e) => handeUpdate(e.detail.checked)} />
          </IonItem>
        <MarcaItem />
        <ModeloItem />
        <CapacidadItem />
        <UnidadesItem />
        <CorrienteNominalItem />
        <VoltajeNominalItem />
        <FrecuenciaItem />
        <ObservacionesItem />
        <IonItemDivider>
          <IonLabel>
            Información Items
          </IonLabel>
        </IonItemDivider>
        {type === 'TABLEROS' && <TablerosItem />}
        {type === 'INTERRUPTORES' && <ProteccionesItem />}
        {type === 'CELDAS_MT' && <CeldasMtoItem />}
        {type === 'CANALIZACIÓN' && (<>        
        <MedicionesACItem />
        <MedicionesCCItem />
        <CanalizacionesItem />
        <AlimentadoresACItem />
        <AlimentadoresCCItem />
        </>
        )}
        {type === 'CTO_MT' && <CircuitosMediaTensionItem />}
        {type === 'CELDAS_MEDIDA' && <TransformadoresMedicionItem />}
        {type === 'TRANSFORMADOR' && <TransformadoresItem /> }
        {type === 'GENERADOR' &&  <GeneradoresItem />}
        {type === 'RECTIFICADOR' && <RectificadorItem />}
        {type === 'ATS_TRANSFERENCIAS' && <TransferenciasItem />}
        {type === 'INVERSOR' && <InversorItem />}
        {type === 'UPS' && <UpsItem />}
        {type === 'BANCO_BATERIAS' && <BancoBateriasItem />}   
        {type === 'ACONDICIONADO' && <AireAcondicionadoItem />} 
      </IonList>

    </>
  );
};