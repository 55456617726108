import { IonImg, IonItem, IonLabel, IonCol, IonButtons, IonSpinner } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/rootReducer";
import { IonRow, IonButton, IonIcon } from "@ionic/react";
import { cameraOutline } from "ionicons/icons";

interface Props {
  idEquipo: string;
  history: any;
}
export const ImagesItem = ({ idEquipo, history }: Props) => {
  const { images } = useSelector((state: RootState) => state.image);
  const equipoImages = images.filter((image: any) => image.equipo === idEquipo);
  const showImages = () => {
    history.push("/gallery");
  };
  const goImages = () => {
    history.push("/images");
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Imagenes
      </IonLabel>
      {equipoImages.length > 0 ? (
        <IonRow>
          {equipoImages.map((image: any) => (
            <IonCol size="3" key={image.id}>              
              <IonImg src={image.uri} onClick={showImages} />
            </IonCol>
          ))}
        </IonRow>
      ) : (
        <IonItem lines="none">
          <IonButtons>
            <IonButton onClick={goImages}>
              <IonIcon
                className="icon-list"
                color="primary"
                icon={cameraOutline}
              ></IonIcon>
            </IonButton>
          </IonButtons>
          <IonLabel className="item-label" position="fixed">Agregar Imagen</IonLabel>
        </IonItem>
      )}
    </IonItem>
  );
};
