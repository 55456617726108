import {BaseComponent} from '../components/base/BaseComponent';
import { EquipoListScreen } from '../components/equipo/EquipoListScreen';
interface ContainerProps {
  title?: string;
}
const Equipos: React.FC<ContainerProps> = () => {
  return (
    <BaseComponent title="Equipos" component={EquipoListScreen}  />
  );
};

export default Equipos;
