import { types } from "../types/types";

const initialState = {
  equipos: [],
  activeEquipo: null,  
};

export const equipoReducer = (state = initialState, action: any) => {
  switch (action.type) {    
    case types.equipoStartLoad:
      return {
        ...state,
        equipos: action.payload,
      };

    case types.equipoStartAdd:
      return {
        ...state,
        equipos: [...state.equipos, action.payload],
      };

    case types.equipoSetActive:
      return {
        ...state,
        activeEquipo: action.payload,
      };

    case types.equipoClearActive:
      return {
        ...state,
        activeEquipo: null,
      };

    case types.equipoStartUpdated:
      return {
        ...state,
        equipos: state.equipos.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };

    case types.equipoStartDeleted:
      return {
        ...state,
        equipos: state.equipos.filter((e) => e.id !== state.activeEquipo.id),
        activeEquipo: null,
      };

    case types.equipoCleared:
      return {
        equipos: [],
        activeEquipo: null,
      };

    default:
      return state;
  }
};
