import { useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonThumbnail,
  IonIcon,
  IonImg,
  IonModal,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../reducers/rootReducer";
import { startLoadCentrales } from "../../actions/central";
import {
  setActiveLoading,
  setModalEquipoHide,
  setToast,
  setInactiveLoading,
} from "../../actions/ui";
import { equipoStartAddNew } from "../../actions/equipos";
import { startLoadTypes } from "../../actions/types";
import { startLoadLabels } from "../../actions/labels";
import { cameraOutline, closeOutline } from "ionicons/icons";
import { usePhoto } from "../../hooks/usePhoto";
import { startClearedLocalImages } from "../../actions/images";

const initEquipo = {
  tag: "-K00X",
  nombre_equipo: "",
  descripcion_equipo: "",
  central: "",
  numero_equipo: "",
  type: "",
};

export const EquipoScreen = ({history}: any) => {    
  const dispatch = useDispatch(); 
  useEffect(() => {   
    dispatch(startLoadCentrales());
    dispatch(startLoadTypes());
    dispatch(startLoadLabels());
  }, [dispatch]);

  const {equipoModal} = useSelector((state: RootState) => state.ui);
  const { centrales } = useSelector((state: RootState) => state.central);
  const { types } = useSelector((state: RootState) => state.type);
  const { labels } = useSelector((state: RootState) => state.label);
  const {activeEquipo} = useSelector((state: RootState) => state.equipo);
  const { localImages } = useSelector((state: RootState) => state.image);  
  const [formValues, setFormValues] = useState(initEquipo);
  const {
    tag,
    nombre_equipo,
    type,
    numero_equipo,
    central,
    descripcion_equipo,
  } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const { takePhoto } = usePhoto();

  useEffect(() => {
    if (activeEquipo && activeEquipo.id === "a1") {
      setFormValues(initEquipo);
    }
  }, [activeEquipo]);
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    let toast = {};
    if (tag.length <= 3) {
      toast = {
        msg: "El tag es un campo obligatorio",
        color: "warning",
      };
      dispatch(setInactiveLoading());
      dispatch(setToast(toast));
      return;
    }
    if (nombre_equipo.length <= 2) {
      toast = {
        msg: "El nombre de equipo es un campo obligatorio",
        color: "warning",
      };
      dispatch(setInactiveLoading());
      dispatch(setToast(toast));
      return;
    }
    if (numero_equipo.length <= 1) {
      toast = {
        msg: "El numero de equipo es un campo obligatorio",
        color: "warning",
      };
      dispatch(setInactiveLoading());
      dispatch(setToast(toast));
      return;
    }
    if (central.length <= 3) {
      toast = {
        msg: "La central es un campo obligatorio",
        color: "warning",
      };
      dispatch(setInactiveLoading());
      dispatch(setToast(toast));
      return;
    }
    dispatch(equipoStartAddNew(formValues));
    history.push("/equipo");   
  };

  const setShowModal = () => {
    setFormValues(initEquipo);
    dispatch(startClearedLocalImages());
    dispatch(setModalEquipoHide());
  };
  return (
    <IonModal cssClass="modal-equipo" isOpen={equipoModal} backdropDismiss={false}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>Registrar Equipo</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={setShowModal}>
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="marca-container">
        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              Tag
            </IonLabel>
            <IonInput
              type="text"
              name="tag"
              value={tag}
              onIonChange={handleInputChange}
              placeholder="Tag"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Nombre Equipo</IonLabel>
            <IonSelect
              name="nombre_equipo"
              value={nombre_equipo}
              onIonChange={handleInputChange}
            >
              {labels.map((label: any) => (
                <IonSelectOption key={label.id} value={label.name}>
                  {label.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              Numero Equipo
            </IonLabel>
            <IonInput
              type="text"
              name="numero_equipo"
              value={numero_equipo}
              onIonChange={handleInputChange}
              autocomplete="off"
              placeholder="Numero equipo"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              Descripción Equipo{" "}
            </IonLabel>
            <IonTextarea
              name="descripcion_equipo"
              value={descripcion_equipo}
              onIonChange={handleInputChange}
              placeholder="Descripción de equipo"
            ></IonTextarea>
          </IonItem>
          <IonItem>
            <IonLabel>Central</IonLabel>
            <IonSelect
              name="central"
              value={central}
              onIonChange={handleInputChange}
            >
              {centrales.map((central: any) => (
                <IonSelectOption key={central.id} value={central.name}>
                  {central.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>Tipo</IonLabel>
            <IonSelect name="type" value={type} onIonChange={handleInputChange}>
              {types.map((t: any) => (
                <IonSelectOption key={t.id} value={t.name}>
                  {t.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem button onClick={takePhoto}>
            <IonThumbnail slot="start">
              {localImages.length > 0 ? (
                <IonImg
                  alt={localImages[0].description}
                  src={localImages[0].image}
                />
              ) : (
                <IonImg src="assets/icon/logo.png" alt="logo beling" />
              )}
            </IonThumbnail>
            <IonLabel>
              <h3>Agregar imagen</h3>
            </IonLabel>
            <IonIcon color="primary" icon={cameraOutline} slot="end" />
          </IonItem>
          <IonItem lines="none" className="marca-item">
            <IonButton slot="end" fill="clear" type="submit">
              Guardar
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={setShowModal}>
              Cerrar
            </IonButton>
          </IonItem>
        </form>
      </IonContent>
    </IonModal>
  );
};
