import {BaseComponent} from '../components/base/BaseComponent';
import { EquipoFullScreen } from '../components/equipo/EquipoFullScreen';

interface ContainerProps {
  title?: string;
}
const Equipo: React.FC<ContainerProps> = () => { 
  return (
    <BaseComponent title="Equipo" component={EquipoFullScreen}  />    
  );
};

export default Equipo;