import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setFechaInstalacionEditHide, setFechaInstalacionEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

export const FechaInstalacionItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { fecha_instalacion } } = useSelector((state: RootState) => state.equipo);
  const { editFechaInstalacion } = useSelector((state: RootState) => state.ui);
  const fechaInstalacion = moment(fecha_instalacion).format('DD/MM/YYYY');
  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setFechaInstalacionEditShow())

  }
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
          <IonLabel className="item-label" position="stacked" color="primary">
            Fecha de Instalación
            </IonLabel>
          </IonCol>
        </IonRow>
       <IonRow>
       <IonCol size='9' size-sm>
        <IonLabel>{fechaInstalacion}</IonLabel>
        </IonCol>
        <IonCol size='3' size-sm className="ion-align-self-center">
        <IonButton color="white" fill='clear' onClick={handleUpdate}>
            <IonIcon slot="icon-only" color="success" size='large' icon={createOutline} />
          </IonButton>
        </IonCol>
       </IonRow>
      </IonGrid>
      {editFechaInstalacion && <FechaInstalacionModal />}
    </IonItem>
  )
}

const FechaInstalacionModal: FC = () => {
  const dispatch = useDispatch();
  const { editFechaInstalacion } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    fecha_instalacion: activeEquipo.fecha_instalacion
  }
  const { fecha_instalacion } = activeEquipo;
  const fechaInstalacion = moment(fecha_instalacion).format('YYYY-MM-DD')
 console.log(fechaInstalacion)
  const handleUpdate = ({ name }) => {
    dispatch(setActiveLoading());
    equipo.fecha_instalacion = name
    dispatch(startEquipoUpdated(equipo));
    dispatch(setFechaInstalacionEditHide())
  };

  const handleClose = () => {
    dispatch(setFechaInstalacionEditHide());
  };
  return (
    <IonContent>
      <IonAlert
        isOpen={editFechaInstalacion}
        onDidDismiss={handleClose}
        header={'Fecha Instalación'}
        inputs={[
          {
            name: 'name',
            type: 'date',
            placeholder: 'Fecha instalación',
            value: fechaInstalacion
          },
        ]}
        buttons={[
          {
            text: 'Guardar',
            handler: (e) => {
              handleUpdate(e);
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }          
        ]}
      />
    </IonContent>
  );
}
