import { IonAlert, IonButton, IonContent, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonInput, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react';
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setTablerosEditHide, setTablerosEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  numero_polos: "",
  numero_hilos: "",
  corriente_corto_circuito: ""
}
export const TablerosItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editTableros } = useSelector((state: RootState) => state.ui);
  const { numero_polos = "", numero_hilos = "", corriente_corto_circuito = "" } = activeEquipo;
  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setTablerosEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Tableros
      </IonLabel>
      {(activeEquipo.type === 'TABLEROS' && activeEquipo.numero_polos) && (<IonList>
        <IonItem lines="none">
          <IonText># Polos</IonText>
          <IonNote slot="end">{numero_polos}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># Hilos</IonText>
          <IonNote slot="end">{numero_hilos}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte corto cto</IonText>
          <IonNote slot="end">{corriente_corto_circuito}</IonNote>
        </IonItem>
      </IonList>)}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{(activeEquipo.type === 'TABLEROS' && activeEquipo.numero_polos) ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editTableros && <TablerosModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const TablerosModal: FC = () => {
  const dispatch = useDispatch();

  const { editTableros } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { numero_polos, numero_hilos, corriente_corto_circuito } =
    formValues;
  useEffect(() => {
    if (activeEquipo.generador) {
      const aac = {
        numero_polos: activeEquipo.numero_polos,
        numero_hilos: activeEquipo.numero_hilos,
        corriente_corto_circuito: activeEquipo.corriente_corto_circuito,
      };
      setFormValues(aac);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo]);
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    numero_polos: activeEquipo.numero_polos,
    numero_hilos: activeEquipo.numero_hilos,
    corriente_corto_circuito: activeEquipo.corriente_corto_circuito,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.numero_polos = numero_polos;
    equipo.numero_hilos = numero_hilos;
    equipo.corriente_corto_circuito = corriente_corto_circuito;
    dispatch(startEquipoUpdated(equipo));
    dispatch(setTablerosEditHide());
  };

  const handleClose = () => {
    dispatch(setTablerosEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editTableros} cssClass="modal-change-password">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Tableros</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
              Número polos
              </IonLabel>
              <IonInput
                type="number"
                name="numero_polos"
                value={numero_polos}
                onIonChange={handleInputChange}
                placeholder="Número polos"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número hilos{" "}
              </IonLabel>
              <IonInput
                type="text"
                name="numero_hilos"
                value={numero_hilos}
                onIonChange={handleInputChange}
                placeholder="Número hilos"
                inputMode="text"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Cte cto circuito
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_corto_circuito"
                value={corriente_corto_circuito}
                onIonChange={handleInputChange}
                placeholder="Cte cto circuito"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};