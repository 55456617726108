import { FC, useEffect } from "react";
import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonText } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { addCircleOutline, chevronForwardOutline, createOutline, trashOutline } from 'ionicons/icons';
import { RootState } from "../../reducers/rootReducer";
import { setModalCanalShow, startSetActiveAlert } from "../../actions/ui";
import { Canalizacion } from "../../interfaces/Canalizacion";
import { canalizacionSetActive, clearActiveCanalizacion, startLoadCanalizaciones } from "../../actions/canalizaciones";
import { AlertComponent } from "../ui/AlertComponent";

export const CanalizacionList: FC = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(startLoadCanalizaciones());
    },[dispatch])
    const { canalizaciones } = useSelector((state: RootState) => state.canalizacion);  

    const handleDelete = (canalizacion: Canalizacion) => {
        
        const alert ={
            active: true,
            header: "Eliminar canalización",
            message: `La canalización ${canalizacion.name} será eliminada`,   
            tipo: 'canalizacion'         
        }
        dispatch(startSetActiveAlert(alert)) 
        dispatch(canalizacionSetActive(canalizacion));        
        
    }

    const handleUpdate =(canalizacion: Canalizacion) => {
        dispatch(canalizacionSetActive(canalizacion));
        dispatch(setModalCanalShow())

    };

    const onDesactive = () => {
        dispatch(clearActiveCanalizacion());
    };

    const handleAdd = () => {
        dispatch(setModalCanalShow());
    };

    return (
        <>
            <IonList>
                <IonListHeader>
                    <IonLabel class="title">Canalizaciones</IonLabel>
                    <IonButton onClick={handleAdd}><IonIcon className="icon-list" color="success" icon={addCircleOutline}></IonIcon></IonButton>
                </IonListHeader>

                {
                    canalizaciones.map((c: any ) => 
                       (<IonItemSliding key={c.id}>
                            <IonItem>
                                <IonText><h6>{c.name}</h6></IonText>
                            <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
                            </IonItem>
                            <IonItemOptions side="end">
                            <IonItemOption color="white" onClick={()=>{handleUpdate(c)}}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
                            <IonItemOption color="white" onClick={()=>{handleDelete(c)}}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
                            </IonItemOptions>
                        </IonItemSliding>
                       )
                    )
                }
            </IonList>
            <AlertComponent onDesactive={onDesactive} />
        </>
    );
}
