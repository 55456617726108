import { types } from "../types/types";

const initialSate ={
    marcas: [],
    activeMarca: null
}

export const marcaReducer = (state = initialSate, action: any) => {
    switch (action.type) {
        case types.marcaStartLoad:
            return {
                ...state,
                marcas: action.payload
            };
        case types.marcaStartAdd:
            return {
                ...state,
                marcas: [
                    ...state.marcas,
                    action.payload
                ]

            }

        case types.marcaSetActive:
            return {
                ...state,
                activeMarca: action.payload
            };
        
        case types.marcaClearActive:
            return {
                ...state,
                activeMarca:null
            };

        case types.marcaStartUpdated:
            return {
                ...state,
                marcas: state.marcas.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            };
        
        case types.marcaStartDeleted:
            return {
                ...state,
                marcas: state.marcas.filter(c => c.id !== state.activeMarca.id),
                activeMarca : null
            };
        
        case types.marcaCleared:
            return {
                marcas: [],
                activeMarca: null
            };

        default:
            return state;
    }
};