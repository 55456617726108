import { useIonToast } from "@ionic/react";

import {
  Camera,
  CameraResultType,
  CameraSource,
  CameraDirection,
} from "@capacitor/camera";
import { useDispatch, useSelector } from "react-redux";
import { loadLocalImages, localImageDelete, startSaveImages, startAddLocalImageDescription } from '../actions/images';
import { RootState } from "../reducers/rootReducer";
import { useEffect } from "react";
import { equipoSetActive } from '../actions/equipos';

export const usePhoto = () => {
    const dispatch = useDispatch();
    const [present] = useIonToast();
    const {localImages, activeImageLocal } = useSelector((state: RootState) => state.image)
    const {toastFacil} = useSelector((state: RootState) => state.ui)
    const {activeEquipo} = useSelector((state: RootState) => state.equipo) 
    useEffect(() => {
       if(!activeEquipo){
        dispatch(equipoSetActive({id: "a1", nombre_equipo:"a1",central: "", numero_equipo: ""}));
       }
    }, [dispatch, activeEquipo])
    //const {nombre_equipo, id} = activeEquipo;
    useEffect(() => {
      if(toastFacil.present){
        present({
          header: toastFacil.header,
          message: toastFacil.msg,
          color: toastFacil.color,
          duration: 2000,          
          position: "middle",      
        })
      }
    });
    const takePhoto = async () => {
        try {
          const photo = await Camera.getPhoto({
            resultType: CameraResultType.DataUrl,
            allowEditing: true,
            source: CameraSource.Prompt,
            quality: 60,
            direction:CameraDirection.Rear,
          });
          if(photo){
            const image = photo && photo.dataUrl;
            const data ={              
              description: '',
              image
            }
            dispatch(loadLocalImages(data))
          }
        } catch (err) {
          present({
            message: 'Contacta al administrador',
            duration: 1000,
            position: "middle",
            color: "warning"
          })
          return;
        }
      };

      const savePicture = async () => {
        if(localImages.length > 0){
          await localImages.map((i: any) => {
            const data = {
              equipo: activeEquipo.id,
              nombre_equipo: activeEquipo.nombre_equipo,
              description: i.description,
              image: i.image,
            }
            dispatch(startSaveImages(data))
            return i
          })      
          return true
        }else{
          present({
            message: 'Contacta al administrador',
            duration: 1000,
            position: "middle",
            color: "warning"
          })
        }
      };

  const addImageDescription = (description: string, image: string) => {
    dispatch(startAddLocalImageDescription({image, description}))
  }      
      const deletePicture = async () => {
        let index = localImages.findIndex((item: any) => item ===activeImageLocal);
        let oldImages = localImages;
        oldImages.splice(index, 1);      
        try {
          dispatch(localImageDelete(oldImages))
        } catch (error) {
          present({
              message: 'La imagen ya ha sido eliminada',
              duration: 1000,
              position: "middle",
              color: "warning"
            })
        }   
    };

      return{
          takePhoto,
          savePicture,
          deletePicture,
          addImageDescription
      }
}