import { FC, useEffect } from "react";
import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonText } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { addCircleOutline, chevronForwardOutline, createOutline, trashOutline } from 'ionicons/icons';
import { RootState } from "../../reducers/rootReducer";
import { setModalTypeShow, startSetActiveAlert } from "../../actions/ui";
import {startLoadTypes, typeSetActive, clearActiveType} from '../../actions/types'
import { Type } from "../../interfaces/Type";
import { AlertComponent } from "../ui/AlertComponent";

export const TypeListScreen: FC = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(startLoadTypes());
    },[dispatch])

    const { types } = useSelector((state: RootState) => state.type);

    const handleDelete = (type: Type) => {
        const alert ={
            active: true,
            header: "Eliminar tipo",
            message: `El tipo ${type.name} será eliminado`,
            tipo: 'types'         
        }
        dispatch(startSetActiveAlert(alert)) 
        dispatch(typeSetActive(type));
    }

    const handleUpdate =(type: Type) => {
        dispatch(typeSetActive(type));
        dispatch(setModalTypeShow())
    };

    const onDesactive = () => {
        dispatch(clearActiveType());
    };

    const handleAdd = () => {
        dispatch(setModalTypeShow());
    };

    return (
        <>
            <IonList>
                <IonListHeader>
                    <IonLabel class="title">Tipos</IonLabel>
                    <IonButton onClick={handleAdd}><IonIcon className="icon-list" color="success" icon={addCircleOutline}></IonIcon></IonButton>
                </IonListHeader>

                {
                    types.map((c: any ) => 
                       (<IonItemSliding key={c.id}>
                            <IonItem>
                                <IonText><h6>{c.name}</h6></IonText>
                            <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
                            </IonItem>
                            <IonItemOptions side="end">
                            <IonItemOption color="white" onClick={()=>{handleUpdate(c)}}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
                            <IonItemOption color="white" onClick={()=>{handleDelete(c)}}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
                            </IonItemOptions>
                        </IonItemSliding>
                       )
                    )
                }
            </IonList>
            <AlertComponent onDesactive={onDesactive} />
        </>
    );
}
