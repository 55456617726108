import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonModal,
  IonNote,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadCanalizaciones } from "../../../actions/canalizaciones";
import { startEquipoUpdated } from "../../../actions/equipos";
import {
  setActiveLoading,
  setCanalizacionesEditHide,
  setCanalizacionesEditShow,
  startSetDesdeShow,
  startSetHastaShow,
} from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";
import { IonList } from '@ionic/react';
import { CanalizacionDesde } from "./CanalizacionDesde";
import { CanalizacionHasta } from "./CanalizacionHasta";

const initForm = {
  canalizacion: "",
  desde: "",
  hasta: "",
  numero_ductos_diametro: "",
  potencia_aparente: "",
  potencia_activa: "",
  potencia_reactiva: "",
  fp_carga_01: "",
};

export const CanalizacionesItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editCanalizaciones, desdeShow, hastaShow } = useSelector((state: RootState) => state.ui);
  const { canalizacion = {}, potencia_activa, potencia_reactiva, fp_carga_01, central } = activeEquipo
  const { canalizacion: canaliza = "", desde = {}, hasta = {}, numero_ductos_diametro = "", potencia_aparente } = canalizacion;
  const handleDelete = () => {
    console.log("click");
  };
  const handleUpdate = () => {
    dispatch(setCanalizacionesEditShow());
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Canalizaciones
      </IonLabel>
      {Object.keys(canalizacion).length !== 0 && <IonList>
        {
          central !== 'Salitre' ? (
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  <IonCol size="8">
                    <IonText title="Desde">Desde</IonText>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton fill="clear" onClick={() => dispatch(startSetDesdeShow())} title="Editar">
                      <IonIcon title="Editar" slot="icon-only" color="success" size='large' icon={createOutline} />
                    </IonButton>
                  </IonCol>
                  {desdeShow && <CanalizacionDesde />}
                </IonRow>
                {desde.salon && (
                  <IonRow>
                    <IonCol>
                      <IonText>Salón</IonText>
                    </IonCol>
                    <IonCol>
                      <IonNote>{desde.salon}</IonNote>
                    </IonCol>
                  </IonRow>
                )}
                {desde.tablero && (
                  <IonRow>
                    <IonCol>
                      <IonText>Tablero</IonText>
                    </IonCol>
                    <IonCol>
                      <IonNote>{desde.tablero}</IonNote>
                    </IonCol>
                  </IonRow>
                )}
                {
                  desde.bastidor && (
                    <IonRow>
                      <IonCol>
                        <IonText>Bastidor</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{desde.bastidor}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  desde.posicion && (
                    <IonRow>
                      <IonCol>
                        <IonText>Posición</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{desde.posicion}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  desde.fila && (
                    <IonRow>
                      <IonCol>
                        <IonText>Fila</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{desde.fila}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  desde.pdu && (
                    <IonRow>
                      <IonCol>
                        <IonText>Pdu</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{desde.pdu}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  desde.capacidad && (
                    <IonRow>
                  <IonCol>
                    <IonText>Capacidad</IonText>
                  </IonCol>
                  <IonCol>
                    <IonNote>{desde.capacidad}</IonNote>
                  </IonCol>
                </IonRow>
                  )
                }
                {
                  desde.fuente && (
                    <IonRow>
                  <IonCol>
                    <IonText>Fuente</IonText>
                  </IonCol>
                  <IonCol>
                    <IonNote>{desde.fuente}</IonNote>
                  </IonCol>
                </IonRow>
                  )
                }
              </IonGrid>
            </IonItem>
          ) : (
            <IonItem lines="none">
              <IonText>Desde</IonText>
              <IonNote slot="end">{desde}</IonNote>
            </IonItem>
          )
        }
        {
          central !== 'Salitre' ? (
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  <IonCol size="8">
                    <IonText title="Desde">Hasta</IonText>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton fill="clear" onClick={() => dispatch(startSetHastaShow())} title="Editar">
                      <IonIcon title="Editar" slot="icon-only" color="success" size='large' icon={createOutline} />
                    </IonButton>
                  </IonCol>
                  {hastaShow && <CanalizacionHasta />}
                </IonRow>
                {hasta.salon && (
                  <IonRow>
                    <IonCol>
                      <IonText>Salón</IonText>
                    </IonCol>
                    <IonCol>
                      <IonNote>{hasta.salon}</IonNote>
                    </IonCol>
                  </IonRow>
                )}
                {hasta.tablero && (
                  <IonRow>
                    <IonCol>
                      <IonText>Tablero</IonText>
                    </IonCol>
                    <IonCol>
                      <IonNote>{hasta.tablero}</IonNote>
                    </IonCol>
                  </IonRow>
                )}
                {
                  hasta.bastidor && (
                    <IonRow>
                      <IonCol>
                        <IonText>Bastidor</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{hasta.bastidor}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  hasta.posicion && (
                    <IonRow>
                      <IonCol>
                        <IonText>Posición</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{hasta.posicion}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  hasta.fila && (
                    <IonRow>
                      <IonCol>
                        <IonText>Fila</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{hasta.fila}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  hasta.pdu && (
                    <IonRow>
                      <IonCol>
                        <IonText>Pdu</IonText>
                      </IonCol>
                      <IonCol>
                        <IonNote>{hasta.pdu}</IonNote>
                      </IonCol>
                    </IonRow>
                  )
                }
                {
                  hasta.capacidad && (
                    <IonRow>
                  <IonCol>
                    <IonText>Capacidad</IonText>
                  </IonCol>
                  <IonCol>
                    <IonNote>{hasta.capacidad}</IonNote>
                  </IonCol>
                </IonRow>
                  )
                }
                {
                  hasta.fuente && (
                    <IonRow>
                  <IonCol>
                    <IonText>Fuente</IonText>
                  </IonCol>
                  <IonCol>
                    <IonNote>{hasta.fuente}</IonNote>
                  </IonCol>
                </IonRow>
                  )
                }
              </IonGrid>
            </IonItem>
          ) : (
            <IonItem lines="none">
              <IonText>Hasta</IonText>
              <IonNote slot="end">{hasta}</IonNote>
            </IonItem>
          )
        }
        <IonItem lines="none">
          <IonText>Canalización</IonText>
          <IonNote slot="end">{canaliza.name}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Número ductos</IonText>
          <IonNote slot="end">{numero_ductos_diametro}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Potencia aparente</IonText>
          <IonNote slot="end">{potencia_aparente}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Potencia activa</IonText>
          <IonNote slot="end">{potencia_activa}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Potencia reactiva</IonText>
          <IonNote slot="end">{potencia_reactiva}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>FP carga 01</IonText>
          <IonNote slot="end">{fp_carga_01}</IonNote>
        </IonItem>
      </IonList>}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(canalizacion).length !== 0 ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editCanalizaciones && <CanalizacionModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption
            color="light"
            onClick={() => {
              handleUpdate();
            }}
          >
            <IonIcon
              className="icon-list"
              color="success"
              ariaLabel="Editar"
              title="Editar"
              icon={createOutline}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="light"
            onClick={() => {
              handleDelete();
            }}
          >
            <IonIcon
              className="icon-list"
              color="danger"
              icon={trashOutline}
            ></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  );
};

const CanalizacionModal: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadCanalizaciones());
  }, [dispatch]);

  const { canalizaciones } = useSelector(
    (state: RootState) => state.canalizacion
  );
  const { editCanalizaciones } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { canalizacion, numero_ductos_diametro, potencia_aparente, potencia_activa,
    potencia_reactiva, fp_carga_01 } = formValues;

  useEffect(() => {
    if (activeEquipo.canalizacion) {
      const canalizacion = {
        canalizacion: activeEquipo.canalizacion.canalizacion._id,
        desde: activeEquipo.canalizacion.desde,
        hasta: activeEquipo.canalizacion.hasta,
        numero_ductos_diametro: activeEquipo.canalizacion.numero_ductos_diametro,
        potencia_aparente: activeEquipo.canalizacion.potencia_aparente,
        potencia_activa: activeEquipo.potencia_activa,
        potencia_reactiva: activeEquipo.potencia_reactiva,
        fp_carga_01: activeEquipo.fp_carga_01,
      };
      setFormValues(canalizacion);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo.canalizacion]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    potencia_activa: activeEquipo.potencia_activa,
    potencia_reactiva: activeEquipo.potencia_reactiva,
    fp_carga_01: activeEquipo.fp_carga_01,
    canalizacion: activeEquipo.canalizacion,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.canalizacion = {
      canalizacion: canalizacion,
      numero_ductos_diametro: numero_ductos_diametro,
      potencia_aparente: potencia_aparente,
    };
    equipo.potencia_activa = potencia_activa;
    equipo.potencia_reactiva = potencia_reactiva;
    equipo.fp_carga_01 = fp_carga_01;
    dispatch(startEquipoUpdated(equipo));
    dispatch(setCanalizacionesEditHide());
  };

  const handleClose = () => {
    dispatch(setCanalizacionesEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editCanalizaciones} cssClass="modal-equipo">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Canalizaciones</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem lines="none">
              <IonLabel>Tipo canalizacion</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonSelect
                name="canalizacion"
                value={canalizacion}
                onIonChange={handleInputChange}
              >
                {canalizaciones.map((canalizacion: any) => (
                  <IonSelectOption
                    key={canalizacion.id}
                    value={canalizacion.id}
                  >
                    {canalizacion.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Numero de Ductos - Diametro
              </IonLabel>
              <IonInput
                type="text"
                name="numero_ductos_diametro"
                value={numero_ductos_diametro}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Numero de Ductos - Diametro"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia aparente
              </IonLabel>
              <IonInput
                type="text"
                name="potencia_aparente"
                value={potencia_aparente}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Potencia aparente"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia activa
              </IonLabel>
              <IonInput
                type="text"
                name="potencia_activa"
                value={potencia_activa}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Potencia activa"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia reactiva
              </IonLabel>
              <IonInput
                type="text"
                name="potencia_reactiva"
                value={potencia_reactiva}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Potencia reactiva"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                FP carga 01
              </IonLabel>
              <IonInput
                type="text"
                name="fp_carga_01"
                value={fp_carga_01}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="FP carga 01"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
