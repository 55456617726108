import { useState } from "react"

export const useForm = (initialState = {}) => {
    const [values, setValues] = useState<any>(initialState)

    const handleInputChange = ({target}) =>{
        setValues({
            ...values,
            [target.name] : target.value
        })
    }

    const resetForm = (newFormState = initialState) => {
        setValues(newFormState);
    }

    return [values, handleInputChange, resetForm];
}