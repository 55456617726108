import { types } from "../types/types";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { setInactiveLoading, setToast, startUpdatePasswordHide } from "./ui";

export const startLogin = (email: string, password: string) => {
  return async (dispatch: any) => {
    const resp = await fetchSinToken("auth", { email, password }, "POST");
    const body = await resp.json();
    if (body.ok) {
      localStorage.setItem("token", body.token);
      localStorage.setItem("token-init-date", new Date().getTime().toString());
      dispatch(
        login({
          uid: body.uid,
          name: body.name,
        })
      );
    } else {
      let toast = {};
      if (body.errors) {
        toast = {
          title: "Error",
          msg: "El email y la contraseña son campos obligatorios",
          color: "danger",
        };
      } else {
        toast = {
          title: "Error",
          msg: body.msg,
          color: "danger",
        };
      }
      dispatch(setToast(toast));
    }
  };
};

export const startCheking = () => {
  return async (dispatch: any) => {
    const resp = await fetchConToken("auth/renew", {});
    const body = await resp.json();
    if (body.ok) {
      localStorage.setItem("token", body.token);
      localStorage.setItem("token-init-date", new Date().getTime().toString());
      dispatch(
        login({
          uid: body.uid,
          name: body.name,
        })
      );
    } else {
      dispatch(chekingFinish());
    }
  };
};

const login = (user: any) => ({
  type: types.authLogin,
  payload: user,
});

const chekingFinish = () => ({
  type: types.authChekingFinish,
});

export const startRegister = (
  name: string,
  email: string,
  password: string
) => {
  return async (dispatch: any) => {
    const resp = await fetchSinToken(
      "auth/new",
      { email, name, password },
      "POST"
    );
    const body = await resp.json();
    if (body.ok) {
      dispatch(
        login({
          uid: body.uid,
          name: body.name,
        })
      );
    } else {
    }
  };
};

export const startUpdatePassword = (oldpassword: string, password: string) => {
  return async (dispatch: any, getState: any) => {
    const {uid} = getState().auth;
    try {
      const resp = await fetchConToken(
        `auth/update-password`,
        { uid, oldpassword, password },
        "PUT"
      );
      const body = await resp.json();
      if (body.ok) {
        const toast = {
          title: "",
          msg: "La contraseña ha sido actualizada exitosamente",
          color: "success",
        };
        dispatch(startCheking())
        dispatch(setInactiveLoading());
        await dispatch(setToast(toast));
        dispatch(startUpdatePasswordHide());
      } else {
        if (body.msg) {
          const toast = {
            title: "Error",
            msg: body.msg,
            color: "danger",
          };
          dispatch(setInactiveLoading());
          await dispatch(setToast(toast));
        } else {
          let msg = "";
          const errors = JSON.parse(JSON.stringify(body.errors));
          for (const i in errors) {
            msg = errors[i].msg;
          }
          console.log(`Error ${msg}`);
          const toast = {
            title: "Error",
            msg,
            color: "danger",
          };
          dispatch(setInactiveLoading());
          await dispatch(setToast(toast));
        }
      }
    } catch (error) {
      console.log(`Error ${error}`);
      const toast = {
        title: "Error",
        msg: "Contacta al administrador",
        color: "danger",
      };
      dispatch(setInactiveLoading());
      dispatch(setToast(toast));
    }
  };
};

export const startLogout = () => {
  return (dispatch: any) => {
    localStorage.clear();
    dispatch(logout());
  };
};

const logout = () => ({ type: types.authLogout });
