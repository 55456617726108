import { FC, useEffect} from "react";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonText,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCircleOutline,
  chevronForwardOutline,
  createOutline,
  trashOutline,
} from "ionicons/icons";
import {
  clearActiveMarca,
  marcaSetActive,
  startLoadMarcas,
} from "../../actions/marcas";
import { RootState } from "../../reducers/rootReducer";
import { Marca } from "../../interfaces/Marca";
import { setModalMarcaShow, startSetActiveAlert } from "../../actions/ui";
import { AlertComponent } from "../ui/AlertComponent";

export const MarcaList: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadMarcas());
  }, [dispatch]);
  const { marcas } = useSelector((state: RootState) => state.marca);

  const handleDelete = (marca: Marca) => {
    const alert = {
      active: true,
      header: "Eliminar marca",
      message: `La marca ${marca.name} será eliminada`,
      tipo: "marca",
    };
    dispatch(startSetActiveAlert(alert));
    dispatch(marcaSetActive(marca));
  };

  const onDesactive = () => {
    dispatch(clearActiveMarca());
  };

  const handleUpdate = (marca: Marca) => {
    dispatch(marcaSetActive(marca));
    dispatch(setModalMarcaShow());
  };

  const handleAdd = () => {
    dispatch(setModalMarcaShow());
  };

  return (
    <>
      <IonList>
        <IonListHeader>
          <IonLabel class="title">Marcas</IonLabel>
          <IonButton onClick={handleAdd}>
            <IonIcon
              className="icon-list"
              color="success"
              icon={addCircleOutline}
            ></IonIcon>
          </IonButton>
        </IonListHeader>

        {marcas.map((m: any) => (
          <IonItemSliding key={m.id}>
            <IonItem>
              <IonText>
                <h6>{m.name}</h6>
              </IonText>
              <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
            </IonItem>
            <IonItemOptions side="end">
              <IonItemOption
                color="white"
                onClick={() => {
                  handleUpdate(m);
                }}
              >
                <IonIcon
                  className="icon-list"
                  color="success"
                  ariaLabel="Editar"
                  title="Editar"
                  icon={createOutline}
                ></IonIcon>
              </IonItemOption>
              <IonItemOption
                color="white"
                onClick={() => {
                  handleDelete(m);
                }}
              >
                <IonIcon
                  className="icon-list"
                  color="danger"
                  icon={trashOutline}
                ></IonIcon>
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      </IonList>
      <AlertComponent onDesactive={onDesactive} />
    </>
  );
};
