import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { createOutline, trashOutline, chevronForwardOutline, closeOutline } from 'ionicons/icons';
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startEquipoUpdated } from "../../../actions/equipos";
import {
  startAddUpsSwow,
  startAddUpsHide,
  setActiveLoading,
} from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";
import { IonButton, IonInput } from '@ionic/react';

const initForm = {
  fp_sistema: "",
fp_carga_01: "",
potencia_activa: "",
potencia_reactiva: "",
voltaje_ac_salida: "",
corriente_ac_salida: "",
voltaje_ac_entrada: "",
corriente_ac_entrada: "",
}

export const UpsItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editUps } = useSelector((state: RootState) => state.ui);
  const { ups = {}, voltaje_ac_salida = "", corriente_ac_salida = "", potencia_activa = "",potencia_reactiva = "", voltaje_ac_entrada = "",corriente_ac_entrada = "",fp_carga_01 = "" } = activeEquipo;
  const { fp_sistema = "" } = ups;

  const handleDelete = () => {
    console.log("click");
  };
  const handleUpdate = () => {
    dispatch(startAddUpsSwow());
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        UPS
      </IonLabel>
      {Object.keys(ups).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>FP sistema</IonText>
          <IonNote slot="end">{fp_sistema}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>FP carga</IonText>
          <IonNote slot="end">{fp_carga_01}</IonNote>
        </IonItem>         
        <IonItem lines="none">
          <IonText>Vol. AC entrada</IonText>
          <IonNote slot="end">{voltaje_ac_entrada}</IonNote>
        </IonItem> 
        <IonItem lines="none">
          <IonText>Cte. AC entrada</IonText>
          <IonNote slot="end">{corriente_ac_entrada}</IonNote>
        </IonItem> 
        <IonItem lines="none">
          <IonText>Vol. AC salida</IonText>
          <IonNote slot="end">{voltaje_ac_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte AC salida</IonText>
          <IonNote slot="end">{corriente_ac_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Potencia Activa</IonText>
          <IonNote slot="end">{potencia_activa}</IonNote>
        </IonItem> 
        <IonItem lines="none">
          <IonText>Potencia Reactiva</IonText>
          <IonNote slot="end">{potencia_reactiva}</IonNote>
        </IonItem>       
        </IonList>}
      <IonItemSliding>
      <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(ups).length !== 0  ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editUps && <UpsModal />}
        </IonItem>        
        <IonItemOptions side="end" color="light">
          <IonItemOption
            color="light"
            onClick={() => {
              handleUpdate();
            }}
          >
            <IonIcon
              className="icon-list"
              color="success"
              ariaLabel="Editar"
              title="Editar"
              icon={createOutline}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="light"
            onClick={() => {
              handleDelete();
            }}
          >
            <IonIcon
              className="icon-list"
              color="danger"
              icon={trashOutline}
            ></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  );
};


const UpsModal: FC = () => {
  const dispatch = useDispatch();   


  const { editUps } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { fp_sistema, fp_carga_01, potencia_activa, potencia_reactiva, voltaje_ac_salida, corriente_ac_salida, voltaje_ac_entrada, corriente_ac_entrada } = formValues;
  useEffect(() => {
    if (activeEquipo.ups) {
      const u = {
        fp_sistema: activeEquipo.ups.fp_sistema,
        fp_carga_01: activeEquipo.fp_carga_01,
        potencia_activa: activeEquipo.potencia_activa,
        potencia_reactiva: activeEquipo.potencia_reactiva,
        voltaje_ac_salida: activeEquipo.voltaje_ac_salida,
        corriente_ac_salida: activeEquipo.corriente_ac_salida,
        voltaje_ac_entrada: activeEquipo.voltaje_ac_entrada,
        corriente_ac_entrada: activeEquipo.corriente_ac_entrada,
      };
      setFormValues(u)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    ups: activeEquipo.ups,
    potencia_activa: activeEquipo.potencia_activa,
    potencia_reactiva: activeEquipo.potencia_reactiva,
    fp_carga_01: activeEquipo.fp_carga_01,
    voltaje_ac_salida: activeEquipo.voltaje_ac_salida,
    corriente_ac_salida: activeEquipo.corriente_ac_salida,
    voltaje_ac_entrada: activeEquipo.voltaje_ac_entrada,
    corriente_ac_entrada: activeEquipo.corriente_ac_entrada,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.ups = {
      fp_sistema      
    }
    equipo.fp_carga_01 = fp_carga_01;
    equipo.voltaje_ac_entrada = voltaje_ac_entrada;
    equipo.corriente_ac_entrada = corriente_ac_entrada;
    equipo.voltaje_ac_salida = voltaje_ac_salida;
    equipo.corriente_ac_salida = corriente_ac_salida;
    equipo.potencia_activa = potencia_activa;
    equipo.potencia_reactiva = potencia_reactiva;
    dispatch(startEquipoUpdated(equipo));
    dispatch(startAddUpsHide())
  };

  const handleClose = () => {
    dispatch(startAddUpsHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editUps} cssClass='modal-celdasMT'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Ups</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Fp Sistema
              </IonLabel>
              <IonInput
                type="text"
                name="fp_sistema"
                value={fp_sistema}
                onIonChange={handleInputChange}
                placeholder="Fp Sistema"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Fp carga 01
              </IonLabel>
              <IonInput
                type="number"
                name="fp_carga_01"
                value={fp_carga_01}
                onIonChange={handleInputChange}
                placeholder="Fp carga 01"
                inputmode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia activa
              </IonLabel>
              <IonInput
                type="number"
                name="potencia_activa"
                value={potencia_activa}
                onIonChange={handleInputChange}
                placeholder="Potencia activa"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia reactiva
              </IonLabel>
              <IonInput
                type="number"
                name="potencia_reactiva"
                value={potencia_reactiva}
                onIonChange={handleInputChange}
                placeholder="Potencia reactiva"
                inputmode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje AC salida
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_ac_salida"
                value={voltaje_ac_salida}
                onIonChange={handleInputChange}
                placeholder="Voltaje AC salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente AC salida
              </IonLabel>
              <IonInput
                type="number"
                name="corriente_ac_salida"
                value={corriente_ac_salida}
                onIonChange={handleInputChange}
                placeholder="Corriente AC salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje AC entrada
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_ac_entrada"
                value={voltaje_ac_entrada}
                onIonChange={handleInputChange}
                placeholder="Voltaje AC entrada"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente AC entrada
              </IonLabel>
              <IonInput
                type="number"
                name="corriente_ac_entrada"
                value={corriente_ac_entrada}
                onIonChange={handleInputChange}
                placeholder="Corriente AC entrada"
                inputmode="numeric"
              ></IonInput>
            </IonItem>            
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}