import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setCapacidadEditHide, setCapacidadEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

export const CapacidadItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { capacidad } } = useSelector((state: RootState) => state.equipo);
  const { editCapacidad } = useSelector((state: RootState) => state.ui);

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setCapacidadEditShow())

  }
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
          <IonLabel className="item-label" position="stacked" color="primary">
             Capacidad
            </IonLabel>
          </IonCol>
        </IonRow>
       <IonRow>
       <IonCol size='9' size-sm>
        <IonLabel>{capacidad}</IonLabel>
        </IonCol>
        <IonCol size='3' size-sm className="ion-align-self-center">
        <IonButton color="white" fill='clear' onClick={handleUpdate}>
            <IonIcon slot="icon-only" color="success" size='large' icon={createOutline} />
          </IonButton>
        </IonCol>
       </IonRow>
      </IonGrid>
      {editCapacidad && <CapacidadModal />}
    </IonItem>
  )
}

const CapacidadModal: FC = () => {
  const dispatch = useDispatch();
  const { editCapacidad } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    capacidad: activeEquipo.capacidad
  }
  const { capacidad } = activeEquipo;

  const handleUpdate = ({ name }) => {
    dispatch(setActiveLoading());
    equipo.capacidad = name
    dispatch(startEquipoUpdated(equipo));
    dispatch(setCapacidadEditHide())
  };

  const handleClose = () => {
    dispatch(setCapacidadEditHide());
  };
  return (
    <IonContent>
      <IonAlert
        isOpen={editCapacidad}
        onDidDismiss={handleClose}
        header={'Capacidad'}
        inputs={[
          {
            name: 'name',
            type: 'text',
            placeholder: 'Capacidad',
            value: capacidad
          },
        ]}
        buttons={[
          {
            text: 'Guardar',
            handler: (e) => {
              handleUpdate(e);
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }
        ]}
      />
    </IonContent>
  );
}
