import { fetchConToken } from "../helpers/fetch";
import { Type} from "../interfaces/Type";
import { types } from "../types/types";
import { setInactiveLoading, setModalTypeHide, setToast } from "./ui";

export const startLoadTypes = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken("types", {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(loadTypes(body.types))
        } else {
            console.log(body.msg);
        }
    };
};

const loadTypes = (tipos: Type) => ({
    type: types.typeStartLoad,
    payload: tipos
})

export const typeSetActive = (type: Type) => ({
    type: types.typeSetActive,
    payload: type
});

export const clearActiveType = () => ({
    type: types.typeClearActive
});

export const tipoStartAddNew = (type: Type) => {
    return async (dispatch: any, getState: any) => {
        const { uid, name } = getState().auth;
        try {
            const resp = await fetchConToken("types", type, "POST");
            const body = await resp.json();
            if (body.ok) {                
                type.id = body.type.id;
                type.user = {
                    _id: uid,
                    name: name,
                };
                dispatch(tipoAddNew(type));
                const toast = {
                    title: '',
                    msg: 'El tipo ha sido agregado exitosamente',
                    color: "success"
                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalTypeHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            dispatch(setToast(toast));
        }
    };
};

const tipoAddNew = (type: Type) => ({
    type: types.typeStartAdd,
    payload: type
});

export const startTypeUpdated = (type: Type) => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().type.activeType;
        try {
            const resp = await fetchConToken(`types/${id}`, type, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(typeUpdated(body.type));
                const toast = {
                    title: '',
                    msg: 'El tipo ha sido actualizado',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalTypeHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(error);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    };
};

const typeUpdated = (type: Type) => ({
    type: types.typeStartUpdated,
    payload: type,
});

export const starTypeDeleted = () => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().type.activeType;
        try {
            const resp = await fetchConToken(`types/${id}`, {}, "DELETE");
            const body = await resp.json();
            if (body.ok) {
                dispatch(typeDeleted());
                const toast = {
                    title: '',
                    msg: 'Se ha eliminado el tipo exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))
            } else {
                console.log(body)
                let msg = "";
                const errors = JSON.parse(JSON.stringify(body.errors));
                for (const i in errors) {
                    msg = errors[i].msg;
                }
                console.log(`Error ${msg}`);
                const toast = {
                    title: 'Error',
                    msg,
                    color: "danger"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))

            }
        } catch (error) {
            console.error(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    }
};

const typeDeleted = () => ({
    type: types.typeStartDeleted,
});
