import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  chevronForwardOutline,
  closeOutline,
  createOutline,
  trashOutline,
} from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startEquipoUpdated } from "../../../actions/equipos";
import {
  startAddEquipoRespaldoSwow,
  startAddEquipoRespaldoHide,
  setActiveLoading,
} from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";

const initForm = {
  corriente_dc_salida: "",
  equipo_respaldo: "",
  potencia_activa: "",
  voltaje_dc_salida: "",
};
export const BancoBateriasItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const {
    banco_baterias = {},
    voltaje_dc_salida = "",
    corriente_dc_salida = "",
    potencia_activa = "",
  } = activeEquipo;
  const { editEquipoRespaldo } = useSelector((state: RootState) => state.ui);

  const handleDelete = () => {
    console.log("click");
  };
  const handleUpdate = () => {
    dispatch(startAddEquipoRespaldoSwow());
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Banco Baterías
      </IonLabel>
      {Object.keys(banco_baterias).length !== 0 && (
        <IonList>
          <IonItem lines="none">
            <IonText>Equipo respalda</IonText>
            <IonNote slot="end">{banco_baterias.equipo_respaldo}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Vol. DC salida</IonText>
            <IonNote slot="end">{voltaje_dc_salida}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Cte DC salida</IonText>
            <IonNote slot="end">{corriente_dc_salida}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Potencia activa</IonText>
            <IonNote slot="end">{potencia_activa}</IonNote>
          </IonItem>
        </IonList>
      )}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">
            {Object.keys(banco_baterias).length !== 0 ? "Editar" : "Agregar"}
          </IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editEquipoRespaldo && <ModalBancoBateriasItem />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption
            color="light"
            onClick={() => {
              handleUpdate();
            }}
          >
            <IonIcon
              className="icon-list"
              color="success"
              ariaLabel="Editar"
              title="Editar"
              icon={createOutline}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="light"
            onClick={() => {
              handleDelete();
            }}
          >
            <IonIcon
              className="icon-list"
              color="danger"
              icon={trashOutline}
            ></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  );
};

const ModalBancoBateriasItem = () => {
  const dispatch = useDispatch();
  const { editEquipoRespaldo } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const [formValues, setFormValues] = useState(initForm);

  const {
    corriente_dc_salida,
    equipo_respaldo,
    potencia_activa,
    voltaje_dc_salida,
  } = formValues;

  useEffect(() => {
    if (activeEquipo.banco_baterias) {
      const bb = {
        corriente_dc_salida: activeEquipo.corriente_dc_salida,
        equipo_respaldo: activeEquipo.banco_baterias.equipo_respaldo,
        potencia_activa: activeEquipo.potencia_activa,
        voltaje_dc_salida: activeEquipo.voltaje_dc_salida,
      };
      setFormValues(bb);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    banco_baterias: activeEquipo.banco_baterias,
    corriente_dc_salida: activeEquipo.corriente_dc_salida,
    potencia_activa: activeEquipo.potencia_activa,
    voltaje_dc_salida: activeEquipo.voltaje_dc_salida,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.banco_baterias = {
      equipo_respaldo,
    };
    equipo.corriente_dc_salida = corriente_dc_salida;
    equipo.potencia_activa = potencia_activa;
    equipo.voltaje_dc_salida = voltaje_dc_salida;
    dispatch(startEquipoUpdated(equipo));
    dispatch(startAddEquipoRespaldoHide());
  };

  const handleClose = () => {
    dispatch(startAddEquipoRespaldoHide());
  };

  return (
    <IonContent>
      <IonModal isOpen={editEquipoRespaldo} cssClass="modal-banco-baterias">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Banco Baterías</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
              <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Equipo que respalda
              </IonLabel>
              <IonInput
                type="text"
                name="equipo_respaldo"
                value={equipo_respaldo}
                onIonChange={handleInputChange}
                placeholder="Equipo que respalda"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje salida
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_dc_salida"
                value={voltaje_dc_salida}
                onIonChange={handleInputChange}
                placeholder="Voltaje salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente salida
              </IonLabel>
              <IonInput
                type="number"
                name="corriente_dc_salida"
                value={corriente_dc_salida}
                onIonChange={handleInputChange}
                placeholder="Corriente salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia activa
              </IonLabel>
              <IonInput
                type="number"
                name="potencia_activa"
                value={potencia_activa}
                onIonChange={handleInputChange}
                placeholder="Potencia activa"
                inputmode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
