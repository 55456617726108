import { FC, useEffect } from "react";
import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonText } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { addCircleOutline, chevronForwardOutline, createOutline, trashOutline } from 'ionicons/icons';
import { RootState } from "../../reducers/rootReducer";
import { setModalLabelShow, startSetActiveAlert } from "../../actions/ui";
import { Label } from "../../interfaces/Label";
import { AlertComponent } from "../ui/AlertComponent";
import { clearActiveLabel, labelSetActive, startLoadLabels } from "../../actions/labels";

export const LabelListScreen: FC = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(startLoadLabels());
    },[dispatch])

    const { labels } = useSelector((state: RootState) => state.label);

    const handleDelete = (label: Label) => {
        const alert ={
            active: true,
            header: "Eliminar etiqueta de nombre",
            message: `La etiqueta ${label.name} será eliminada`,
            tipo: 'labels'         
        }
        dispatch(startSetActiveAlert(alert)) 
        dispatch(labelSetActive(label));
    }

    const handleUpdate =(label: Label) => {
        dispatch(labelSetActive(label));
        dispatch(setModalLabelShow())
    };  

    const onDesactive = () => {
        dispatch(clearActiveLabel());
    };

    const handleAdd = () => {
        dispatch(setModalLabelShow());
    };

    return (
        <>
            <IonList>
                <IonListHeader>
                    <IonLabel class="title">Nombres</IonLabel>
                    <IonButton onClick={handleAdd}><IonIcon className="icon-list" color="success" icon={addCircleOutline}></IonIcon></IonButton>
                </IonListHeader>

                {
                    labels.map((l: any ) => 
                       (<IonItemSliding key={l.id}>
                            <IonItem>
                                <IonText><h6>{l.name}</h6></IonText>
                            <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
                            </IonItem>
                            <IonItemOptions side="end">
                            <IonItemOption color="white" onClick={()=>{handleUpdate(l)}}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
                            <IonItemOption color="white" onClick={()=>{handleDelete(l)}}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
                            </IonItemOptions>
                        </IonItemSliding>
                       )
                    )
                }
            </IonList>
            <AlertComponent onDesactive={onDesactive} />
        </>
    );
}
