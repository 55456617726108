import { IonAlert, IonContent } from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { startAddImageDescripcionHide } from "../../actions/ui";
import { startImageUpdated, startSetInactiveImage } from "../../actions/images";

export const AddImageDescription = () => {
  const dispatch = useDispatch();
  const { editImageDescription } = useSelector((state: RootState) => state.ui);
  const {images, activeImage} = useSelector((state: RootState) => state.image);

  const handleUpdate = ({ description }) => {    
    const data = images.find((img: any) => img.id === activeImage.id);      
      data.description = description;
      dispatch(startImageUpdated(data));
      dispatch(startAddImageDescripcionHide());
  };

  const handleClose = () => {
    dispatch(startAddImageDescripcionHide());
    dispatch(startSetInactiveImage())
  };
  return (
    <IonContent>
      <IonAlert
        isOpen={editImageDescription}
        onDidDismiss={handleClose}
        header={"Descripcion"}
        inputs={[
          {
            name: "description",
            type: "text",
            placeholder: "Descripción",
            value: activeImage.description,
          },
        ]}
        buttons={[
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
            },
          },
          {
            text: "Agregar",
            handler: (e) => {
              handleUpdate(e);
            },
          },
        ]}
      />
    </IonContent>
  );
};
