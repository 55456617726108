import { types } from "../types/types";

const initialState = {
  localImages: [],
  images: [],
  activeImage: null,
  activeImageLocal: null,
};

export const imageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.imageStartLoadImages:
      return {
        ...state,
        images: action.payload,
      };

    case types.imageStartSaveImages:
      return {
        ...state,
        images: [...state.images, action.payload],
      };

    case types.imagesStartImageUpdated:
      return {
        ...state,
        images: state.images.map((i) =>
          i.id === action.payload.id ? action.payload : i
        ),
      };

    case types.imagesSetDescription:
      return {
        ...state,
        images: state.images.map((i: any) =>
          i.id === action.payload.image
            ? { ...i, description: action.payload.description }
            : i
        ),
      };

    case types.imageStartDeleteImages:
      return {
        ...state,
        images: state.images.filter(
          (image: any) => image.id !== action.payload
        ),
      };

    case types.imageSetLocalSaveImages:
      return {
        ...state,
        localImages: [...state.localImages, action.payload],
      };

    case types.imageSetLocalImageDescription:
      console.log(action.payload)
      return {
        ...state,
        localImages: state.localImages.map((i: any) =>
          i.image === action.payload.image.image
            ? { ...i, description: action.payload.description }
            : i
        ),
      };

    case types.imageStartLocalDelete:
      return {
        ...state,
        localImages: action.payload,
      };

    case types.imageStartClearLocal:
      return {
        ...state,
        localImages: [],
      };

      case types.imageSetActiveImage:
        return {
          ...state,
          activeImage: action.payload
        };

        case types.imageSetInactiveImage:
        return {
          ...state,
          activeImage:null
        };

        case types.imageSetActiveLocalImage:
        return {
          ...state,
          activeImageLocal: action.payload
        };

        case types.imageSetInactiveLocalImage:
        return {
          ...state,
          activeImageLocal:null
        };

    default:
      return state;
  }
};
