import React from 'react'
import { Route, Redirect } from 'react-router-dom'

interface ContainerProps {
    exact: boolean,
    path: any
    isAuthenticated: boolean,
    component: any;
  }
export const PublicRoute: React.FC<ContainerProps> = ({isAuthenticated, component: Component, ...rest}) => {
    return (
        <Route {...rest}
            component={(props: any) => (
                (!isAuthenticated)
                ? <Component { ...props } />
                : <Redirect to="/" />
                 
            )}
         />
    )
}

