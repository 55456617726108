import { fetchConToken } from "../helpers/fetch";
import { Marca } from "../interfaces/Marca";
import { types } from "../types/types";
import { setInactiveLoading, setModalMarcaHide, setToast } from "./ui";

export const startLoadMarcas = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken("marcas", {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(loadMarcas(body.marcas))
        } else {
            console.log(body.msg);
        }
    };
};

const loadMarcas = (marcas: Marca) => ({
    type: types.marcaStartLoad,
    payload: marcas
})

export const marcaSetActive = (marca: Marca) => ({
    type: types.marcaSetActive,
    payload: marca
});

export const clearActiveMarca = () => ({
    type: types.marcaClearActive
});

export const marcaStartAddNew = (marca: Marca) => {
    return async (dispatch: any, getState: any) => {
        const { uid, name } = getState().auth;
        try {
            const resp = await fetchConToken("marcas", marca, "POST");
            const body = await resp.json();
            if (body.ok) {                
                marca.id = body.marca.id;
                marca.user = {
                    _id: uid,
                    name: name,
                };
                dispatch(marcaAddNew(marca));
                const toast = {
                    title: '',
                    msg: 'La marca ha sido agregada exitosamente',
                    color: "success"
                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalMarcaHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            dispatch(setToast(toast));
        }
    };
};

const marcaAddNew = (marca: Marca) => ({
    type: types.marcaStartAdd,
    payload: marca
});

export const startMarcaUpdated = (marca: Marca) => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().marca.activeMarca;
        try {
            const resp = await fetchConToken(`marcas/${id}`, marca, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(marcaUpdated(body.marca));
                const toast = {
                    title: '',
                    msg: 'La marca ha sido actualizada exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalMarcaHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(error);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    };
};

const marcaUpdated = (marca: Marca) => ({
    type: types.marcaStartUpdated,
    payload: marca,
});

export const starMarcaDeleted = () => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().marca.activeMarca;
        try {
            const resp = await fetchConToken(`marcas/${id}`, {}, "DELETE");
            const body = await resp.json();
            if (body.ok) {
                dispatch(marcaDeleted());
                const toast = {
                    title: '',
                    msg: 'La marca ha sido eliminada exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))
            } else {
                console.log(body)
                let msg = "";
                const errors = JSON.parse(JSON.stringify(body.errors));
                for (const i in errors) {
                    msg = errors[i].msg;
                }
                console.log(`Error ${msg}`);
                const toast = {
                    title: 'Error',
                    msg,
                    color: "danger"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))

            }
        } catch (error) {
            console.error(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    }
};

const marcaDeleted = () => ({
    type: types.marcaStartDeleted,
});
