import { types } from "../types/types";

const initialSate ={
    canalizaciones: [],
    activeCanalizacion: null,    
}

export const canalizacionReducer = (state = initialSate, action: any) => {
    switch (action.type) {        
        case types.canalStartLoad:
            return {
                ...state,
                canalizaciones: action.payload
            };
        case types.canalStartAdd:
            return {
                ...state,
                canalizaciones: [
                    ...state.canalizaciones,
                    action.payload
                ]

            }

        case types.canalSetActive:
            return {
                ...state,
                activeCanalizacion: action.payload
            };
        
        case types.canalClearActive:
            return {
                ...state,
                activeCanalizacion:null
            };

        case types.canalStartUpdated:
            return {
                ...state,
                canalizaciones: state.canalizaciones.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            };
        
        case types.canalStartDeleted:
            return {
                ...state,
                canalizaciones: state.canalizaciones.filter(c => c.id !== state.activeCanalizacion.id),
                activeCanalizacion : null
            };
        
        case types.canalCleared:
            return {
                canalizaciones: [],
                activeCanalizacion: null
            };

        default:
            return state;
    }
};