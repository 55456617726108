import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, logOutOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { startLoadMarcas } from '../../../actions/marcas'
import { startAddRectificadorSwow, startAddRectificadorHide, setActiveLoading } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'


const initForm = {
  capacidad_sistema: "",
  capacidad_de_modulos: "",
  modulos_instalados: "",
  voltaje_ac_entrada: "",
  corriente_ac_entrada: "",
  voltaje_dc_salida: "",
  corriente_dc_salida: "",
  potencia_activa: "",
  potencia_reactiva: "",
  fp_entrada: "",
}

export const RectificadorItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editRectificador } = useSelector((state: RootState) => state.ui);
  const { rectificador = {}, potencia_activa = "", potencia_reactiva = "", voltaje_ac_entrada = "", corriente_ac_entrada = "",voltaje_dc_salida = "", corriente_dc_salida = "" } = activeEquipo;
  const { capacidad_sistema = "", capacidad_de_modulos = "", modulos_instalados = "",   fp_entrada = "" } = rectificador;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(startAddRectificadorSwow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Rectificdor
      </IonLabel>
      {Object.keys(rectificador).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>Cap. sistema</IonText>
          <IonNote slot="end">{capacidad_sistema}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cant. modulos</IonText>
          <IonNote slot="end">{capacidad_de_modulos}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Mod. intsalados</IonText>
          <IonNote slot="end">{modulos_instalados}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Vol. AC entrada</IonText>
          <IonNote slot="end">{voltaje_ac_entrada}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte AC entrada</IonText>
          <IonNote slot="end">{corriente_ac_entrada}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Vol. DC salida</IonText>
          <IonNote slot="end">{voltaje_dc_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte DC salida</IonText>
          <IonNote slot="end">{corriente_dc_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Potencia activa</IonText>
          <IonNote slot="end">{potencia_activa}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Potencia reactiva</IonText>
          <IonNote slot="end">{potencia_reactiva}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Fp entrada</IonText>
          <IonNote slot="end">{fp_entrada}</IonNote>
        </IonItem>
        </IonList>}
      <IonItemSliding>
      <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(rectificador).length !== 0 ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editRectificador && <RectificadorModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
}

const RectificadorModal: FC = () => {
  const dispatch = useDispatch();
 
  const { editRectificador } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { capacidad_sistema, capacidad_de_modulos, modulos_instalados, voltaje_ac_entrada, corriente_ac_entrada, voltaje_dc_salida, corriente_dc_salida, potencia_activa, potencia_reactiva, fp_entrada } = formValues;
  useEffect(() => {
    if (activeEquipo.rectificador) {
      const r = {
        capacidad_sistema: activeEquipo.rectificador.capacidad_sistema,
        capacidad_de_modulos: activeEquipo.rectificador.capacidad_de_modulos,
        modulos_instalados: activeEquipo.rectificador.modulos_instalados,
        voltaje_ac_entrada: activeEquipo.voltaje_ac_entrada,
        corriente_ac_entrada: activeEquipo.corriente_ac_entrada,
        voltaje_dc_salida: activeEquipo.voltaje_dc_salida,
        corriente_dc_salida: activeEquipo.corriente_dc_salida,
        potencia_activa: activeEquipo.potencia_activa,
        potencia_reactiva: activeEquipo.potencia_reactiva,
        fp_entrada: activeEquipo.rectificador.fp_entrada
      };
      setFormValues(r)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    rectificador: activeEquipo.rectificador,
    voltaje_ac_entrada: activeEquipo.voltaje_ac_entrada,
    corriente_ac_entrada: activeEquipo.corriente_ac_entrada,
    voltaje_dc_salida: activeEquipo.voltaje_dc_salida,
    corriente_dc_salida: activeEquipo.corriente_dc_salida,
    potencia_activa: activeEquipo.potencia_activa,
    potencia_reactiva: activeEquipo.potencia_reactiva,
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.rectificador = {
      capacidad_sistema,
      capacidad_de_modulos,
      modulos_instalados,        
      fp_entrada,
    }
    equipo.voltaje_dc_salida = voltaje_dc_salida;
    equipo.corriente_dc_salida = corriente_dc_salida;
    equipo.voltaje_ac_entrada = voltaje_ac_entrada;
    equipo.corriente_ac_entrada = corriente_ac_entrada;
    equipo.potencia_activa = potencia_activa;
    equipo.potencia_reactiva = potencia_reactiva;
    dispatch(startEquipoUpdated(equipo));
    dispatch(startAddRectificadorHide())
  };

  const handleClose = () => {
    dispatch(startAddRectificadorHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editRectificador} cssClass='modal-equipo'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Rectificadores</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Capacidad sistema
              </IonLabel>
              <IonInput
                type="text"
                name="capacidad_sistema"
                value={capacidad_sistema}
                onIonChange={handleInputChange}
                placeholder="Capacidad sistema"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Capacidad modulos
              </IonLabel>
              <IonInput
                type="text"
                name="capacidad_de_modulos"
                value={capacidad_de_modulos}
                onIonChange={handleInputChange}
                placeholder="Capacidad modulos"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Modulos instalados
              </IonLabel>
              <IonInput
                type="text"
                name="modulos_instalados"
                value={modulos_instalados}
                onIonChange={handleInputChange}
                placeholder="Modulos instalados"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje AC entrada
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_ac_entrada"
                value={voltaje_ac_entrada}
                onIonChange={handleInputChange}
                placeholder="Voltaje AC entrada"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente AC entrada
              </IonLabel>
              <IonInput
                type="number"
                name="corriente_ac_entrada"
                value={corriente_ac_entrada}
                onIonChange={handleInputChange}
                placeholder="Corriente AC entrada"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje DC salida
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_dc_salida"
                value={voltaje_dc_salida}
                onIonChange={handleInputChange}
                placeholder="Voltaje DC salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente DC salida
              </IonLabel>
              <IonInput
                type="number"
                name="corriente_dc_salida"
                value={corriente_dc_salida}
                onIonChange={handleInputChange}
                placeholder="Corriente DC salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia activa
              </IonLabel>
              <IonInput
                type="number"
                name="potencia_activa"
                value={potencia_activa}
                onIonChange={handleInputChange}
                placeholder="Potencia activa"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Potencia reactiva
              </IonLabel>
              <IonInput
                type="number"
                name="potencia_reactiva"
                value={potencia_reactiva}
                onIonChange={handleInputChange}
                placeholder="Potencia reactiva"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Fp entrada
              </IonLabel>
              <IonInput
                type="number"
                name="fp_entrada"
                value={fp_entrada}
                onIonChange={handleInputChange}
                placeholder="Fp entrada"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
