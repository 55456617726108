import {
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonIcon,
    IonContent
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { centralStartAddNew, clearActiveCentral, startCentralUpdated } from "../../actions/central";
import { setActiveLoading, setInactiveLoading, setModalCentralHide, setToast } from "../../actions/ui";
import { RootState } from "../../reducers/rootReducer";

const initCentral = {
    name: "",
    calle: "",
    city: "Bogota DC.",
    phone: "",
    zip: 110821
};

const CentralScreen: React.FC = () => {
    const dispatch = useDispatch();
    const { activeCentral } = useSelector((state: RootState) => state.central);
    const [formValues, setFormValues] = useState(initCentral);

    const { name, calle, city, phone, zip } = formValues;

    useEffect(() => {
        if (activeCentral) {
            const central = {
                name: activeCentral.name,
                calle: activeCentral.address.calle,
                city: activeCentral.address.city,
                phone: activeCentral.phone,
                zip: activeCentral.address.zip
            }
            setFormValues(central);
        } else {
            setFormValues(initCentral)
        }
    }, [activeCentral]);

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value,
        });
    };

    const central = {
        address: {
            calle,
            city,
            zip
        },
        name,
        phone,
        id: "",
        user: ""
    }

    const handleSumit = (e: any) => {
        e.preventDefault();
        if (name.length <= 3) {
            const toast = {
                msg: "El nombre es un campo obligatorio o tener mínimo 4 caracteres",
                color: "warning"
            };

            dispatch(setToast(toast))
            dispatch(setInactiveLoading());
            return;
        }
        dispatch(setActiveLoading());
        if (activeCentral) {
            central.id = activeCentral.id;
            central.user = activeCentral.user;
            dispatch(startCentralUpdated(central));
            dispatch(clearActiveCentral());
        } else {
            dispatch(centralStartAddNew(central));
        }
    };

    const setShowModal = () => {
        setFormValues(initCentral);
        dispatch(clearActiveCentral())
        dispatch(setModalCentralHide())
    };
    return (
        <>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonTitle>{activeCentral ? 'Actualizar' : 'Registrar Central'}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="primary" onClick={setShowModal}>
                            <IonIcon icon={closeOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="marca-container">
                <form onSubmit={handleSumit}>
                    <IonItem>
                        <IonLabel className="item-label" position="floating">
                            Nombre
                        </IonLabel>
                        <IonInput
                            type="text"
                            name="name"
                            value={name}
                            onIonChange={handleInputChange}
                            autocomplete="off"
                            placeholder="Nombre"
                            disabled={activeCentral}
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="item-label" position="floating">
                            Dirección
                        </IonLabel>
                        <IonInput
                            type="text"
                            name="calle"
                            value={calle}
                            onIonChange={handleInputChange}
                            autocomplete="off"
                            placeholder="Calle"
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="item-label" position="floating">
                            Ciudad
                        </IonLabel>
                        <IonInput
                            type="text"
                            name="city"
                            value={city}
                            onIonChange={handleInputChange}
                            autocomplete="off"
                            placeholder="Ciudad"
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="item-label" position="floating">
                            Teléfono
                        </IonLabel>
                        <IonInput
                            type="text"
                            name="phone"
                            value={phone}
                            inputmode="numeric"
                            onIonChange={handleInputChange}
                            autocomplete="off"
                            placeholder="Teléfono"
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="item-label" position="floating">
                            Codigo Zip
                        </IonLabel>
                        <IonInput
                            type="text"
                            name="zip"
                            value={zip}
                            inputmode="numeric"
                            onIonChange={handleInputChange}
                            autocomplete="off"
                            placeholder="Nombre"
                        ></IonInput>
                    </IonItem>
                    <IonItem lines="none" className="marca-item">
                        <IonButton slot="end" fill="clear" type="submit">
                            Guardar
                        </IonButton>
                        <IonButton slot="end" fill="clear" onClick={setShowModal}>
                            Cerrar
                        </IonButton>
                    </IonItem>
                </form>
            </IonContent>
        </>
    );
};

export default CentralScreen;