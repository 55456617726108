import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react'
import { addOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setModalEditEstadoHide, setModalEditEstadoShow, setModalEditHide, setModalEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  estado: "",
}
const estados = [
  {id: 1, name: 'BUENO'},
  {id: 2, name: 'EN FALLA'},
  {id: 3, name: 'APAGADO'},
  {id: 4, name: 'RESERVA'},
  {id:5, name: 'OPERATIVO'},
  {id:6, name: 'NO OPERATIVO'},  
  {id:7, name: 'DESCONECTADO'},
  {id:8, name: 'ABIERTO'},
  {id:9, name: 'CERRADO'}
];
export const StatusItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { estado } } = useSelector((state: RootState) => state.equipo);
  const {editModal, editEstado} = useSelector((state: RootState) => state.ui);  

  const handleEdit = () => {
    dispatch(setModalEditEstadoShow());
  }
  const handleUpdate = () => {
    dispatch(setModalEditShow())
    
  }

  const handleDelete = () => {
    console.log('click')
  }

  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Estado
      </IonLabel>
      <IonItemSliding>
        <IonItem lines="none">
        <IonLabel>{estado}</IonLabel>
        {editModal && <EstadoModal />}
        {editEstado && <EstadoEditModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleEdit() }}><IonIcon className="icon-list" color="success" icon={addOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const EstadoModal: FC = () => {
  const dispatch = useDispatch();

  const { editModal } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { estado } = formValues;
  useEffect(() => {
    if (activeEquipo.estado) {
      const m = { estado: activeEquipo.estado};
      setFormValues(m)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.estado])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    estado: activeEquipo.estado
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.estado = estado
    dispatch(startEquipoUpdated(equipo));
    dispatch(setModalEditHide())
  };

  const handleClose = () => {
    dispatch(setModalEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editModal} cssClass='modal-marca'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Estado</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleUpdate}>
            <IonItem lines="none">
              <IonLabel>Estado</IonLabel>
              <IonSelect name="estado" value={estado} onIonChange={handleInputChange}>
                {estados.map((estado: any) => (
                  <IonSelectOption key={estado.name} value={estado.name}>
                    {estado.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <IonItem lines="none" className="marca-item">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}

const EstadoEditModal: FC = () => {
  const dispatch = useDispatch();

  const { editEstado } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { estado } = formValues;
  useEffect(() => {
    if (activeEquipo.estado) {
      const m = { estado: activeEquipo.estado};
      setFormValues(m)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.estado])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    estado: activeEquipo.estado
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.estado = estado
    dispatch(startEquipoUpdated(equipo));
    dispatch(setModalEditEstadoHide())
  };

  const handleClose = () => {
    dispatch(setModalEditEstadoHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editEstado} cssClass='modal-marca'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Estado</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleUpdate}>
            <IonItem lines="inset">
              <IonInput
                type="text"
                name="estado"
                value={estado}
                onIonChange={handleInputChange}
                placeholder="Estado"
              ></IonInput>
            </IonItem>

            <IonItem lines="none" className="marca-item">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
