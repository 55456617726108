import { fetchConToken } from "../helpers/fetch";
import { Label } from "../interfaces/Label";
import { types } from "../types/types";
import { setInactiveLoading, setModalLabelHide, setToast } from "./ui";

export const startLoadLabels = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken("labels", {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(loadLabels(body.labels))
        } else {
            console.log(body.msg);
        }
    };
};

const loadLabels = (labels: Label) => ({
    type: types.labelStartLoad,
    payload: labels
})

export const labelSetActive = (label: Label) => ({
    type: types.labelSetActive,
    payload: label
});

export const clearActiveLabel = () => ({
    type: types.labelClearActive
});

export const labelStartAddNew = (label: Label) => {
    return async (dispatch: any, getState: any) => {
        const { uid, name } = getState().auth;
        try {
            const resp = await fetchConToken("labels", label, "POST");
            const body = await resp.json();
            if (body.ok) {                
                label.id = body.label.id;
                label.user = {
                    _id: uid,
                    name
                };
                dispatch(labelAddNew(label));
                const toast = {
                    title: '',
                    msg: 'El item ha sido agregado exitosamente',
                    color: "success"
                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalLabelHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            dispatch(setToast(toast));
        }
    };
};

const labelAddNew = (label: Label) => ({
    type: types.labelStartAdd,
    payload: label
});

export const startLabelUpdated = (label: Label) => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().label.activeLabel;
        try {
            const resp = await fetchConToken(`labels/${id}`, label, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(labelUpdated(body.label));
                const toast = {
                    title: '',
                    msg: 'El item ha sido actualizado',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalLabelHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(error);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    };
};

const labelUpdated = (label: Label) => ({
    type: types.labelStartUpdated,
    payload: label,
});

export const starLabelDeleted = () => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().label.activeLabel;
        try {
            const resp = await fetchConToken(`labels/${id}`, {}, "DELETE");
            const body = await resp.json();
            if (body.ok) {
                dispatch(labelDeleted());
                const toast = {
                    title: '',
                    msg: 'Se ha eliminado el item exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))
            } else {
                console.log(body)
                let msg = "";
                const errors = JSON.parse(JSON.stringify(body.errors));
                for (const i in errors) {
                    msg = errors[i].msg;
                }
                console.log(`Error ${msg}`);
                const toast = {
                    title: 'Error',
                    msg,
                    color: "danger"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))

            }
        } catch (error) {
            console.error(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    }
};

const labelDeleted = () => ({
    type: types.labelStartDeleted,
});
