import { types } from "../types/types";

// acciones para activar y desactivar el loading

export const setActiveLoading = () => ({
    type: types.uiSetActiveLoading
});

export const setInactiveLoading = () => ({
    type: types.uiSetInactiveLoading
})

// acciones para activar y desactivar las alertas
export const startSetActiveAlert = (alert: any) => ({
    type: types.uiSetActiveAlert,
    payload: alert
});

export const startSetInactiveAlert = () => ({
    type: types.uiSetInactiveAlert
})


//editar tag
export const setModalTagShow = () => ({
    type: types.uiModalTagSetShow
});

export const setModalTagHide = () => ({
    type: types.uiModalTagSetHide
});

//editar estado editable
export const setModalEditNumeroShow = () => ({
    type: types.uiModalNumeroEditShow
});

export const setModalEditNumeroHide = () => ({
    type: types.uiModalNumeroEditHide
});

//editar estado editable
export const setActiveLoadingDataShow = () => ({
    type: types.uiSetActiveLoadingData
});

export const setActiveLoadingDataHide = () => ({
    type: types.uiSetInactiveLoadingData
});


//editar estado editable
export const setModalEditEstadoShow = () => ({
    type: types.uiModalEstadoEditShow
});

export const setModalEditEstadoHide = () => ({
    type: types.uiModalEstadoEditHide
});

//editar description
export const setModalDescriptionShow = () => ({
    type: types.uidModalDescriptionSetShow
});

export const setModalDescriptionHide = () => ({
    type: types.uidModalDescriptionSetHide
});

export const setModalCentralShow = () => ({
    type: types.uiModalCentralSetShow
});

export const setModalCentralHide = () => ({
    type: types.uiModalCentralSetHide
});

// abrir modal para editar marca

export const setModalMarcaShow = () => ({
    type: types.uiModalMarcaSetShow
});

export const setModalMarcaHide = () => ({
    type: types.uiModalMarcaSetHide
});

// abrir modal para editar tipo
export const setModalTypeShow = () => ({
    type: types.uiModalTypeSetShow
});

export const setModalTypeHide = () => ({
    type: types.uiModalTypeSetHide
});


// abrir modal para editar tipo
export const setModalLabelShow = () => ({
    type: types.uiModalLabelSetShow
});

export const setModalLabelHide = () => ({
    type: types.uiModalLabelSetHide
});


export const setModalCanalShow = () => ({
    type: types.uiModalCanalSetShow
});

export const setModalCanalHide = () => ({
    type: types.uiModalCanalSetHide
});

export const setModalEditShow = () => ({
    type: types.uiEditModalSetShow
});

export const setModalEditHide = () => ({
    type: types.uiEditModalSetHide
});

export const setModeloEditShow = () => ({
    type: types.uiEditModeloSetShow
});

export const setModeloEditHide = () => ({
    type: types.uiEditModeloSetHide
});

// abrir alerta editar frecuencia
export const setFrecuenciaEditShow = () => ({
    type: types.uiEditFrecuenciaSetShow
});

export const setFrecuenciaEditHide = () => ({
    type: types.uiEditFrecuenciaSetHide
});

// abrir alerta editar voltaje nominal
export const setVoltajeNominalEditShow = () => ({
    type: types.uiEditVoltajeNominalShow
});

export const setVolatajeNominalEditHide = () => ({
    type: types.uiEditVoltajeNominalHide
});


// abrir alerta editar corriente nominal
export const setCorrienteNominalEditShow = () => ({
    type: types.uiEditCorrienteNominalSetShow
});

export const setCorrienteNominalEditHide = () => ({
    type: types.uiEditCorrienteNominalSetHide
});


// abrir alerta editar ubicacion
export const setUbicacionEditShow = () => ({
    type: types.uiEditUbicacionSetShow
});

export const setUbicacionEditHide = () => ({
    type: types.uiEditUbicacionSetHide
});


// abrir alerta editar fecha
export const setFechaInstalacionEditShow = () => ({
    type: types.uiEditFechaInstalacionSetShow
});

export const setFechaInstalacionEditHide = () => ({
    type: types.uiEditFechaInstalacionSetHide
});

// abrir alerta editar marca
export const setMarcaEditShow = () => ({
    type: types.uiModalEditMarcaSetShow
});

export const setMarcaEditHide = () => ({
    type: types.uiModalEditMarcaSetHide
});

// abrir alerta editar capacidad
export const setCapacidadEditShow = () => ({
    type: types.uiModalEditCapacidadSetShow
});

export const setCapacidadEditHide = () => ({
    type: types.uiModalEditCapacidadSetHide
});

// abrir alerta editar tableros
export const setTablerosEditShow = () => ({
    type: types.uiModalEditTablerosSetShow
});

export const setTablerosEditHide = () => ({
    type: types.uiModalEditTablerosSetHide
});

// abrir alerta editar protecciones
export const setProteccionesEditShow = () => ({
    type: types.uiModalEditProteccionesSetShow
});

export const setProteccionesEditHide = () => ({
    type: types.uiModalEditProteccionesSetHide
});

// abrir alerta editar medicionesAC
export const setMedicionesACEditShow = () => ({
    type: types.uiModalEditMedicionesACSetShow
});

export const setMedicionesACEditHide = () => ({
    type: types.uiModalEditMedicionesACSetHide
});

// abrir alerta editar medicionesCC
export const setMedicionesCCEditShow = () => ({
    type: types.uiModalEditMedicionesCCSetShow
});

export const setMedicionesCCEditHide = () => ({
    type: types.uiModalEditMedicionesCCSetHide
});

// abrir alerta editar canalizaciones
export const setCanalizacionesEditShow = () => ({
    type: types.uiModalEditCanalizacionSetShow
});

export const setCanalizacionesEditHide = () => ({
    type: types.uiModalEditCanalizacionSetHide
});

// abrir alerta editar alimentadores AC
export const setAlimentadoresACEditShow = () => ({
    type: types.uiModalEditAlimentadoresACSetShow
});

export const setAlimentadoresACEditHide = () => ({
    type: types.uiModalEditAlimentadoresACSetHide
});

// abrir alerta editar alimentadores CC
export const setAlimentadoresCCEditShow = () => ({
    type: types.uiModalEditAlimentadoresCCSetShow
});

export const setAlimentadoresCCEditHide = () => ({
    type: types.uiModalEditAlimentadoresCCSetHide
});


// abrir alerta editar circuitos media tension
export const setCircuitosMTEditShow = () => ({
    type: types.uiModalEditCircuitosMediaTensionSetShow
});

export const setCircuitosMTEditHide = () => ({
    type: types.uiModalEditCircuitosMediaTensionSetHide
});

// abrir alerta editar generadores
export const setGeneradoresEditShow = () => ({
    type: types.uiModalEditGeneradoresSetShow
});

export const setGeneradoresEditHide = () => ({
    type: types.uiModalEditGeneradoresSetHide
});

// abrir alerta editar transformadores
export const setTransformadoresEditShow = () => ({
    type: types.uiModalEditTrasformadoresSetShow
});

export const setTransformadoresEditHide = () => ({
    type: types.uiModalEditTrasformadoresSetHide
});

// abrir alerta editar transformadores medición
export const setTransformadoresMEditShow = () => ({
    type: types.uiModalEditTrasformadoresMedicionSetShow
});

export const setTransformadoresMEditHide = () => ({
    type: types.uiModalEditTrasformadoresMedicionSetHide
});

// abrir alerta editar transferencias automaticas
export const setTransferenciasMEditShow = () => ({
    type: types.uiModalEditTransferenciasAutomaticasSetShow
});

export const setTransferenciasMEditHide = () => ({
    type: types.uiModalEditTransferenciasAutomaticasSetHide
});

// abrir alerta para agregar descripcion
export const startAddImageDescripcionSwow = () => ({
    type: types.uiEditModalAddDescriptionShow
});

export const startAddImageDescripcionHide = () => ({
    type: types.uiEditModalAddDescriptionHide
});

// abrir alerta para agregar descripcion
export const startAddImageLocalDescripcionSwow = () => ({
    type: types.uiEditModalAddLocalDescriptionShow
});

export const startAddImageLocalDescripcionHide = () => ({
    type: types.uiEditModalAddLocalDescriptionHide
});

// abrir alerta para agregar banco baterias
export const startAddEquipoRespaldoSwow = () => ({
    type: types.uiModalEditEquipoRespladoSetShow
});

export const startAddEquipoRespaldoHide = () => ({
    type: types.uiModalEditEquipoRespladoSetHide
});

// abrir alerta para agregar numero celda
export const startAddNumeroCeldaSwow = () => ({
    type: types.uiModalEditNumeroCeldaSetShow
});

export const startAddNumeroCeldaHide = () => ({
    type: types.uiModalEditNumeroCeldaSetHide
});

// abrir alerta para agregar rectificador
export const startAddRectificadorSwow = () => ({
    type: types.uiModalEditRectificadorSetShow
});

export const startAddRectificadorHide = () => ({
    type: types.uiModalEditRectificadorSetHide
});

// abrir alerta para agregar celdas mto
export const startAddCeldasMtoSwow = () => ({
    type: types.uiModalEditCeldasMtoSetShow
});

export const startAddCeldasMtoHide = () => ({
    type: types.uiModalEditCeldasMtoSetHide
});

// abrir alerta para editar unidades
export const startAddUnidadesMtoSwow = () => ({
    type: types.uiModalEditUnidadesSetShow
});

export const startAddUnidadesMtoHide = () => ({
    type: types.uiModalEditUnidadesSetHide
});


// abrir alerta para agregar inversor
export const startAddInversorSwow = () => ({
    type: types.uiModalEditInversorSetShow
});

export const startAddInversorHide = () => ({
    type: types.uiModalEditInversorSetHide
});

// abrir alerta para agregar ups
export const startAddUpsSwow = () => ({
    type: types.uiModalEditUpsSetShow
});

export const startAddUpsHide = () => ({
    type: types.uiModalEditUpsSetHide
});

// abrir alerta para agregar aires
export const startAddAiresSwow = () => ({
    type: types.uiModalEditAiresSetShow
});

export const startAddAiresHide = () => ({
    type: types.uiModalEditAiresSetHide
});

// abrir modal para actualizar pass
export const startUpdatePasswordSwow = () => ({
    type: types.uiModalUpdatePasswordSetShow
});

export const startUpdatePasswordHide = () => ({
    type: types.uiModalUpdatePasswordSetHide
});

// abrir modal equipo
export const setModalEquipoShow = () => ({
    type: types.uiModalEquipoSetShow
});

export const setModalEquipoHide = () => ({
    type: types.uiModalEquipoSetHide
});

export const setToast = (toast: any) => {
    return async (dispatch: any) => {
        await dispatch(setToastShow(toast));
        setTimeout(() => {
            dispatch(setToastHide())
        }, 2000);

    }
}

export const setToastFacil = (toast: any) => {
    return async (dispatch: any) => {
        await dispatch(setToastActive(toast));
        setTimeout(() => {
            dispatch(setToastInactive())
        }, 2000);

    }
}

const setToastActive = (toast: any) => ({
    type: types.uiToastSetActive,
    payload: toast
});

const setToastInactive = () => ({
    type: types.uiToastSetInactive
});

const setToastShow = (toast: any) => ({
    type: types.uiToastSetShow,
    payload: toast
});

const setToastHide = () => ({
    type: types.uiToastSetHide
})

export const equipoCheckingCreate = () => ({
    type: types.uiChekingCreate,
});

export const equipounCheckingCreate = () => ({
    type: types.uiunChekingCreate,
});

export const startSetDesdeShow = () =>({
    type: types.uiModalDesdeSetShow
});

export const startSetDesdeHide = () =>({
    type: types.uiModalDesdeSetHide
});

export const startSetHastaShow = () =>({
    type: types.uiModalHastaSetShow
});

export const startSetHastaHide = () =>({
    type: types.uiModalHastaSetHide
});

export const startSetObservacionesShow = () =>({
    type: types.uiEditModalObservacionesShow
});

export const startSetObservacionesHide = () =>({
    type: types.uiEditModalObservacionesHide
});