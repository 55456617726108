import { FC, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { centralSetActive, startLoadCentrales } from "../../actions/central";
import { RootState } from "../../reducers/rootReducer";
import { startClearEquipos, startLoadEquipos } from "../../actions/equipos";


export const EquiposByCentral: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(startLoadCentrales());
    }, [dispatch])

    const { centrales } = useSelector((state: RootState) => state.central); 

    const handleViewEquipos = (central: any) => {
        dispatch(startClearEquipos());
        dispatch(centralSetActive(central));
        history.push('/equipos')
    }
    return (
        <>
            <IonList>
                <IonListHeader>
                    <IonLabel class="title">Centrales</IonLabel>
                </IonListHeader>

                {
                    centrales.map((c: any) =>
                    (
                        <IonItem button onClick = {() => handleViewEquipos(c)} detail key={c.id}>
                            <IonLabel>
                             {c.name}
                             </IonLabel>
                        </IonItem>
            )
            )
                }
        </IonList>
        </>
    )
}