import { FC, useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonContent,
  IonIcon,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import {
  setActiveLoading,
  setModalMarcaHide,
  setToast,
} from "../../actions/ui";
import { Marca } from "../../interfaces/Marca";
import {
  clearActiveMarca,
  marcaStartAddNew,
  startMarcaUpdated,
} from "../../actions/marcas";
import { closeOutline } from "ionicons/icons";

const initMarca = {
  name: "",
};

export const MarcaScreen: FC = () => {
  const dispatch = useDispatch();
  const { activeMarca } = useSelector((state: RootState) => state.marca);
  const [formValues, setFormValues] = useState(initMarca);

  const { name } = formValues;

  useEffect(() => {
    if (activeMarca) {
      const marca = {
        name: activeMarca.name,
      };
      setFormValues(marca);
    } else {
      setFormValues(initMarca);
    }
  }, [activeMarca]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const marca: Marca = {
    id: "",
    name,
    user: {
      _id: "",
      name: "",
    },
  };

  const handleSumit = (e: any) => {
    e.preventDefault();

    if (name.length <= 1) {
      const toast = {
        title: "Error",
        msg: "El nombre es un campo obligatorio",
        color: "danger",
      };

      dispatch(setToast(toast));
      return;
    }
    dispatch(setActiveLoading());
    if (activeMarca) {
      marca.id = activeMarca.id;
      marca.user = activeMarca.user;
      dispatch(startMarcaUpdated(marca));
      dispatch(clearActiveMarca());
    } else {
      dispatch(marcaStartAddNew(marca));
    }
  };

  const setShowModal = () => {
    setFormValues(initMarca);
    dispatch(clearActiveMarca());
    dispatch(setModalMarcaHide());
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle> {activeMarca ? "Actualizar" : "Registrar Marca"}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={setShowModal}>
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="marca-container">
        <form onSubmit={handleSumit}>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              Nombre
            </IonLabel>
            <IonInput
              type="text"
              name="name"
              value={name}
              onIonChange={handleInputChange}
              autocomplete="off"
              placeholder="Nombre"
            ></IonInput>
          </IonItem>
          <IonItem lines="none" className="marca-item">
            <IonButton slot="end" fill="clear" type="submit">
              Guardar
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={setShowModal}>
              Cerrar
            </IonButton>
          </IonItem>
        </form>
      </IonContent>
    </>
  );
};
