import { FC } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
  IonToast,
  IonLoading
} from "@ionic/react";
import { Route } from "react-router-dom";

import { logOutOutline } from 'ionicons/icons'
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "../../actions/auth";
import { RootState } from "../../reducers/rootReducer";
import CentralScreen from "../central/CentralScreen";
import { EquipoScreen } from "../equipo/EquipoScreen";
import { MarcaScreen } from "../marca/MarcaScreen";
import { CanalizacionScreen } from "../canalizacion/CanalizacionScreen";
import { TypeScreen } from "../type/TypeScreen";
import { LabelScreen } from "../name/LabelScreen";
import { ChangeLogin } from "../auth/ChangeLogin";

interface ContainerProps {
  title: string;
  component: FC;
}
export const BaseComponent: React.FC<ContainerProps> = ({ title, component}) => {
  
  const dispatch = useDispatch();
  const { uid } = useSelector((state: RootState) => state.auth)
   const {toast: {present, msg, color, header}, activeLoading} = useSelector((state: RootState) => state.ui)
  const { centralModal, marcaModal, canalModal, typeModal, labelModal, modalUpdatePassword } = useSelector((state: RootState) => state.ui);
  const handleLogout = () => {
    console.log('click')
    dispatch(startLogout());
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          {
            !!uid && <IonButtons slot="end">
              <IonButton color="primary" onClick={handleLogout}>
                <IonIcon icon={logOutOutline} />
              </IonButton>
            </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonToast
          isOpen={present}
          message={msg}
          position="middle"
          onDidDismiss={() => activeLoading}
          duration={2000}
          color={color}
          header={header}
        ></IonToast>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={activeLoading}
          onDidDismiss={() => activeLoading}
          message={'Guardando...'}
          onWillDismiss={() => activeLoading}
        />
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonModal cssClass="modal-change-password" isOpen={modalUpdatePassword}  backdropDismiss={false}>
          <ChangeLogin />
        </IonModal> 
        <IonModal cssClass="modal-central" isOpen={centralModal}  backdropDismiss={false}>
          <CentralScreen />
        </IonModal>        
        <IonModal cssClass="modal-marca" isOpen={marcaModal} backdropDismiss={false}>
          <MarcaScreen />
        </IonModal>
        <IonModal cssClass="modal-marca" isOpen={typeModal}  backdropDismiss={false}>
          <TypeScreen />
        </IonModal>
        <IonModal cssClass="modal-marca" isOpen={labelModal} backdropDismiss={false}>
          <LabelScreen />
        </IonModal>
        <IonModal cssClass="modal-marca"  isOpen={canalModal} backdropDismiss={false}>
          <CanalizacionScreen />
        </IonModal>
        <Route component={component} />
      </IonContent>
    </IonPage>
  );
};
