import {BaseComponent} from '../components/base/BaseComponent';
import { ListComponent } from '../components/ui/ListComponent';


const Listas: React.FC = () => {

  return (
    <BaseComponent title="Listas" component={ListComponent}  />
  );
};

export default Listas;
