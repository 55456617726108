import { IonAlert } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { starCanalizacionDeleted } from '../../actions/canalizaciones';
import { starCentralDeleted } from '../../actions/central';
import { startEquipoDeleted } from '../../actions/equipos';
import { starImageDeleted } from '../../actions/images';
import { starLabelDeleted } from '../../actions/labels';
import { starMarcaDeleted } from '../../actions/marcas';
import { starTypeDeleted } from '../../actions/types';
import { setActiveLoading, startSetInactiveAlert } from '../../actions/ui';
import { RootState } from '../../reducers/rootReducer';

interface Props {
    onPress?: () => void;
    onDesactive?: () => void;

}
export const AlertComponent = ({ onPress, onDesactive}: Props) => {
    const dispatch = useDispatch();
    const {activeAlert:{active, header, message, tipo}} = useSelector((state: RootState) => state.ui)
    const handleClose = () => {
        dispatch(startSetInactiveAlert());
        onDesactive()
      };
      const actionsDelete = () => {
        dispatch(setActiveLoading());
        if(tipo === 'types'){          
          dispatch(starTypeDeleted());
        }else if(tipo === 'central'){
          dispatch(starCentralDeleted());
        }else if( tipo === 'marca'){
          dispatch(starMarcaDeleted());
        }else if(tipo === 'equipo'){
          dispatch(startEquipoDeleted());
        }else if(tipo === 'canalizacion'){
          dispatch(starCanalizacionDeleted());
        }else if(tipo === 'image'){
          dispatch(starImageDeleted());
        }else if(tipo === 'labels'){
          dispatch(starLabelDeleted())
        }else if(tipo === 'types'){
          dispatch(starTypeDeleted())
        }
          
      }
    return (
       <IonAlert
          isOpen={active}
          onDidDismiss={() => handleClose}
          cssClass='my-custom-class'
          header={header}
          message={message}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                onDesactive();
                dispatch(startSetInactiveAlert());
              }
            },
            {
              text: 'Eliminar',
              handler: () => {
                actionsDelete();
                dispatch(startSetInactiveAlert())
              }
            }
          ]}
        />
    )
}
