import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setModalEditNumeroHide, setModalEditNumeroShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  numero_equipo: "",
}

export const NumeroItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { numero_equipo } } = useSelector((state: RootState) => state.equipo);
  const {editNumero} = useSelector((state: RootState) => state.ui);  

  
  const handleUpdate = () => {
    dispatch(setModalEditNumeroShow())
    
  }

  const handleDelete = () => {
    console.log('click')
  }

  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Numero Equipo
      </IonLabel>
      <IonItemSliding>
        <IonItem lines="none">
        <IonLabel>{numero_equipo}</IonLabel>
        {editNumero && <NumeroEditModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const NumeroEditModal: FC = () => {
  const dispatch = useDispatch();

  const { editNumero } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { numero_equipo } = formValues;
  useEffect(() => {
    if (activeEquipo.numero_equipo) {
      const m = { numero_equipo: activeEquipo.numero_equipo};
      setFormValues(m)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.numero_equipo])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.numero_equipo = numero_equipo
    dispatch(startEquipoUpdated(equipo));
    dispatch(setModalEditNumeroHide())
  };

  const handleClose = () => {
    dispatch(setModalEditNumeroHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editNumero} cssClass='modal-marca'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Numero de Equipo</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleUpdate}>
            <IonItem lines="inset">
              <IonInput
                type="text"
                name="numero_equipo"
                value={numero_equipo}
                onIonChange={handleInputChange}
                placeholder="Estado"
              ></IonInput>
            </IonItem>

            <IonItem lines="none" className="marca-item">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
