import { FC } from "react";
import { CanalizacionList } from "../canalizacion/CanalizacionList";
import { CentralList } from "../central/CentralList";
import { MarcaList } from "../marca/MarcaList";
import { LabelListScreen } from "../name/LabelListScreen";
import { TypeListScreen } from "../type/typeLilstScreen";

export const ListComponent: FC = () =>{
    return(
    <>
    <TypeListScreen />
    <LabelListScreen />
    <CentralList />
    <MarcaList />
    <CanalizacionList />
    </>
    )
};