import { fetchConToken, fetchImagesSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import { setToastFacil, setToast, setInactiveLoading } from './ui';


export const startLoadImages = () => {
  return async (dispatch: any) => {
    try {
     const resp = await fetchConToken("images", {});
     const body = await resp.json();
      if (body.ok) {
        dispatch({
          type: types.imageStartLoadImages,
          payload: body.images,
        });
      } 
    } catch (error) {
      console.log(error);
    }      
    }
  };

export const startLoadLocalImages = (image: any) => {
  return async (dispatch: any) => {
    const imagen = image;
    await dispatch(loadLocalImages(imagen));
  };
};

export const startSaveImages = (images: any) => {
  return async (dispatch: any) => {
    try {
      const resp = await fetchImagesSinToken("images", images, "POST");
      const body = await resp.json();
      if (body) {
        const data = {
          ...body,
          id_server: body.id};
        
        const resp = await fetchConToken("images", data, "POST");
        const bodyImage = await resp.json();
        if (bodyImage) {
          await dispatch(startSavedImages(bodyImage.image));
          dispatch(startClearedLocalImages());
          const toast = {
            title: '',
            msg: 'La imagen han sido agregadas',
            color: "success",
            present: true,
        }
        dispatch(setInactiveLoading())
          dispatch(setToastFacil(toast))
        }else{
            const toast = {
                title: '',
                msg: 'Error, contacte al administrador',
                color: "danger",
                present: true,
            }
            console.log('error en images')
            dispatch(setInactiveLoading())
              dispatch(setToastFacil(toast))
        }
      }else{
        const toast = {
            title: '',
            msg: 'Error, contacte al administrador',
            color: "danger",
            present: true,
        }
        dispatch(setInactiveLoading())
          dispatch(setToastFacil(toast))
      }
    } catch (error) {
        const toast = {
            title: '',
            msg: 'Error, contacte al administrador',
            color: "danger",
            present: true,
        }
        dispatch(setInactiveLoading())
          dispatch(setToastFacil(toast))
    }
  };
};

const startSavedImages = (image: any) => ({
  type: types.imageStartSaveImages,
  payload: image,
});

export const startImageUpdated = (image: any) => {
  return async (dispatch: any) => {
   
     const id = image.id;
      try {
          const resp = await fetchConToken(`images/${id}`, image, 'PUT');
          const body = await resp.json();
          if (body.ok) {
              dispatch(startImgesUpdated(body.image));
              console.log(body.image);
              const toast = {
                  title: '',
                  msg: 'La imagen ha sido actualizada exitosamente',
                  color: "success"

              }
              await dispatch(setToast(toast));
          } else {
              if(body.msg){
                  const toast = {
                      title: 'Error',
                      msg:body.msg,
                      color: "danger"
  
                  }
                  await dispatch(setToast(toast))
              }else{
                  let msg = "";
                  const errors = JSON.parse(JSON.stringify(body.errors));
                  for (const i in errors) {
                      msg = errors[i].msg;
                  }
                  console.log(`Error ${msg}`);
                  const toast = {
                      title: 'Error',
                      msg,
                      color: "danger"
  
                  }
                  await dispatch(setToast(toast));
              }
          }
      } catch (error) {
          console.log(error);
          const toast = {
              title: 'Error',
              msg: "Contacta al administrador",
              color: 'danger'
          }

          await dispatch(setToast(toast))
      }
  };
};

const startImgesUpdated = (image: any) => ({
  type: types.imagesSetDescription,
  payload: image
});

export const startClearedLocalImages = () => ({
  type: types.imageStartClearLocal,
});

export const loadLocalImages = (image: any) => ({
  type: types.imageSetLocalSaveImages,
  payload: image,
});

export const startAddLocalImageDescription = (payload: any) => ({
  type: types.imageSetLocalImageDescription,
  payload,
});

export const starImageDeleted = () => {
  return async (dispatch: any, getState: any) => {

    const {id, id_server, name} = getState().image.activeImage; 
    await fetchImagesSinToken(`images/${id_server}`, {}, 'DELETE');
    try {     
      const resp = await fetchConToken(`images/${id}`, {name}, "DELETE");
      const body = await resp.json();
      if (body.ok) {        
        dispatch(imageDeleted(id));
          const toast = {
              title: '',
              msg: 'La imagen ha sido eliminada exitosamente',
              color: "success"

          }
          dispatch(setInactiveLoading());
          await dispatch(setToast(toast));
          dispatch(startSetInactiveImage());
      } else {  
          console.log(`Error ${body.msg}`);
          const toast = {
              title: 'Error',
              msg: body.msg,
              color: "danger"

          }
          dispatch(setInactiveLoading());
          await dispatch(setToast(toast));

      }
  } catch (error) {
      console.error(`Error ${error}`);
      const toast = {
          title: 'Error',
          msg: "Contacta al administrador",
          color: 'danger'
      }
      dispatch(setInactiveLoading());
      await dispatch(setToast(toast));
  }
    
  };
};

const imageDeleted = (image: any) => ({
  type: types.imageStartDeleteImages,
  payload: image,
});

export const localImageDelete = (image: any) => ({
  type: types.imageStartLocalDelete,
  payload: image,
});

export const startSetActiveImage = (image: any) =>({
  type: types.imageSetActiveImage,
  payload: image
});

export const startSetInactiveImage = () =>({
  type: types.imageSetInactiveImage
});

export const startSetActiveImageLocal = (image: any) =>({
  type: types.imageSetActiveLocalImage,
  payload: image
});

export const startSetInactiveImageLocal = () =>({
  type: types.imageSetInactiveLocalImage
});