import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonRefresher, IonRefresherContent, IonSpinner, IonText } from "@ionic/react";
import { FC, useEffect } from "react";
import { addCircleOutline, arrowUndoCircleOutline, chevronForwardOutline, createOutline, trashOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from "react-redux";
import { clearActiveEquipo, equipoSetActive, startClearEquipos, startLoadEquipos } from "../../actions/equipos";
import { RootState } from "../../reducers/rootReducer";
import { setModalEquipoShow, startSetActiveAlert } from '../../actions/ui';
import { AlertComponent } from "../ui/AlertComponent";
import { RefresherEventDetail } from "@ionic/core";
import { useHistory } from "react-router-dom";
import { clearActiveCentral } from "../../actions/central";

export const EquipoListScreen: FC = () => {
    const dispatch = useDispatch();
    
    const history = useHistory();

    useEffect(() => {
        dispatch(startLoadEquipos());
    }, [dispatch]);
    
    const { equipos} = useSelector((state: RootState) => state.equipo);
    const { activeLoadingData } = useSelector((state: RootState) => state.ui);
    
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        dispatch(startLoadEquipos());
        setTimeout(() => {
            event.detail.complete();
        }, 1500)
    };
    const handleDelete = (equipo: any) => {
        const alert = {
            active: true,
            header: "Eliminar equipo",
            message: `El equipo ${equipo.nombre_equipo} ${equipo.tag} será eliminado`,
            tipo: 'equipo'
        }
        dispatch(startSetActiveAlert(alert))
        dispatch(equipoSetActive(equipo));
    }

    const onDesactive = () => {
        dispatch(clearActiveEquipo());
    };

    const handleUpdate = (equipo: any) => {
        dispatch(equipoSetActive(equipo));
        history.push('/equipo')
    };

    const handleAdd = () => {
        dispatch(clearActiveEquipo());
        dispatch(setModalEquipoShow());
    };

    const goBack = () => {
        dispatch(startClearEquipos());
        dispatch(clearActiveCentral());
        history.replace('/centrales');   
    };

    if(activeLoadingData) {
        return (
            <IonLoading
        cssClass='my-custom-class'
        isOpen={activeLoadingData}
        message={'Cargando...'}
        duration={activeLoadingData? 5000 : 0}
      />
        )
    }

    return (
        <>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <IonList>
                <IonListHeader>
                <IonButton onClick={goBack}><IonIcon className="icon-list" color="primary" icon={arrowUndoCircleOutline}></IonIcon></IonButton>
                    <IonLabel class="title">Equipos</IonLabel>
                    <IonButton onClick={handleAdd}><IonIcon className="icon-list" color="success" icon={addCircleOutline}></IonIcon></IonButton>
                </IonListHeader>

                {
                    equipos.length === 0 ?
                    <IonItem>
                        <IonText color="medium">Esta central no tiene equipos registrados</IonText>
                    </IonItem>
                    :equipos.map((e: any) =>
                    (<IonItemSliding key={e.id}>
                        <IonItem>
                            <IonText>{e.nombre_equipo} {e.tag} {e.ubicacion}</IonText>
                            <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
                        </IonItem>
                        <IonItemOptions side="end">
                            <IonItemOption color="white" onClick={() => { handleUpdate(e) }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
                            <IonItemOption color="white" onClick={() => { handleDelete(e) }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                    )
                    )
                }
                
            </IonList>
             {/* <EquipoScreen history={history} /> */}
            <AlertComponent onDesactive={onDesactive} />
        </>
    );
}