import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setCircuitosMTEditHide, setCircuitosMTEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  centro_distribucion: "",
  direccion: "",
  nombre_circuito: "",
  nomenclatura_circuito: "",
  punto_fisico: "",
}
export const CircuitosMediaTensionItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editCircuitosMT } = useSelector((state: RootState) => state.ui);
  const { circuitos_media_tension = {} } = activeEquipo;
  const { centro_distribucion = "", direccion = "", nombre_circuito = "", nomenclatura_circuito = "", punto_fisico = "" } = circuitos_media_tension;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setCircuitosMTEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Circuitos Media Tensión
      </IonLabel>
      {Object.keys(circuitos_media_tension).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>Centro distribución</IonText>
          <IonNote slot="end">{centro_distribucion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Dirección</IonText>
          <IonNote slot="end">{direccion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Nombre circuito</IonText>
          <IonNote slot="end">{nombre_circuito}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Nomenclatura circuito</IonText>
          <IonNote slot="end">{nomenclatura_circuito}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Punto físico</IonText>
          <IonNote slot="end">{punto_fisico}</IonNote>
        </IonItem>
      </IonList>}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(circuitos_media_tension).length !== 0 ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editCircuitosMT && <CircuitosMediaTensionItemModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const CircuitosMediaTensionItemModal: FC = () => {
  const dispatch = useDispatch();

  const { editCircuitosMT } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { centro_distribucion, direccion, nombre_circuito, nomenclatura_circuito, punto_fisico } = formValues;
  useEffect(() => {
    if (activeEquipo.circuitos_media_tension) {
      const cmt = {
        centro_distribucion: activeEquipo.circuitos_media_tension.centro_distribucion,
        direccion: activeEquipo.circuitos_media_tension.direccion,
        nombre_circuito: activeEquipo.circuitos_media_tension.nombre_circuito,
        nomenclatura_circuito: activeEquipo.circuitos_media_tension.nomenclatura_circuito,
        punto_fisico: activeEquipo.circuitos_media_tension.punto_fisico,
      };
      setFormValues(cmt)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.circuitos_media_tension])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    circuitos_media_tension: activeEquipo.circuitos_media_tension,

  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.circuitos_media_tension = {
      centro_distribucion,
      direccion,
      nombre_circuito,
      nomenclatura_circuito,
      punto_fisico
    }
    dispatch(startEquipoUpdated(equipo));
    dispatch(setCircuitosMTEditHide())
  };

  const handleClose = () => {
    dispatch(setCircuitosMTEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editCircuitosMT} cssClass='modal-central'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Circuitos Media Tensión</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Centro distribución
              </IonLabel>
              <IonInput
                type="number"
                name="centro_distribucion"
                value={centro_distribucion}
                onIonChange={handleInputChange}
                placeholder="Centro distribución"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Dirección
              </IonLabel>
              <IonInput
                type="text"
                name="direccion"
                value={direccion}
                onIonChange={handleInputChange}
                placeholder="Dirección"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Nombre circuito
              </IonLabel>
              <IonInput
                type="text"
                name="nombre_circuito"
                value={nombre_circuito}
                onIonChange={handleInputChange}
                placeholder="Nombre circuito"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Nomenclatura circuito
              </IonLabel>
              <IonInput
                type="text"
                name="nomenclatura_circuito"
                value={nomenclatura_circuito}
                onIonChange={handleInputChange}
                placeholder="Nomenclatura circuito"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Punto físico
              </IonLabel>
              <IonInput
                type="number"
                name="punto_fisico"
                value={punto_fisico}
                onIonChange={handleInputChange}
                placeholder="Punto físico"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}