import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadCanalizaciones } from "../../../actions/canalizaciones";
import { startEquipoUpdated } from "../../../actions/equipos";
import { setActiveLoading, startSetDesdeHide, startSetHastaHide } from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";

const initForm = {
    salon: "",
    tablero: "",
    bastidor: "",
    posicion: "",
    fila: "",
    pdu: "",
    capacidad: "",
    fuente: "",
};

export const CanalizacionHasta: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadCanalizaciones());
  }, [dispatch]);

  const { canalizaciones } = useSelector(
    (state: RootState) => state.canalizacion
  );

  const {hastaShow} = useSelector((state: RootState) => state.ui);

  const { activeEquipo} = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { salon, tablero, bastidor, posicion, fila, pdu, capacidad, fuente } = formValues;

  useEffect(() => {
    if (activeEquipo.canalizacion.hasta) {
      const hasta = {
        salon: activeEquipo.canalizacion.hasta.salon,
        tablero: activeEquipo.canalizacion.hasta.tablero,
        bastidor: activeEquipo.canalizacion.hasta.bastidor,
        posicion: activeEquipo.canalizacion.hasta.posicion,
        fila: activeEquipo.canalizacion.hasta.fila,
        pdu: activeEquipo.canalizacion.hasta.pdu,
        capacidad: activeEquipo.canalizacion.hasta.capacidad,
        fuente: activeEquipo.canalizacion.hasta.fuente,
      };
      setFormValues(hasta);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo.canalizacion.hasta]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central,
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    canalizacion: activeEquipo.canalizacion,
  };

  const handleUpdateDesde = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.canalizacion.hasta = {
      salon,
      tablero,
      bastidor,
      posicion,
      fila,
      pdu,
      capacidad,
      fuente,      
    };   
    dispatch(startEquipoUpdated(equipo));
    dispatch(startSetHastaHide());
  };

  const handleClose = () => {
    dispatch(startSetHastaHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={hastaShow} cssClass="modal-equipo">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Hasta</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
              <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdateDesde}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Salón
              </IonLabel>
              <IonInput
                type="text"
                name="salon"
                value={salon}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Tablero"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tablero
              </IonLabel>
              <IonInput
                type="text"
                name="tablero"
                value={tablero}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Tablero"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Bastidor
              </IonLabel>
              <IonInput
                type="text"
                name="bastidor"
                value={bastidor}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Bastidor"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Posición
              </IonLabel>
              <IonInput
                type="text"
                name="posicion"
                value={posicion}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Posición"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Fila
              </IonLabel>
              <IonInput
                type="text"
                name="fila"
                value={fila}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Fila"
              ></IonInput>
            </IonItem>            
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Pdu
              </IonLabel>
              <IonInput
                type="text"
                name="pdu"
                value={pdu}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Pdu"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Capacidad
              </IonLabel>
              <IonInput
                type="text"
                name="capacidad"
                value={capacidad}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Capacidad"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Fuente
              </IonLabel>
              <IonInput
                type="text"
                name="fuente"
                value={fuente}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Fuente"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
