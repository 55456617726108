import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { startSaveImages } from "./images";
import { equipoCheckingCreate, setActiveLoadingDataHide, setActiveLoadingDataShow, setInactiveLoading, setModalEquipoHide, setToast } from "./ui";

export const equipoStartAddNew = (equipo: any) => {
    return async (dispatch: any, getState: any) => {
        const { uid, name } = getState().auth;        
        try {
            const resp = await fetchConToken("equipos", equipo, "POST");
            const body = await resp.json();
            if (body.ok) {
                equipo.id = body.equipo.id;
                equipo.user = {
                    _id: uid,
                    name: name
                };                               
                dispatch(equipoAddNew(equipo));
                await dispatch(savePicture(equipo));
                dispatch(equipoSetActive(equipo));
                const toast = {
                    color: 'success',
                    msg: 'Equipo agregado exitosamente',
                    title: "",
                }
                dispatch(setInactiveLoading());
                dispatch(setToast(toast));
                dispatch(equipoCheckingCreate());
                dispatch(setModalEquipoHide());
            } else {
                if (body.msg) {
                    const toast = {
                        title: 'Error',
                        msg: body.msg,
                        color: "danger"

                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                } else {
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"

                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            const toast = {
                color: 'danger',
                msg: 'Contacta al administrador',
                title: "Error",
            }
            console.log('aqui entra');
            dispatch(setInactiveLoading());
            dispatch(setToast(toast));
        }
    };
};

const equipoAddNew = (equipo: any) => ({
    type: types.equipoStartAdd,
    payload: equipo
});

export const startLoadEquipos = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setActiveLoadingDataShow());
        const {name} = getState().central.activeCentral;
        
        const resp = await fetchConToken(`equipos/central/${name}`, {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(loadEquipos(body.equipos)); 
            dispatch(setActiveLoadingDataHide());          
        } else {
            console.log(body.msg);
            dispatch(setActiveLoadingDataHide());
        }
    };
};

const loadEquipos = (equipos: any) => ({
    type: types.equipoStartLoad,
    payload: equipos
});

export const equipoSetActive = (equipo: any) => ({
    type: types.equipoSetActive,
    payload: equipo
});

export const clearActiveEquipo = () => ({
    type: types.equipoClearActive
});

export const startClearEquipos = () => ({
    type: types.equipoCleared
});

export const startEquipoUpdated = (equipo: any) => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().equipo.activeEquipo;
        console.log(equipo);
        try {
            const resp = await fetchConToken(`equipos/${id}`, equipo, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(equipoUpdated(body.equipo));
                dispatch(equipoSetActive(body.equipo));
                const toast = {
                    title: '',
                    msg: 'El equipo ha sido actualizado exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalEquipoHide());
            } else {
                if (body.msg) {
                    const toast = {
                        title: 'Error',
                        msg: body.msg,
                        color: "danger"

                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                } else {
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"

                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(error);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    };
};

const equipoUpdated = (equipo: any) => ({
    type: types.equipoStartUpdated,
    payload: equipo,
});

export const startEquipoDeleted = () => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().equipo.activeEquipo;
        try {
            const resp = await fetchConToken(`equipos/${id}`, {}, "DELETE");
            const body = await resp.json();
            if (body.ok) {
                dispatch(equipoDeleted());
                const toast = {
                    title: '',
                    msg: 'El equipo ha sido eliminado exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))
            } else {
                console.log(body)
                let msg = "";
                const errors = JSON.parse(JSON.stringify(body.errors));
                for (const i in errors) {
                    msg = errors[i].msg;
                }
                console.log(`Error ${msg}`);
                const toast = {
                    title: 'Error',
                    msg,
                    color: "danger"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))

            }
        } catch (error) {
            console.error(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    }
};

const equipoDeleted = () => ({
    type: types.equipoStartDeleted,
});

const savePicture = (equipo: any) => {
    return async (dispatch: any, getState: any) => {
        const { localImages } = getState().image;
        if (localImages.length > 0) {
            await localImages.map((i: any) => {
                const data = {
                    equipo: equipo.id,
                    nombre_equipo: equipo.nombre_equipo,
                    description: equipo.descripcion_equipo,
                    image: i.image,
                }
                return dispatch(startSaveImages(data))
            })
        } else {
            const toast = {
                msg: "No hay imagen para almacenar",
                color: 'warning'
            }
            await dispatch(setToast(toast))
        }
    }
};