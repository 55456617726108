import {BaseComponent} from '../components/base/BaseComponent';
import { EquiposByCentral } from '../components/equipo/EquiposByCentral';
interface ContainerProps {
  title?: string;
}
const Centrales: React.FC<ContainerProps> = () => {
  return (
    <BaseComponent title="Centrales" component={EquiposByCentral}  />
  );
};

export default Centrales;