import {BaseComponent} from '../components/base/BaseComponent';
import { HomComponent } from '../components/ui/HomeComponent';


const Home: React.FC = () => { 
  
  return (
    <BaseComponent title="Inicio"  component={HomComponent}  />    
  );
};

export default Home;
