import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import { closeOutline, createOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setCorrienteNominalEditHide, setCorrienteNominalEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  corrienteNominal: '',
}

export const CorrienteNominalItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { corrienteNominal } } = useSelector((state: RootState) => state.equipo);
  const { editCorrienteNominal } = useSelector((state: RootState) => state.ui);

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setCorrienteNominalEditShow())

  }
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
          <IonLabel className="item-label" position="stacked" color="primary">
          Corriente Nominal
            </IonLabel>
          </IonCol>
        </IonRow>
       <IonRow>
       <IonCol size='9' size-sm>
        <IonLabel>{corrienteNominal}</IonLabel>
        </IonCol>
        <IonCol size='3' size-sm className="ion-align-self-center">
        <IonButton color="white" fill='clear' onClick={handleUpdate}>
            <IonIcon slot="icon-only" color="success" size='large' icon={createOutline} />
          </IonButton>
        </IonCol>
       </IonRow>
      </IonGrid>
      {editCorrienteNominal && <CorrienteNominalModal />}
    </IonItem>
  )
}

const CorrienteNominalModal: FC = () => {
  const dispatch = useDispatch();

  const { editCorrienteNominal } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { corrienteNominal } = formValues;
  useEffect(() => {
    if (activeEquipo.corrienteNominal) {
      const cn = {
        corrienteNominal: activeEquipo.corrienteNominal,
      };
      setFormValues(cn)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.corrienteNominal])

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    corrienteNominal: activeEquipo.corrienteNominal,

  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.corrienteNominal = corrienteNominal
    dispatch(startEquipoUpdated(equipo));
    dispatch(setCorrienteNominalEditHide())
  };

  const handleClose = () => {
    dispatch(setCorrienteNominalEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editCorrienteNominal} cssClass='modal-unit-iput'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle color="dark">Corriente Nominal</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem lines="inset">
              <IonInput
                type="number"
                inputMode="numeric"
                name="corrienteNominal"
                value={corrienteNominal}
                onIonChange={handleInputChange}
                placeholder="Corriente nominal"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>          
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
