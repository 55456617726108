import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonRow } from '@ionic/react'
import { chevronForwardOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setUbicacionEditHide, setUbicacionEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

export const UbicacionItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { ubicacion } } = useSelector((state: RootState) => state.equipo);
  const {editUbicacion} = useSelector((state: RootState) => state.ui);  

  const handleUpdate = () => {
    dispatch(setUbicacionEditShow())
    
  }
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
          <IonLabel className="item-label" position="stacked" color="primary">
             Ubicación
            </IonLabel>
          </IonCol>
        </IonRow>
       <IonRow>
       <IonCol size='9' size-sm>
        <IonLabel>{ubicacion}</IonLabel>
        </IonCol>
        <IonCol size='3' size-sm className="ion-align-self-center">
        <IonButton color="white" fill='clear' onClick={handleUpdate}>
            <IonIcon slot="icon-only" color="success" size='large' icon={createOutline} />
          </IonButton>
        </IonCol>
       </IonRow>
      </IonGrid>
      {editUbicacion && <UbicacionModal />} 
    </IonItem>)  
}

const UbicacionModal: FC = () => {
  const dispatch = useDispatch();
  const { editUbicacion } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  
  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    ubicacion: activeEquipo.ubicacion
  }
  const { ubicacion } = activeEquipo;

  const handleUpdate = ({name}) => {
    dispatch(setActiveLoading());
    equipo.ubicacion = name
    dispatch(startEquipoUpdated(equipo));
   dispatch(setUbicacionEditHide())
  };
  
  const handleClose = () => {
    dispatch(setUbicacionEditHide());
  };
  return (
    <IonContent>
      <IonAlert
          isOpen={editUbicacion}
          onDidDismiss={handleClose}
          header={'Ubicación'}
          inputs={[
            {
              name: 'name',
              type: 'text',
              placeholder: 'Ubicación',
              value: ubicacion
            },
          ]}
          buttons={[
            {
              text: 'Guardar',
              handler: (e) => {
                handleUpdate(e);
              }
            },
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('Confirm Cancel');
              }
            },            
          ]}
        />
    </IonContent>
  );
}
