import { types } from "../types/types";

const initialSate ={
    types: [],
    activeType: null
}

export const typeReducer = (state = initialSate, action: any) => {
    switch (action.type) {
        case types.typeStartLoad:
            return {
                ...state,
                types: action.payload
            };
        case types.typeStartAdd:
            return {
                ...state,
                types: [
                    ...state.types,
                    action.payload
                ]

            }

        case types.typeSetActive:
            return {
                ...state,
                activeType: action.payload
            };
        
        case types.typeClearActive:
            return {
                ...state,
                activeType:null
            };

        case types.typeStartUpdated:
            return {
                ...state,
                types: state.types.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            };
        
        case types.typeStartDeleted:
            return {
                ...state,
                types: state.types.filter(c => c.id !== state.activeType.id),
                activeType: null
            };
        
        case types.typeCleared:
            return {
                types: [],
                activeType: null
            };

        default:
            return state;
    }
};