import { IonAlert, IonContent } from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { startAddImageLocalDescripcionHide } from "../../actions/ui";
import { startAddLocalImageDescription, startSetInactiveImageLocal} from "../../actions/images";

interface Props {
  description?: string;
}
export const AddImageDescriptionGallery = ({ description }: Props) => {
  const dispatch = useDispatch();
  const { editLocalImageDescription } = useSelector((state: RootState) => state.ui);
  const {activeImageLocal: image} = useSelector((state: RootState) => state.image);

  const handleUpdate = ({ description }) => {
    dispatch(startAddLocalImageDescription({ description, image }));
    dispatch(startAddImageLocalDescripcionHide());    
  };

  const handleClose = () => {
    dispatch(startAddImageLocalDescripcionHide());
    dispatch(startSetInactiveImageLocal())
  };
  return (
    <IonContent>
      <IonAlert
        isOpen={editLocalImageDescription}
        onDidDismiss={handleClose}
        header={"Descripcion"}
        inputs={[
          {
            name: "description",
            type: "text",
            placeholder: "Descripción",
            value: image.description,
          },
        ]}
        buttons={[
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
            },
          },
          {
            text: "Agregar",
            handler: (e) => {
              handleUpdate(e);
            },
          },
        ]}
      />
    </IonContent>
  );
};
