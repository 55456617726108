import { types } from "../types/types";

const initialState = {
    centrales: [],
    activeCentral: null
}

export const centralReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.centralStartLoad:
            return {
                ...state,
                centrales: action.payload
            };
        case types.centralStartAdd:
            return {
                ...state,
                centrales: [
                    ...state.centrales,
                    action.payload
                ]

            }

        case types.centralSetActive:
            return {
                ...state,
                activeCentral: action.payload
            };
        
        case types.centralClearActive:
            return {
                ...state,
                activeCentral:null
            };

        case types.centralStartUpdated:
            return {
                ...state,
                centrales: state.centrales.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            };
        
        case types.centralStartDeleted:
            return {
                ...state,
                centrales: state.centrales.filter(c => c.id !== state.activeCentral.id),
                activeCentral : null
            };
        
        case types.centralCleared:
            return {
                centrales: [],
                activeCentral: null
            };

        default:
            return state;
    }
}