import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { startAddCeldasMtoSwow, startAddCeldasMtoHide, setActiveLoading } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  capacidad_fusible: "",
  derivacion: "",
  entrada_1: "",
  numero_celda: "",
  numero_polos: "",
  numero_salida: "",
  tipo: "",
  tipo_fusible: "",
  tipo_aislamiento: "",
}

export const CeldasMtoItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editCeldasMto } = useSelector((state: RootState) => state.ui);
  const { celdas_mt = {}, derivacion = "", entrada_1, numero_celda = "", numero_polos = "", numero_salida = "", tipo = "", tipo_aislamiento = "", } = activeEquipo;
  const { capacidad_fusible = "", tipo_fusible = "", } = celdas_mt;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(startAddCeldasMtoSwow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Celdas MT
      </IonLabel>
      {(activeEquipo.type === 'CELDAS_MT' && (numero_celda || numero_polos || tipo_aislamiento || tipo_fusible)) && (<IonList>
        <IonItem lines="none">
          <IonText>Capacidad fusible</IonText>
          <IonNote slot="end">{capacidad_fusible}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Derivación</IonText>
          <IonNote slot="end">{derivacion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>entrada 1</IonText>
          <IonNote slot="end">{entrada_1}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># Celda</IonText>
          <IonNote slot="end">{numero_celda}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># polos</IonText>
          <IonNote slot="end">{numero_polos}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># salida</IonText>
          <IonNote slot="end">{numero_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo</IonText>
          <IonNote slot="end">{tipo}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo aislamiento</IonText>
          <IonNote slot="end">{tipo_aislamiento}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo fusible</IonText>
          <IonNote slot="end">{tipo_fusible}</IonNote>
        </IonItem>
      </IonList>)}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{(activeEquipo.type === 'CELDAS_MT' && (derivacion || tipo_fusible || numero_celda || numero_polos )) ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editCeldasMto && <CeldasMtoModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
}

const CeldasMtoModal: FC = () => {
  const dispatch = useDispatch();
  const { editCeldasMto } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const [formValues, setFormValues] = useState(initForm);
  const { capacidad_fusible, derivacion, entrada_1, numero_celda, numero_polos, numero_salida, tipo, tipo_fusible, tipo_aislamiento } = formValues;

  useEffect(() => {
    if (activeEquipo.celdas_mt) {
      const e = {
        numero_celda: activeEquipo.numero_celda,
        derivacion: activeEquipo.derivacion,
        entrada_1: activeEquipo.entrada_1,
        numero_polos: activeEquipo.numero_polos,
        numero_salida: activeEquipo.numero_salida,
        tipo: activeEquipo.tipo,
        tipo_aislamiento: activeEquipo.tipo_aislamiento,
        tipo_fusible: activeEquipo.celdas_mt.tipo_fusible,
        capacidad_fusible: activeEquipo.celdas_mt.capacidad_fusible
      }
      setFormValues(e)
    } else {
      setFormValues(initForm);
    }
  },[activeEquipo])

  

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    celdas_mt: activeEquipo.celdas_mt,
    numero_celda: activeEquipo.numero_celda,
    derivacion: activeEquipo.derivacion,
    entrada_1: activeEquipo.entrada_1,
    numero_polos: activeEquipo.numero_polos,
    numero_salida: activeEquipo.numero_salida,
    tipo: activeEquipo.tipo,
    tipo_aislamiento: activeEquipo.tipo_aislamiento,
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.celdas_mt = {
      capacidad_fusible,
      tipo_fusible
    }
    equipo.derivacion = derivacion;
    equipo.entrada_1 = entrada_1;
    equipo.numero_celda = numero_celda;
    equipo.numero_polos = numero_polos;
    equipo.numero_salida = numero_salida;
    equipo.tipo = tipo;
    equipo.tipo_aislamiento = tipo_aislamiento;
    dispatch(startEquipoUpdated(equipo));
    dispatch(startAddCeldasMtoHide())
  };

  const handleClose = () => {
    dispatch(startAddCeldasMtoHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editCeldasMto} cssClass='modal-celdasMT'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Celdas MT</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
              <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Entrada 1
              </IonLabel>
              <IonInput
                type="text"
                name="entrada_1"
                value={entrada_1}
                onIonChange={handleInputChange}
                placeholder="Entrada 1"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Capacidad fusible
              </IonLabel>
              <IonInput
                type="text"
                name="capacidad_fusible"
                value={capacidad_fusible}
                onIonChange={handleInputChange}
                placeholder="Capacidad fusible"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo
              </IonLabel>
              <IonInput
                type="text"
                name="tipo"
                value={tipo}
                onIonChange={handleInputChange}
                placeholder="Tipo"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Derivación
              </IonLabel>
              <IonInput
                type="text"
                name="derivacion"
                value={derivacion}
                onIonChange={handleInputChange}
                placeholder="Derivación"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo de fusible
              </IonLabel>
              <IonInput
                type="text"
                name="tipo_fusible"
                value={tipo_fusible}
                onIonChange={handleInputChange}
                placeholder="Tipo de fusible"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo de aislamiento
              </IonLabel>
              <IonInput
                type="text"
                name="tipo_aislamiento"
                value={tipo_aislamiento}
                onIonChange={handleInputChange}
                placeholder="Tipo de aislamiento"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de celda
              </IonLabel>
              <IonInput
                type="text"
                name="numero_celda"
                value={numero_celda}
                onIonChange={handleInputChange}
                placeholder="Número de celda"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de polos
              </IonLabel>
              <IonInput
                type="number"
                name="numero_polos"
                value={numero_polos}
                onIonChange={handleInputChange}
                placeholder="Número de polos"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de salida
              </IonLabel>
              <IonInput
                type="text"
                name="numero_salida"
                value={numero_salida}
                onIonChange={handleInputChange}
                placeholder="Número de salida"
              ></IonInput>
            </IonItem>
            <IonItem lines="none" className="marca-item">
            <IonButton slot="end" fill="clear" type="submit">
              Guardar
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={handleClose}>
              Cancelar
            </IonButton>
          </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
