import {BaseComponent} from '../components/base/BaseComponent';
import { ImagesFromServer } from '../components/gallery/ImagesFromServer';


const Gallery: React.FC = (history) => {

  return (
    <BaseComponent title="Imagenes" component={ImagesFromServer}  />
  );
};

export default Gallery;