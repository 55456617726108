import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonRow, IonTextarea, IonTitle, IonToolbar } from '@ionic/react'
import { closeOutline, createOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, startSetObservacionesHide, startSetObservacionesShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  observaciones: '',
}

export const ObservacionesItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { observaciones } } = useSelector((state: RootState) => state.equipo);
  const { editObservaciones } = useSelector((state: RootState) => state.ui);

  const handleUpdate = () => {
    dispatch(startSetObservacionesShow())

  }
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
          <IonLabel className="item-label" position="stacked" color="primary">
             Observaciones
            </IonLabel>
          </IonCol>
        </IonRow>
       <IonRow>
       <IonCol size='9' size-sm>
        <IonLabel>{observaciones}</IonLabel>
        </IonCol>
        <IonCol size='3' size-sm className="ion-align-self-center">
        <IonButton color="white" fill='clear' onClick={handleUpdate}>
            <IonIcon slot="icon-only" color="success" size='large' icon={createOutline} />
          </IonButton>
        </IonCol>
       </IonRow>
      </IonGrid>
          {editObservaciones && <ObservacionesModal />}    
    </IonItem>
  )
}

const ObservacionesModal: FC = () => {
  const dispatch = useDispatch();

  const { editObservaciones } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { observaciones } = formValues;
  useEffect(() => {
    if (activeEquipo.observaciones) {
      const f = {
        observaciones: activeEquipo.observaciones,
      };
      setFormValues(f)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.observaciones])

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    observaciones: activeEquipo.observaciones,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.observaciones = observaciones
    dispatch(startEquipoUpdated(equipo));
    dispatch(startSetObservacionesHide())
  };

  const handleClose = () => {
    dispatch(startSetObservacionesHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editObservaciones} cssClass='modal-observaciones'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle color="dark">Observaciones</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem lines="inset">
            <IonTextarea
              name="observaciones"
              value={observaciones}
              onIonChange={handleInputChange}
              placeholder="Observaciones"
            ></IonTextarea>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>          
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}

