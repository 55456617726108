import { useEffect, useRef, useState } from "react";
import {
  trash,
  close,
  createOutline,
  arrowUndoOutline,
  addCircleOutline,
} from "ionicons/icons";
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonActionSheet,
  IonCard,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import {
  startAddImageDescripcionSwow,
  startSetActiveAlert,
} from "../../actions/ui";
import { AddImageDescription } from "./AddImageDescription";
import { IonCardContent, IonCardSubtitle } from "@ionic/react";
import {
  startSetActiveImage,
  startSetInactiveImage,
} from "../../actions/images";
import { AlertComponent } from "../ui/AlertComponent";

export const ImagesFromServer = ({ history }: any) => {
  const dispatch = useDispatch();
  const { images } = useSelector((state: RootState) => state.image);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editImageDescription } = useSelector((state: RootState) => state.ui);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const isMounted = useRef(true);
  const [imagesEquipo, setImagesEquipo] = useState([]);

  useEffect(() => {
    if (activeEquipo) {
      setImagesEquipo(images.filter((i: any) => i.equipo === activeEquipo.id));
    }
  }, [activeEquipo, images]);

  const handleShowActionSheet = (img: any) => {
    dispatch(startSetActiveImage(img));
    setShowActionSheet(true);
  };

  const goImages = () => {
    history.push("/images");
  };

  useEffect(() => {
    if(!isMounted.current) return;
    
    if (activeEquipo && imagesEquipo.length === 0) {
      history.push("/images");
    }

    return () => {
      isMounted.current = false;
    };
  }, [activeEquipo, imagesEquipo]);

  const deletePicture = () => {
    const alert = {
      active: true,
      header: "Eliminar imagen",
      message: `¿Desea eliminar la imagen?`,
      tipo: "image",
    };
    dispatch(startSetActiveAlert(alert));
  };

  const onDesactive = () => {
    dispatch(startSetInactiveImage());
  };

  const goBack = () => {
    history.push("/equipo");
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          {imagesEquipo.map((img: any, index: number) => (
            <IonCol
              size="6"
              key={index}
              onClick={() => handleShowActionSheet(img)}
            >
              <IonCard>
                <IonImg alt={`${img.name} ${img.description}`} src={img.uri} />
                <IonCardContent>
                  <IonCardSubtitle>{img.description}</IonCardSubtitle>
                </IonCardContent>
              </IonCard>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      <IonFab vertical="bottom" horizontal="center" slot="fixed">
        <IonFabButton onClick={goImages}>
          <IonIcon icon={addCircleOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonFab vertical="top" horizontal="end" slot="fixed">
        <IonFabButton onClick={goBack}>
          <IonIcon icon={arrowUndoOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="my-custom-class"
        buttons={[
          {
            text: "Agregar Descripción",
            icon: createOutline,
            handler: () => {
              dispatch(startAddImageDescripcionSwow());
            },
          },
          {
            text: "Eliminar",
            icon: trash,
            handler: () => {
              deletePicture();
            },
          },
          {
            text: "Cancelar",
            icon: close,
            role: "cancel",
            handler: () => {
              dispatch(startSetInactiveImage());
            },
          },
        ]}
      />
      {editImageDescription && <AddImageDescription />}
      <AlertComponent onDesactive={onDesactive} />
    </>
  );
};
