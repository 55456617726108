import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonModal, IonTextarea, IonTitle, IonToolbar } from '@ionic/react'
import { closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setModalDescriptionHide, setModalDescriptionShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  descripcion_equipo: '',
}

export const DescriptionItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { descripcion_equipo } } = useSelector((state: RootState) => state.equipo);
  const { editDescription } = useSelector((state: RootState) => state.ui);

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setModalDescriptionShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Descripción
      </IonLabel>
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel>{descripcion_equipo}</IonLabel>
          {editDescription && <DescriptionModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
}

const DescriptionModal: FC = () => {
  const dispatch = useDispatch();

  const { editDescription } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { descripcion_equipo } = formValues;

  useEffect(() => {
    if (activeEquipo) {
      const d = {descripcion_equipo: activeEquipo.descripcion_equipo};
      setFormValues(d)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo])

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    dispatch(startEquipoUpdated(equipo));
    dispatch(setModalDescriptionHide())
  };

  const handleClose = () => {
    dispatch(setModalDescriptionHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editDescription} cssClass='modal-unit-iput'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle color="dark">Descripción</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem lines="inset">
            <IonTextarea
              name="descripcion_equipo"
              value={descripcion_equipo}
              onIonChange={handleInputChange}
              placeholder="Descripción de equipo"
            ></IonTextarea>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>          
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
