import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { startAddInversorSwow, startAddInversorHide, setActiveLoading } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  voltaje_dc_entrada: "",
  corriente_dc_entrada: "",      
  fp_salida: "",
  voltaje_ac_salida: "",
  corriente_ac_salida: "",
}
export const InversorItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editInversor } = useSelector((state: RootState) => state.ui);
  const { inversor = {}, voltaje_ac_salida = "", corriente_ac_salida = "" } = activeEquipo;
  const { voltaje_dc_entrada = "", corriente_dc_entrada = "", fp_salida = "", } = inversor;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(startAddInversorSwow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Inversor
      </IonLabel>
      {Object.keys(inversor).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>Vol. AC entrada</IonText>
          <IonNote slot="end">{voltaje_dc_entrada}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte AC entrada</IonText>
          <IonNote slot="end">{corriente_dc_entrada}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Vol. AC salida</IonText>
          <IonNote slot="end">{voltaje_ac_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte AC salida</IonText>
          <IonNote slot="end">{corriente_ac_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Fp salida</IonText>
          <IonNote slot="end">{fp_salida}</IonNote>
        </IonItem>
        </IonList>}
      <IonItemSliding>
      <IonItem lines="none">
                <IonLabel color="danger">{Object.keys(inversor).length !== 0  ? 'Editar' : 'Agregar'}</IonLabel>
                <IonIcon icon={chevronForwardOutline}></IonIcon>
                {editInversor && <InversorModal />}
            </IonItem>        
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
}

const InversorModal: FC = () => {
  const dispatch = useDispatch();

  const { editInversor } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { voltaje_dc_entrada, corriente_dc_entrada, fp_salida, voltaje_ac_salida, corriente_ac_salida } =
    formValues;
  useEffect(() => {
    if (activeEquipo.inversor) {
      const i = {
        voltaje_dc_entrada: activeEquipo.inversor.voltaje_dc_entrada,
        corriente_dc_entrada: activeEquipo.inversor.corriente_dc_entrada,
        fp_salida: activeEquipo.inversor.fp_salida,
        voltaje_ac_salida: activeEquipo.voltaje_ac_salida,
        corriente_ac_salida: activeEquipo.corriente_ac_salida,
      };
      setFormValues(i);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo]);
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    inversor: activeEquipo.inversor,
    voltaje_ac_salida: activeEquipo.voltaje_ac_salida,
    corriente_ac_salida: activeEquipo.corriente_ac_salida,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.inversor = {
      voltaje_dc_entrada,
      corriente_dc_entrada,
      fp_salida,
    };
    equipo.voltaje_ac_salida = voltaje_ac_salida;
    equipo.corriente_ac_salida = corriente_ac_salida
    dispatch(startEquipoUpdated(equipo));
    dispatch(startAddInversorHide());
  };

  const handleClose = () => {
    dispatch(startAddInversorHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editInversor} cssClass="modal-central">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Inversor</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
              <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
              Voltaje DC entrada
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_dc_entrada"
                value={voltaje_dc_entrada}
                onIonChange={handleInputChange}
                placeholder="Voltaje DC entrada"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
              Corriente DC entrada
              </IonLabel>
              <IonInput
                type="number"
                name="corriente_dc_entrada"
                value={corriente_dc_entrada}
                onIonChange={handleInputChange}
                placeholder="Corriente DC entrada"
                inputMode="numeric"
              ></IonInput>
            </IonItem>            
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje AC salida{" "}
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_ac_salida"
                value={voltaje_ac_salida}
                onIonChange={handleInputChange}
                placeholder="Voltaje AC salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente AC salida
              </IonLabel>
              <IonInput
                type="number"
                name="corriente_ac_salida"
                value={corriente_ac_salida}
                onIonChange={handleInputChange}
                placeholder="Corriente AC salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
            Fp salida
              </IonLabel>
              <IonInput
                type="number"
                name="fp_salida"
                value={fp_salida}
                onIonChange={handleInputChange}
                placeholder="Fp salida"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
