import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { canalizacionReducer } from "./canalizacionReducer";
import { centralReducer } from "./centralReducer";
import { imageReducer } from "./imageReducer";
import { marcaReducer } from "./marcaReducer";
import { labelReducer } from "./labelReducer";
import { typeReducer } from "./typeReducer";
import { uiReducer } from "./uiReducer";
import { equipoReducer } from './equipoReducer';

export const rootReducer = combineReducers({    
    auth: authReducer,
    ui: uiReducer,
    central: centralReducer,
    equipo: equipoReducer,
    marca: marcaReducer,
    canalizacion: canalizacionReducer,
    image: imageReducer,
    type: typeReducer,
    label: labelReducer
});

export type RootState = ReturnType<typeof rootReducer>