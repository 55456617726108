import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setTransformadoresEditHide, setTransformadoresEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  numero_polos: "",
  numero_celda: "",
  tipo_aislamiento: "",
  relacion_transformacion: "",
  tipo_conexion: "",
  voltaje_entrada: "",
  voltaje_salida: "",
  k_impedancia: ""
}

export const TransformadoresItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editTransformadores } = useSelector((state: RootState) => state.ui);
  const { transformador = {}, numero_polos = "", numero_celda = "", tipo_aislamiento= ""} = activeEquipo;
  const { relacion_transformacion = "", tipo_conexion = "", voltaje_entrada = "", voltaje_salida = "", k_impedancia = "" } = transformador;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setTransformadoresEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Transformador
      </IonLabel>
      {Object.keys(transformador).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText># Polos</IonText>
          <IonNote slot="end">{numero_polos}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># celda</IonText>
          <IonNote slot="end">{numero_celda}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Rel. transformación</IonText>
          <IonNote slot="end">{relacion_transformacion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo conección</IonText>
          <IonNote slot="end">{tipo_conexion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Voltaje entrada</IonText>
          <IonNote slot="end">{voltaje_entrada}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Voltaje salida</IonText>
          <IonNote slot="end">{voltaje_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo aislamiento</IonText>
          <IonNote slot="end">{tipo_aislamiento}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>K -impedancia</IonText>
          <IonNote slot="end">{k_impedancia}</IonNote>
        </IonItem>
      </IonList>}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(transformador).length !== 0 ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editTransformadores && <TransformadoresModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
}

const TransformadoresModal: FC = () => {

  const dispatch = useDispatch();
  const { editTransformadores } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const [formValues, setFormValues] = useState(initForm);
  const { numero_polos, numero_celda, tipo_aislamiento, relacion_transformacion, tipo_conexion, voltaje_entrada, voltaje_salida, k_impedancia } = formValues;

  useEffect(() => {
    if (activeEquipo.transformador) {
      const e = {
        numero_polos: activeEquipo.numero_polos,
        numero_celda: activeEquipo.numero_celda,
        tipo_aislamiento: activeEquipo.tipo_aislamiento,
        relacion_transformacion: "",
        tipo_conexion: activeEquipo.transformador.tipo_conexion,
        voltaje_entrada: activeEquipo.transformador.voltaje_entrada,
        voltaje_salida: activeEquipo.transformador.voltaje_salida,
        k_impedancia: activeEquipo.transformador.k_impedancia,
        numero_salida: activeEquipo.numero_salida
      }
      setFormValues(e)
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo])



  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    transformador: activeEquipo.transformador,
    numero_celda: activeEquipo.numero_celda,
    numero_polos: activeEquipo.numero_polos,
    tipo_aislamiento: activeEquipo.tipo_aislamiento,
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.transformador = {
      relacion_transformacion,
      tipo_conexion,
      voltaje_entrada,
      voltaje_salida,
      k_impedancia
    }
    equipo.numero_polos = numero_polos;
    equipo.numero_celda = numero_celda;
    equipo.tipo_aislamiento = tipo_aislamiento;
    dispatch(startEquipoUpdated(equipo));
    dispatch(setTransformadoresEditHide())
  };

  const handleClose = () => {
    dispatch(setTransformadoresEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editTransformadores} cssClass='modal-celdasMT'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Celdas MT</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Relación de Transformación
              </IonLabel>
              <IonInput
                type="text"
                name="relacion_transformacion"
                value={relacion_transformacion}
                onIonChange={handleInputChange}
                placeholder="Relación de Transformación"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo de conexión
              </IonLabel>
              <IonInput
                type="text"
                name="tipo_conexion"
                value={tipo_conexion}
                onIonChange={handleInputChange}
                placeholder="Tipo de conexión"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje entrada
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_entrada"
                value={voltaje_entrada}
                onIonChange={handleInputChange}
                placeholder="Voltaje entrada"
                inputmode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje salida
              </IonLabel>
              <IonInput
                type="number"
                name="voltaje_salida"
                value={voltaje_salida}
                onIonChange={handleInputChange}
                placeholder="Voltaje salida"
                inputmode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                K impedancia
              </IonLabel>
              <IonInput
                type="text"
                name="k_impedancia"
                value={k_impedancia}
                onIonChange={handleInputChange}
                placeholder=" K impedancia"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo de aislamiento
              </IonLabel>
              <IonInput
                type="text"
                name="tipo_aislamiento"
                value={tipo_aislamiento}
                onIonChange={handleInputChange}
                placeholder="Tipo de aislamiento"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de celda
              </IonLabel>
              <IonInput
                type="text"
                name="numero_celda"
                value={numero_celda}
                onIonChange={handleInputChange}
                placeholder="Número de celda"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de polos
              </IonLabel>
              <IonInput
                type="number"
                name="numero_polos"
                value={numero_polos}
                onIonChange={handleInputChange}
                placeholder="Número de polos"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
