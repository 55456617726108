import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonModal,
  IonText,
  IonInput,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonList,
  IonNote,
} from "@ionic/react";
import {
  chevronForwardOutline,
  closeOutline,
  createOutline,
  trashOutline,
} from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startEquipoUpdated } from "../../../actions/equipos";
import {
  setActiveLoading,
  setMedicionesCCEditHide,
  setMedicionesCCEditShow,
} from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";

const initForm = {
  corriente_negativo: "",
  corriente_positivo: "",
  voltaje_cc: "",
};
export const MedicionesCCItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editMedicionesCC } = useSelector((state: RootState) => state.ui);
  const { mediciones_cc = {} } = activeEquipo;
  const {
    corriente_negativo = "",
    corriente_positivo = "",
    voltaje_cc = "",
  } = mediciones_cc;

  const handleDelete = () => {
    console.log("click");
  };
  const handleUpdate = () => {
    dispatch(setMedicionesCCEditShow());
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Mediciones (CC)
      </IonLabel>
      {Object.keys(mediciones_cc).length !== 0 && (
        <IonList>
          <IonItem lines="none">
            <IonText>Corriente negativo</IonText>
            <IonNote slot="end">{corriente_negativo}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Corriente positivo</IonText>
            <IonNote slot="end">{corriente_positivo}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Voltaje CC</IonText>
            <IonNote slot="end">{voltaje_cc}</IonNote>
          </IonItem>
        </IonList>
      )}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">
            {Object.keys(mediciones_cc).length !== 0 ? "Editar" : "Agregar"}
          </IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editMedicionesCC && <MedicionesCCModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption
            color="light"
            onClick={() => {
              handleUpdate();
            }}
          >
            <IonIcon
              className="icon-list"
              color="success"
              ariaLabel="Editar"
              title="Editar"
              icon={createOutline}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="light"
            onClick={() => {
              handleDelete();
            }}
          >
            <IonIcon
              className="icon-list"
              color="danger"
              icon={trashOutline}
            ></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  );
};

const MedicionesCCModal: FC = () => {
  const dispatch = useDispatch();

  const { editMedicionesCC } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { corriente_negativo, corriente_positivo, voltaje_cc } = formValues;
  useEffect(() => {
    if (activeEquipo.mediciones_cc) {
      const aac = {
        corriente_negativo: activeEquipo.mediciones_cc.corriente_negativo,
        corriente_positivo: activeEquipo.mediciones_cc.corriente_positivo,
        voltaje_cc: activeEquipo.mediciones_cc.voltaje_cc,
      };
      setFormValues(aac);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo.mediciones_cc]);
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    mediciones_cc: activeEquipo.mediciones_cc,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.mediciones_cc = {
      corriente_negativo,
      corriente_positivo,
      voltaje_cc,
    };
    dispatch(startEquipoUpdated(equipo));
    dispatch(setMedicionesCCEditHide());
  };

  const handleClose = () => {
    dispatch(setMedicionesCCEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editMedicionesCC} cssClass="modal-banco-baterias">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Mediciones (CC)</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente negativo
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_negativo"
                value={corriente_negativo}
                onIonChange={handleInputChange}
                placeholder="Corriente negativo"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente positivo
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_positivo"
                value={corriente_positivo}
                onIonChange={handleInputChange}
                placeholder="Corriente positivo"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje CC
              </IonLabel>
              <IonInput
                type="text"
                name="voltaje_cc"
                value={voltaje_cc}
                onIonChange={handleInputChange}
                placeholder="Voltaje CC"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
