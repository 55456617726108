import {BaseComponent} from '../components/base/BaseComponent';
import { GalleryScreen } from '../components/gallery/GalleryScreen';


const Images: React.FC = (history) => {

  return (
    <BaseComponent title="Imagenes" component={GalleryScreen}  />
  );
};

export default Images;
