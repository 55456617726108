import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  chevronForwardOutline,
  closeOutline,
  createOutline,
  trashOutline,
} from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startEquipoUpdated } from "../../../actions/equipos";
import {
  setActiveLoading,
  setGeneradoresEditHide,
  setGeneradoresEditShow,
} from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";

const initForm = {
  ensamblador: "",
  capacidad_tanque_combustible: "",
  tipo_de_instalacion: "",
};

export const GeneradoresItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editGeneradores } = useSelector((state: RootState) => state.ui);
  const { generador = {} } = activeEquipo;
  const {
    ensamblador = "",
    capacidad_tanque_combustible = "",
    tipo_de_instalacion = "",
  } = generador;
  const handleDelete = () => {
    console.log("click");
  };
  const handleUpdate = () => {
    dispatch(setGeneradoresEditShow());
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Generador
      </IonLabel>
      <IonItemSliding>
        {Object.keys(generador).length !== 0 && (
          <IonList>
            <IonItem lines="none">
              <IonText>Ensamblador</IonText>
              <IonNote slot="end">{ensamblador}</IonNote>
            </IonItem>
            <IonItem lines="none">
              <IonText>Cap. tanque.</IonText>
              <IonNote slot="end">{capacidad_tanque_combustible}</IonNote>
            </IonItem>
            <IonItem lines="none">
              <IonText>Tipo Instal..</IonText>
              <IonNote slot="end">{tipo_de_instalacion}</IonNote>
            </IonItem>
          </IonList>
        )}
        <IonItem lines="none">
          <IonLabel color="danger">
            {Object.keys(generador).length !== 0 ? "Editar" : "Agregar"}
          </IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editGeneradores && <GeneradoresModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption
            color="light"
            onClick={() => {
              handleUpdate();
            }}
          >
            <IonIcon
              className="icon-list"
              color="success"
              ariaLabel="Editar"
              title="Editar"
              icon={createOutline}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="light"
            onClick={() => {
              handleDelete();
            }}
          >
            <IonIcon
              className="icon-list"
              color="danger"
              icon={trashOutline}
            ></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  );
};

const GeneradoresModal: FC = () => {
  const dispatch = useDispatch();

  const { editGeneradores } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { ensamblador, capacidad_tanque_combustible, tipo_de_instalacion } =
    formValues;
  useEffect(() => {
    if (activeEquipo.generador) {
      const aac = {
        ensamblador: activeEquipo.generador.ensamblador,
        capacidad_tanque_combustible:
          activeEquipo.generador.capacidad_tanque_combustible,
        tipo_de_instalacion: activeEquipo.generador.tipo_de_instalacion,
      };
      setFormValues(aac);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo.generador]);
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    generador: activeEquipo.generador,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.generador = {
      ensamblador,
      capacidad_tanque_combustible,
      tipo_de_instalacion,
    };
    dispatch(startEquipoUpdated(equipo));
    dispatch(setGeneradoresEditHide());
  };

  const handleClose = () => {
    dispatch(setGeneradoresEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editGeneradores} cssClass="modal-banco-baterias">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Generador</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
              <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Ensamblador
              </IonLabel>
              <IonInput
                type="text"
                name="ensamblador"
                value={ensamblador}
                onIonChange={handleInputChange}
                placeholder="Ensamblador"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Capacidad tanque combustible{" "}
              </IonLabel>
              <IonInput
                type="text"
                name="capacidad_tanque_combustible"
                value={capacidad_tanque_combustible}
                onIonChange={handleInputChange}
                placeholder="Capacidad tanque combustible"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo de instalación
              </IonLabel>
              <IonInput
                type="text"
                name="tipo_de_instalacion"
                value={tipo_de_instalacion}
                onIonChange={handleInputChange}
                placeholder="Tipo de instalación"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
