const baseUrl = process.env.REACT_APP_API_URL;
const baseUrlImages = process.env.REACT_APP_API_URL_IMAGES;

const fetchSinToken = ( endpoint: string, data: any, method = 'GET' ) => {
    const url = `${baseUrl}/${endpoint}`;

    if(method === 'GET'){
        return fetch( url );
    }else{
        return fetch(url, {
            method: method,
            headers:{
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
};

const fetchConToken = ( endpoint: any, data: any, method = 'GET' ) => {
    const url = `${baseUrl}/${endpoint}`;
    const token = localStorage.getItem('token') || '';
    if(method === 'GET'){
        return fetch( url,{
            method,
            headers: {
                'x-token': token,
            }
        } );
    }else{
        return fetch(url, {
            method: method,
            headers:{                
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        });
    }
};

const fetchImagesSinToken = ( endpoint: string, data: any, method = 'GET' ) => {
    const url = `${baseUrlImages}/${endpoint}`;

    if(method === 'GET'){
        return fetch( url );
    }else{
        return fetch(url, {
            method: method,
            headers:{
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
};

const fetchImagesToken = ( endpoint: any, data: any, method = 'GET' ) => {
    const url = `${baseUrlImages}/${endpoint}`;
    const token = localStorage.getItem('token') || '';

    if(method === 'GET'){
        return fetch( url,{
            method,
            headers: {
                'x-token': token,
            }
        } );
    }else{
        return fetch(url, {
            method: method,
            headers:{                
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        });
    }
};



export {
    fetchSinToken,
    fetchConToken,
    fetchImagesSinToken,
    fetchImagesToken
}