import {FC, useEffect, useRef} from "react";
import { IonReactRouter } from "@ionic/react-router";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";

import { startCheking } from "../../actions/auth";
import { LoginScreen } from "../../pages/LoginScreen";
import { RootState } from "../../reducers/rootReducer";
import { DashboardRoutes } from "./DashboardRoutes";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const AppRouter: FC = () => { 
  const dispatch = useDispatch();   
  const {uid}  = useSelector((state: RootState) => state.auth);
  const componentMounted = useRef(true);  

  useEffect(() => {
    if(componentMounted.current){
    dispatch(startCheking());
    }
    return () => {
      componentMounted.current =false
    }
  }, [dispatch]);

  return (
    <>
    <IonReactRouter>
      <div>
        <Switch>
          <PublicRoute
            exact
            path="/login"
            isAuthenticated={uid}
            component={LoginScreen}
          />
          <PrivateRoute
           exact
           path="/"
           isAuthenticated={uid}
           component={DashboardRoutes} />
        </Switch>
      </div>
    </IonReactRouter>
    </>
  );
};
