import { fetchConToken } from "../helpers/fetch";
import { Central } from "../interfaces/Central";
import { types } from "../types/types";
import { setInactiveLoading, setModalCentralHide, setToast } from "./ui";

export const startLoadCentrales = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken("centrales", {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(loadCentrales(body.centrales))
        } else {
            console.log(body.msg);
        }
    };
};

const loadCentrales = (centrales: Central) => ({
    type: types.centralStartLoad,
    payload: centrales
})

export const centralSetActive = (central: any) => ({
    type: types.centralSetActive,
    payload: central
});

export const clearActiveCentral = () => ({
    type: types.centralClearActive
});

export const centralStartAddNew = (central: any) => {
    return async (dispatch: any, getState: any) => {
        const { uid, name } = getState().auth;
        try {
            const resp = await fetchConToken("centrales", central, "POST");
            const body = await resp.json();
            if (body.ok) {                
                central.id = body.central.id;
                central.user = {
                    _id: uid,
                    name: name,
                };
                dispatch(centralAddNew(central));
                const toast = {
                    title: '',
                    msg: 'La central ha sido agregada exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalCentralHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            dispatch(setToast(toast))
        }
    };
};

const centralAddNew = (central: any) => ({
    type: types.centralStartAdd,
    payload: central
});

export const startCentralUpdated = (central: any) => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().central.activeCentral;
        try {
            const resp = await fetchConToken(`centrales/${id}`, central, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(centralUpdated(body.central));
                const toast = {
                    title: '',
                    msg: 'La central ha sido actualizada exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalCentralHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(error);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    };
};

const centralUpdated = (central: any) => ({
    type: types.centralStartUpdated,
    payload: central,
});

export const starCentralDeleted = () => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().central.activeCentral;
        try {
            const resp = await fetchConToken(`centrales/${id}`, {}, "DELETE");
            const body = await resp.json();
            if (body.ok) {
                dispatch(centralDeleted());
                const toast = {
                    title: '',
                    msg: 'La central ha sido eliminada exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))
            } else {
                console.log(body)
                let msg = "";
                const errors = JSON.parse(JSON.stringify(body.errors));
                for (const i in errors) {
                    msg = errors[i].msg;
                }
                console.log(`Error ${msg}`);
                const toast = {
                    title: 'Error',
                    msg,
                    color: "danger"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))

            }
        } catch (error) {
            console.error(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    }
};

const centralDeleted = () => ({
    type: types.centralStartDeleted,
});
