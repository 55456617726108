import { types } from "../types/types";

const initialSate ={
    labels: [],
    activeLabel: null
}

export const labelReducer = (state = initialSate, action: any) => {
    switch (action.type) {
        case types.labelStartLoad:
            return {
                ...state,
                labels: action.payload
            };
        case types.labelStartAdd:
            return {
                ...state,
                labels: [
                    ...state.labels,
                    action.payload
                ]

            }

        case types.labelSetActive:
            return {
                ...state,
                activeLabel: action.payload
            };
        
        case types.labelClearActive:
            return {
                ...state,
                activeLabel:null
            };

        case types.labelStartUpdated:
            return {
                ...state,
                labels: state.labels.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            };
        
        case types.labelStartDeleted:
            return {
                ...state,
                labels: state.labels.filter(c => c.id !== state.activeLabel.id),
                activeLabel: null
            };
        
        case types.labelCleared:
            return {
                labels: [],
                activeLabel: null
            };

        default:
            return state;
    }
};