import React from "react";
import {
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonContent,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import "./Login.css";
import { useForm } from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { startUpdatePassword } from "../../actions/auth";

import {
  setToast,
  startUpdatePasswordHide,
  setActiveLoading,
} from "../../actions/ui";

export const ChangeLogin: React.FC = () => {

  const dispatch = useDispatch();
  const [formValues, handleInputChange, resetForm] = useForm({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const { oldPassword, password, confirmPassword } = formValues;

  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    if (password.length < 8) {
      const toast = {
        msg: "La contraseña debe tener almenos 8 caracteres",
        color: "warning",
      };
      dispatch(setToast(toast));
      return;
    }
    if (password !== confirmPassword) {
      const toast = {
        msg: "Las contraseñas no coiciden",
        color: "warning",
      };
      dispatch(setToast(toast));
      return;
    }
    dispatch(setActiveLoading());
    dispatch(startUpdatePassword(oldPassword, password));
  };

  const setShowModal = () => {
    resetForm();
    dispatch(startUpdatePasswordHide());
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>Cambiar contraseña</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={setShowModal}>
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="marca-container">
        <form onSubmit={handleLoginSubmit}>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              Contraseña anterior
            </IonLabel>
            <IonInput
              type="password"
              name="oldPassword"
              value={oldPassword}
              onIonChange={handleInputChange}
              placeholder="Contraseña anterior"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              {" "}
              Contraseña
            </IonLabel>
            <IonInput
              type="password"
              name="password"
              value={password}
              onIonChange={handleInputChange}
              autocomplete="off"
              placeholder="Contraseña"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              {" "}
              Confirmar contraseña
            </IonLabel>
            <IonInput
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onIonChange={handleInputChange}
              autocomplete="off"
              placeholder="Confirmar contraseña"
            ></IonInput>
          </IonItem>
          <IonItem lines="none" className="marca-item">
            <IonButton slot="end" fill="clear" type="submit">
              Guardar
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={setShowModal}>
              Cerrar
            </IonButton>
          </IonItem>
        </form>
      </IonContent>
    </>
  );
};
