import {
  IonAlert,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonModal,
  IonText,
  IonInput,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonList,
  IonNote,
} from "@ionic/react";
import {
  chevronForwardOutline,
  closeOutline,
  createOutline,
  trashOutline,
} from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startEquipoUpdated } from "../../../actions/equipos";
import {
  setActiveLoading,
  setTransformadoresMEditHide,
  setTransformadoresMEditShow,
} from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";

const initForm = {
  numero_ct: "",
  relacion_ct: "",
  numero_pt: "",
  relacion_pt: "",
  clase_pt: "",
  clase_ct: "",
};

export const TransformadoresMedicionItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editTransformadoresM } = useSelector((state: RootState) => state.ui);
  const { celda_medida = {} } = activeEquipo;
  const {
    numero_ct = "",
    relacion_ct = "",
    numero_pt = "",
    relacion_pt = "",
    clase_pt = "",
    clase_ct = "",
  } = celda_medida;

  const handleDelete = () => {
    console.log("click");
  };
  const handleUpdate = () => {
    dispatch(setTransformadoresMEditShow());
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Celda Medida
      </IonLabel>
      {Object.keys(celda_medida).length !== 0 && (
        <IonList>
          <IonItem lines="none">
            <IonText>Número CT</IonText>
            <IonNote slot="end">{numero_ct}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Relación CT</IonText>
            <IonNote slot="end">{relacion_ct}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Número PT</IonText>
            <IonNote slot="end">{numero_pt}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Relación PT</IonText>
            <IonNote slot="end">{relacion_pt}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Clase PT</IonText>
            <IonNote slot="end">{clase_pt}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonText>Clase CT</IonText>
            <IonNote slot="end">{clase_ct}</IonNote>
          </IonItem>
        </IonList>
      )}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">
            {Object.keys(celda_medida).length !== 0 ? "Editar" : "Agregar"}
          </IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editTransformadoresM && <CeldaMedidaModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption
            color="light"
            onClick={() => {
              handleUpdate();
            }}
          >
            <IonIcon
              className="icon-list"
              color="success"
              ariaLabel="Editar"
              title="Editar"
              icon={createOutline}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="light"
            onClick={() => {
              handleDelete();
            }}
          >
            <IonIcon
              className="icon-list"
              color="danger"
              icon={trashOutline}
            ></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  );
};

const CeldaMedidaModal: FC = () => {
  const dispatch = useDispatch();

  const { editTransformadoresM } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { numero_ct, relacion_ct, numero_pt, relacion_pt, clase_pt, clase_ct } =
    formValues;
  useEffect(() => {
    if (activeEquipo.celda_medida) {
      const u = {
        numero_ct: activeEquipo.celda_medida.numero_ct,
        relacion_ct: activeEquipo.celda_medida.relacion_ct,
        numero_pt: activeEquipo.celda_medida.numero_pt,
        relacion_pt: activeEquipo.celda_medida.relacion_pt,
        clase_pt: activeEquipo.celda_medida.clase_pt,
        clase_ct: activeEquipo.celda_medida.clase_ct,
      };
      setFormValues(u);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo]);
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    celda_medida: activeEquipo.celda_medida,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.celda_medida = {
      numero_ct,
      relacion_ct,
      numero_pt,
      relacion_pt,
      clase_pt,
      clase_ct,
    };
    dispatch(startEquipoUpdated(equipo));
    dispatch(setTransformadoresMEditHide());
  };

  const handleClose = () => {
    dispatch(setTransformadoresMEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editTransformadoresM} cssClass="modal-celdasMT">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Celda de Medida</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número CT
              </IonLabel>
              <IonInput
                type="text"
                name="numero_ct"
                value={numero_ct}
                onIonChange={handleInputChange}
                placeholder="Número CT"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Relación CT
              </IonLabel>
              <IonInput
                type="text"
                name="relacion_ct"
                value={relacion_ct}
                onIonChange={handleInputChange}
                placeholder="Relación CT"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número PT
              </IonLabel>
              <IonInput
                type="text"
                name="numero_pt"
                value={numero_pt}
                onIonChange={handleInputChange}
                placeholder="Número PT"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Relación PT
              </IonLabel>
              <IonInput
                type="text"
                name="relacion_pt"
                value={relacion_pt}
                onIonChange={handleInputChange}
                placeholder="Relación PT"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Clase PT
              </IonLabel>
              <IonInput
                type="text"
                name="clase_pt"
                value={clase_pt}
                onIonChange={handleInputChange}
                placeholder="Clase PT"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Clase CT
              </IonLabel>
              <IonInput
                type="text"
                name="clase_ct"
                value={clase_ct}
                onIonChange={handleInputChange}
                placeholder="Clase CT"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
