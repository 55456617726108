import React from 'react';
import ReactDOM from 'react-dom';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import BelingApp from './BelingApp';

ReactDOM.render( 
    <BelingApp />,
  document.getElementById('root')
);
defineCustomElements(window);

