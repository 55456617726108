import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react'
import { closeOutline, createOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { startLoadMarcas } from '../../../actions/marcas'
import { setActiveLoading, setMarcaEditHide, setMarcaEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'


const initForm = {
  marca: ""
}

export const MarcaItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { marca = "" } } = useSelector((state: RootState) => state.equipo);
  const { editMarca } = useSelector((state: RootState) => state.ui);

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setMarcaEditShow())

  }
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
          <IonLabel className="item-label" position="stacked" color="primary">
             Marca
            </IonLabel>
          </IonCol>
        </IonRow>
       <IonRow>
       <IonCol size='9' size-sm>
        <IonLabel>{marca.name}</IonLabel>
        </IonCol>
        <IonCol size='3' size-sm className="ion-align-self-center">
        <IonButton color="white" fill='clear' onClick={handleUpdate}>
            <IonIcon slot="icon-only" color="success" size='large' icon={createOutline} />
          </IonButton>
        </IonCol>
       </IonRow>
      </IonGrid>
      {editMarca && <MarcaModal />}
    </IonItem>
  )
}

const MarcaModal: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadMarcas())
  }, [dispatch])

  const { marcas } = useSelector((state: RootState) => state.marca);
  const { editMarca } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { marca } = formValues;
  useEffect(() => {
    if (activeEquipo.marca) {
      const m = { marca: activeEquipo.marca._id };
      setFormValues(m)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.marca])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    marca: activeEquipo.marca
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.marca = marca
    dispatch(startEquipoUpdated(equipo));
    dispatch(setMarcaEditHide())
  };

  const handleClose = () => {
    dispatch(setMarcaEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editMarca} cssClass='modal-marca'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Marca</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleUpdate}>
            <IonItem lines="none">
              <IonLabel>Marca</IonLabel>
              <IonSelect name="marca" value={marca} onIonChange={handleInputChange}>
                {marcas.map((marca: any) => (
                  <IonSelectOption key={marca.id} value={marca.id}>
                    {marca.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <IonItem lines="none" className="marca-item">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
