import React from "react";
import {
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonIcon,
  IonCardSubtitle,
  IonImg,
} from "@ionic/react";
import {
  businessOutline,
  cogOutline,
  diceOutline,
  lockClosedOutline,
  ribbonOutline,
} from "ionicons/icons";
import { useDispatch } from "react-redux";
import {
  setModalCentralShow,
  setModalEquipoShow,
  setModalMarcaShow,
  startUpdatePasswordSwow,
} from "../../actions/ui";
import { EquipoScreen } from "../equipo/EquipoScreen";
export const HomComponent: React.FC = ({history}: any) => {
  const dispatch = useDispatch();

  const setShowModalCentral = () => {
    dispatch(setModalCentralShow());
  };

  const setShowModalEquipo = () => {    
    dispatch(setModalEquipoShow());
  };

  const setShowModalMarca = () => {
    dispatch(setModalMarcaShow());
  };

  const setShowModalChangPassword = () => {
    dispatch(startUpdatePasswordSwow());
  };

  return (
    <>
      <IonCardContent className="home-container">
        <IonRow>
          <IonCol>
            <IonImg src="assets/images/img5.svg" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6">
            <IonCard type="button" onClick={setShowModalCentral}>
              <IonCardContent className="card-home">
                <IonIcon
                  className="icon-login"
                  color="primary"
                  icon={businessOutline}
                />
                <IonCardSubtitle>Agregar Central</IonCardSubtitle>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="6">
            <IonCard type="button" onClick={setShowModalEquipo}>
              <IonCardContent>
                <IonIcon
                  className="icon-login"
                  color="primary"
                  icon={cogOutline}
                />
                <IonCardSubtitle>Agregar Equipo</IonCardSubtitle>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6">
            <IonCard type="button" onClick={setShowModalMarca}>
              <IonCardContent>
                <IonIcon
                  className="icon-login"
                  color="primary"
                  icon={ribbonOutline}
                />
                <IonCardSubtitle>Agregar Marca</IonCardSubtitle>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="6">
            <IonCard type="button" onClick={setShowModalChangPassword}>
              <IonCardContent>
                <IonIcon
                  className="icon-login"
                  color="primary"
                  icon={lockClosedOutline}
                />
                <IonCardSubtitle>Cambiar contraseña</IonCardSubtitle>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonCardContent>
      <EquipoScreen history={history} />
    </>
  );
};
