import { FC, useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import {
  setActiveLoading,
  setModalLabelHide,
  setToast,
} from "../../actions/ui";
import { Label } from "../../interfaces/Label";
import {
  clearActiveLabel,
  labelStartAddNew,
  startLabelUpdated,
} from "../../actions/labels";
import { closeOutline } from "ionicons/icons";

const initLabel = {
  name: "",
};

export const LabelScreen: FC = () => {
  const dispatch = useDispatch();
  const { activeLabel } = useSelector((state: RootState) => state.label);
  const [formValues, setFormValues] = useState(initLabel);

  const { name } = formValues;

  useEffect(() => {
    if (activeLabel) {
      const label = {
        name: activeLabel.name,
      };
      setFormValues(label);
    } else {
      setFormValues(initLabel);
    }
  }, [activeLabel]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const label: Label = {
    id: "",
    name,
    user: {
      _id: "",
      name: "",
    },
  };

  const handleSumit = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    if (name.length <= 1) {
      const toast = {
        title: "Error",
        msg: "El nombre es un campo obligatorio",
        color: "danger",
      };

      dispatch(setToast(toast));
      return;
    }

    if (activeLabel) {
      label.id = activeLabel.id;
      label.user = activeLabel.user;
      dispatch(startLabelUpdated(label));
      dispatch(clearActiveLabel());
    } else {
      dispatch(labelStartAddNew(label));
    }
  };

  const setShowModal = () => {
    setFormValues(initLabel);
    dispatch(clearActiveLabel());
    dispatch(setModalLabelHide());
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>
            {" "}
            {activeLabel ? "Actualizar" : "Registrar nombre"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={setShowModal}>
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSumit}>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              Etique de Nombre
            </IonLabel>
            <IonInput
              type="text"
              name="name"
              value={name}
              onIonChange={handleInputChange}
              autocomplete="off"
              placeholder="Nombre"
            ></IonInput>
          </IonItem>
          <IonItem lines="none" className="marca-item">
            <IonButton slot="end" fill="clear" type="submit">
              Guardar
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={setShowModal}>
              Cerrar
            </IonButton>
          </IonItem>
        </form>
      </IonContent>
    </>
  );
};
