import React from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from "@ionic/react";

import "./Login.css";
import { useForm } from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { startLogin } from "../../actions/auth";

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [formValues, handleInputChange] = useForm({
    email: "",
    password: ""
  });

  const { email, password } = formValues;

  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    dispatch(startLogin(email, password))
  };

  return (
    <div className="login">
      <IonRow>
        <IonCol className="col-item">
          {/* <IonIcon className="icon-login" color="primary" icon={personCircle} /> */}
          
              <img src="assets/icon/icon.png" alt="logo beling" />
          
        </IonCol>
      </IonRow>
      <form onSubmit={handleLoginSubmit}>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Email
              </IonLabel>
              <IonInput
                type="email"
                name="email"
                value={email}
                onIonChange={handleInputChange}
                placeholder="Email"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                {" "}
                Contraseña
              </IonLabel>
              <IonInput
                type="password"
                name="password"
                value={password}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Contraseña"
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" type="submit" fill="outline">
              Ingresar
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
    </div>
  );
};
