import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setMedicionesACEditHide, setMedicionesACEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm ={
  corriente_fase_a: "",
corriente_fase_b: "",
corriente_fase_c: "",
corriente_neutro: "",
voltaje_l_l: "",
voltaje_l_n: "",
voltaje_n_t: ""
}
export const MedicionesACItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editMedicionesAC } = useSelector((state: RootState) => state.ui);
  const { mediciones_ac = {} } = activeEquipo;
  const { corriente_fase_a = "", corriente_fase_b = "", corriente_fase_c = "", corriente_neutro = "", voltaje_l_l = "", voltaje_l_n = "", voltaje_n_t = "" } = mediciones_ac;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setMedicionesACEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Mediciones (AC)
      </IonLabel>
      {Object.keys(mediciones_ac).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>Corriente fase a</IonText>
          <IonNote slot="end">{corriente_fase_a}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Corriente fase b</IonText>
          <IonNote slot="end">{corriente_fase_b}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Corriente fase c</IonText>
          <IonNote slot="end">{corriente_fase_c}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Corriente neutro</IonText>
          <IonNote slot="end">{corriente_neutro}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Voltaje l l</IonText>
          <IonNote slot="end">{voltaje_l_l}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Voltaje l n</IonText>
          <IonNote slot="end">{voltaje_l_n}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Voltaje n t</IonText>
          <IonNote slot="end">{voltaje_n_t}</IonNote>
        </IonItem>
        </IonList>}
      <IonItemSliding>
      <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(mediciones_ac).length !== 0  ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editMedicionesAC && <MedicionesACModal />}
        </IonItem> 
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const MedicionesACModal: FC = () => {
  const dispatch = useDispatch();   


  const { editMedicionesAC } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { corriente_fase_a, corriente_fase_b, corriente_fase_c, corriente_neutro, voltaje_l_l, voltaje_l_n, voltaje_n_t} = formValues;
  useEffect(() => {
    if (activeEquipo.mediciones_ac) {
      const u = {
        corriente_fase_a: activeEquipo.mediciones_ac.corriente_fase_a,
        corriente_fase_b: activeEquipo.mediciones_ac.corriente_fase_b,
        corriente_fase_c: activeEquipo.mediciones_ac.corriente_fase_c,
        corriente_neutro: activeEquipo.mediciones_ac.corriente_neutro,
        voltaje_l_l: activeEquipo.mediciones_ac.voltaje_l_l,
        voltaje_l_n: activeEquipo.mediciones_ac.voltaje_l_n,
        voltaje_n_t: activeEquipo.mediciones_ac.voltaje_n_t,
      };
      setFormValues(u)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    mediciones_ac: activeEquipo.mediciones_ac
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.mediciones_ac = {
      corriente_fase_a,
      corriente_fase_b,
      corriente_fase_c,
      corriente_neutro,
      voltaje_l_l,
      voltaje_l_n,
      voltaje_n_t     
    }
    dispatch(startEquipoUpdated(equipo));
    dispatch(setMedicionesACEditHide())
  };

  const handleClose = () => {
    dispatch(setMedicionesACEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editMedicionesAC} cssClass='modal-equipo'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Mediciones AC</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
              <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
              Corriente fase a
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_fase_a"
                value={corriente_fase_a}
                onIonChange={handleInputChange}
                placeholder="Corriente fase a"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
              Corriente fase b
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_fase_b"
                value={corriente_fase_b}
                onIonChange={handleInputChange}
                placeholder="Corriente fase b"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
              Corriente fase c
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_fase_c"
                value={corriente_fase_c}
                onIonChange={handleInputChange}
                placeholder="Corriente fase c"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente neutro
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_neutro"
                value={corriente_neutro}
                onIonChange={handleInputChange}
                placeholder="Corriente neutro"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje l l
              </IonLabel>
              <IonInput
                type="text"
                name="voltaje_l_l"
                value={voltaje_l_l}
                onIonChange={handleInputChange}
                placeholder="Voltaje l l"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje l n
              </IonLabel>
              <IonInput
                type="text"
                name="voltaje_l_n"
                value={voltaje_l_n}
                onIonChange={handleInputChange}
                placeholder="Voltaje l n"
                inputMode="decimal"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje n t
              </IonLabel>
              <IonInput
                type="text"
                name="voltaje_n_t"
                value={voltaje_n_t}
                onIonChange={handleInputChange}
                placeholder="Voltaje n t"
                inputMode="decimal"
              ></IonInput>
            </IonItem>           
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}