import { FC, useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonContent,
  IonIcon,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { setActiveLoading, setModalTypeHide, setToast } from "../../actions/ui";
import { Type } from "../../interfaces/Type";
import {
  clearActiveType,
  startTypeUpdated,
  tipoStartAddNew,
} from "../../actions/types";
import { closeOutline } from "ionicons/icons";

const initType = {
  name: "",
};

export const TypeScreen: FC = () => {
  const dispatch = useDispatch();
  const { activeType } = useSelector((state: RootState) => state.type);
  const [formValues, setFormValues] = useState(initType);

  const { name } = formValues;

  useEffect(() => {
    if (activeType) {
      const tipo = {
        name: activeType.name,
      };
      setFormValues(tipo);
    } else {
      setFormValues(initType);
    }
  }, [activeType]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const tipo: Type = {
    id: "",
    name,
    user: {
      _id: "",
      name: "",
    },
  };

  const handleSumit = (e: any) => {
    e.preventDefault();

    if (name.length <= 1) {
      const toast = {
        title: "Error",
        msg: "El nombre es un campo obligatorio",
        color: "danger",
      };

      dispatch(setToast(toast));
      return;
    }
    dispatch(setActiveLoading());
    if (activeType) {
      tipo.id = activeType.id;
      tipo.user = activeType.user;
      dispatch(startTypeUpdated(tipo));
      dispatch(clearActiveType());
    } else {
      dispatch(tipoStartAddNew(tipo));
    }
  };

  const setShowModal = () => {
    setFormValues(initType);
    dispatch(clearActiveType());
    dispatch(setModalTypeHide());
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle> {activeType ? "Actualizar" : "Registrar tipo"}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={setShowModal}>
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSumit}>
          <IonItem>
            <IonLabel className="item-label" position="floating">
              Nombre
            </IonLabel>
            <IonInput
              type="text"
              name="name"
              value={name}
              onIonChange={handleInputChange}
              autocomplete="off"
              placeholder="Nombre"
            ></IonInput>
          </IonItem>
          <IonItem lines="none" className="marca-item">
            <IonButton slot="end" fill="clear" type="submit">
              Guardar
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={setShowModal}>
              Cerrar
            </IonButton>
          </IonItem>
        </form>
      </IonContent>
    </>
  );
};
