import { useState } from "react";
import { camera, trash, close, save, createOutline, arrowUndoOutline } from 'ionicons/icons';
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonActionSheet,
  IonCard,
  IonSpinner,
} from "@ionic/react";
import { usePhoto } from "../../hooks/usePhoto";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../reducers/rootReducer";
import { setActiveLoading, startAddImageLocalDescripcionSwow } from '../../actions/ui';
import { IonCardContent, IonCardSubtitle } from '@ionic/react';
import { startClearedLocalImages, startSetActiveImageLocal, startSetInactiveImageLocal } from '../../actions/images';
import { AddImageDescriptionGallery } from "./AddImageDescriptionGallery";

export const GalleryScreen = ({history}: any) => {
  const dispatch = useDispatch();
  const {localImages: images} = useSelector((state: RootState)=>state.image)
  const {editLocalImageDescription, activeLoading} = useSelector((state: RootState)=>state.ui);
  const {takePhoto, deletePicture, savePicture} = usePhoto();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [description, setDescription] = useState('');
  
  const handleShowActionSheetGallery = (img: string, description: string) => {
    dispatch(startSetActiveImageLocal(img))   
    setDescription(description);
    setShowActionSheet(true);
  };
  
  const submitSavePicture = async () => {
    dispatch(setActiveLoading());
    await savePicture();    
      if(!activeLoading){
        history.push('/equipo');
      }   
  }
  const goBack = () => {
    history.push('/equipo');
    dispatch(startClearedLocalImages())
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          {images.map((img: any, index: number) => (            
            <IonCol size="6" key={index} onClick={()=>handleShowActionSheetGallery(img, img.description)}>
              <IonCard>
              {!img.image && <IonSpinner name="bubbles" />}
                <IonImg
                  alt={img.description}
                  src={img.image}
                />
                <IonCardContent>
                  <IonCardSubtitle>{img.description}</IonCardSubtitle>
                </IonCardContent>
              </IonCard>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      <IonFab vertical="top" horizontal="center" slot="fixed">
        <IonFabButton onClick={submitSavePicture}>
          <IonIcon icon={save}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonFab vertical="bottom" horizontal="center" slot="fixed">
        <IonFabButton onClick={() => takePhoto()}>
          <IonIcon icon={camera}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonFab vertical="center" horizontal="end" slot="fixed">
        <IonFabButton onClick={goBack}>
          <IonIcon icon={arrowUndoOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="my-custom-class"
        buttons={[
          {
            text: "Agregar Descripción",
            icon: createOutline,
            handler: () => {
              dispatch(startAddImageLocalDescripcionSwow())
            }
          },
          {
            text: "Eliminar",
            icon: trash,
            handler: () => {
              deletePicture();
            }
          },
          {
            text: "Cancelar",
            icon: close,
            role: "cancel",
            handler: () => {
              dispatch(startSetInactiveImageLocal())
            }
          }
        ]}
      />
      {editLocalImageDescription && <AddImageDescriptionGallery description={description} />}
    </>
  );
};