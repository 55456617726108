import { FC, useEffect, useState } from "react";
import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonText } from "@ionic/react";
import { addCircleOutline, chevronForwardOutline, createOutline, trashOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { centralSetActive, clearActiveCentral, startLoadCentrales } from "../../actions/central";
import { setModalCentralShow, startSetActiveAlert } from "../../actions/ui";
import { AlertComponent } from "../ui/AlertComponent";

export const CentralList: FC = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(startLoadCentrales());
    },[dispatch])
    const { centrales } = useSelector((state: RootState) => state.central); 
    const handleDelete = (central: any) => {        
        const alert ={
            active: true,
            header: "Eliminar central",
            message: `La central ${central.name} será eliminada`,
            tipo: 'central'          
        }
        dispatch(startSetActiveAlert(alert))
        dispatch(centralSetActive(central));  
    }

    const onDesactive = () => {
        dispatch(clearActiveCentral());
    };

    const handleUpdate =(central: any) => {
        dispatch(centralSetActive(central));
        dispatch(setModalCentralShow())
    };

    const handleAdd = () => {
        dispatch(setModalCentralShow());
    };

    return (
        <>
            <IonList>
                <IonListHeader>
                    <IonLabel class="title">Centrales</IonLabel>
                    <IonButton onClick={handleAdd}><IonIcon className="icon-list" color="success" icon={addCircleOutline}></IonIcon></IonButton>
                </IonListHeader>

                {
                    centrales.map((c: any ) => 
                       (<IonItemSliding key={c.id}>
                            <IonItem>
                                <IonText><h6>{c.name}</h6></IonText>
                            <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
                            </IonItem>
                            <IonItemOptions side="end">
                            <IonItemOption color="white" onClick={()=>{handleUpdate(c)}}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
                            <IonItemOption color="white" onClick={()=>{handleDelete(c)}}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
                            </IonItemOptions>
                        </IonItemSliding>
                       )
                    )
                }
            </IonList>
            <AlertComponent onDesactive={onDesactive} />
        </>
    );
};