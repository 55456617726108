import { fetchConToken } from "../helpers/fetch";
import { Canalizacion } from "../interfaces/Canalizacion";
import { types } from "../types/types";
import { setInactiveLoading, setModalCanalHide, setToast } from "./ui";

export const startLoadCanalizaciones = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken("canalizaciones", {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(loadCanalizaciones(body.canalizaciones))
        } else {
            console.log(body.msg);
        }
    };
};

const loadCanalizaciones = (canalizaciones: Canalizacion) => ({
    type: types.canalStartLoad,
    payload: canalizaciones
})

export const canalizacionSetActive = (canalizacion: Canalizacion) => ({
    type: types.canalSetActive,
    payload: canalizacion
});

export const clearActiveCanalizacion = () => ({
    type: types.canalClearActive
});

export const canalizacionStartAddNew = (canalizacion: Canalizacion) => {
    return async (dispatch: any, getState: any) => {
        const { uid, name } = getState().auth;
        try {
            const resp = await fetchConToken("canalizaciones", canalizacion, "POST");
            const body = await resp.json();
            if (body.ok) {                
                canalizacion.id = body.canalizacion.id;
                canalizacion.user = {
                    _id: uid,
                    name: name,
                };
                dispatch(canalizacionAddNew(canalizacion));
                const toast = {
                    title: '',
                    msg: 'El item ha sido agregado exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalCanalHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            dispatch(setToast(toast))
        }
    };
};

const canalizacionAddNew = (canalizacion: Canalizacion) => ({
    type: types.canalStartAdd,
    payload: canalizacion
});

export const startCanalizacionUpdated = (canalizacion: Canalizacion) => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().canalizacion.activeCanalizacion;
        try {
            const resp = await fetchConToken(`canalizaciones/${id}`, canalizacion, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(canalizacionUpdated(body.canalizacion));
                const toast = {
                    title: '',
                    msg: 'El item ha sido actualizado exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast));
                dispatch(setModalCanalHide());
            } else {
                if(body.msg){
                    const toast = {
                        title: 'Error',
                        msg:body.msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast))
                }else{
                    let msg = "";
                    const errors = JSON.parse(JSON.stringify(body.errors));
                    for (const i in errors) {
                        msg = errors[i].msg;
                    }
                    console.log(`Error ${msg}`);
                    const toast = {
                        title: 'Error',
                        msg,
                        color: "danger"
    
                    }
                    dispatch(setInactiveLoading());
                    await dispatch(setToast(toast));
                }
            }
        } catch (error) {
            console.log(error);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    };
};

const canalizacionUpdated = (canalizacion: Canalizacion) => ({
    type: types.canalStartUpdated,
    payload: canalizacion,
});

export const starCanalizacionDeleted = () => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().canalizacion.activeCanalizacion;
        try {
            const resp = await fetchConToken(`canalizaciones/${id}`, {}, "DELETE");
            const body = await resp.json();
            if (body.ok) {
                dispatch(canalizacionDeleted());
                const toast = {
                    title: '',
                    msg: 'El item ha sido eliminada exitosamente',
                    color: "success"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))
            } else {
                console.log(body)
                let msg = "";
                const errors = JSON.parse(JSON.stringify(body.errors));
                for (const i in errors) {
                    msg = errors[i].msg;
                }
                console.log(`Error ${msg}`);
                const toast = {
                    title: 'Error',
                    msg,
                    color: "danger"

                }
                dispatch(setInactiveLoading());
                await dispatch(setToast(toast))

            }
        } catch (error) {
            console.error(`Error ${error}`);
            const toast = {
                title: 'Error',
                msg: "Contacta al administrador",
                color: 'danger'
            }
            dispatch(setInactiveLoading());
            await dispatch(setToast(toast))
        }
    }
};

const canalizacionDeleted = () => ({
    type: types.canalStartDeleted,
});
