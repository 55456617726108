import { types } from "../types/types";

const initialState = {
  editTag: false,
  editDescription: false,
  centralModal: false,
  equipoModal: false,
  marcaModal: false,
  canalModal: false,
  editModal: false,
  editEstado: false,
  editModelo: false,
  editFrecuencia: false,
  editVoltajeNominal: false,
  editCorrienteNominal: false,
  editUbicacion: false,
  editFechaInstalacion: false,
  editMarca: false,
  editNumero: false,
  typeModal: false,
  labelModal: false,
  editCapacidad: false,
  editUnidades: false,
  editTableros: false,
  editProtecciones: false,
  editMedicionesAC: false,
  editMedicionesCC: false,
  editCanalizaciones: false,
  editAlimentadoresAC: false,
  editAlimentadoresCC: false,
  editCircuitosMT: false,
  editTransformadoresM: false,
  editTransformadores: false,
  editGeneradores: false,
  editTransferencias: false,
  editEquipoRespaldo: false,
  editNumeroCelda: false,
  editRectificador: false,
  editInversor: false,
  editUps: false,
  editAires: false,
  editCeldasMto: false,
  editImageDescription: false,
  editLocalImageDescription: false,
  modalUpdatePassword: false,
  activeLoading: false,
  activeLoadingData: false,
  isCheking: false,
  desdeShow: false,
  hastaShow: false,
  editObservaciones: false,
  activeAlert: {
    active: false,
    header: "",
    message: "",
    tipo: "",
  },
  toast: {
    color: "",
    header: "",
    msg: "",
    present: false,
  },
  toastFacil: {
    color: "",
    header: "",
    msg: "",
    present: false,
  },
};
export const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.uiEditModalObservacionesShow:
      return {
        ...state,
        editObservaciones: true,
      }
    case types.uiEditModalObservacionesHide:
      return {
        ...state,
        editObservaciones: false,
      }
    case types.uiModalDesdeSetShow:
      return {
        ...state,
        desdeShow: true,
      }
    case types.uiModalDesdeSetHide:
      return {
        ...state,
        desdeShow: false,
      }
    case types.uiModalHastaSetShow:
      return {
        ...state,
        hastaShow: true,
      }
    case types.uiModalHastaSetHide:
      return {
        ...state,
        hastaShow: false,
      }
    case types.uiSetActiveLoadingData:
      return {
        ...state,
        activeLoadingData: true,
      };

    case types.uiSetInactiveLoadingData:
      return {
        ...state,
        activeLoadingData: false,
      };

    case types.uiModalEditUnidadesSetShow:
      return {
        ...state,
        editUnidades: true,
      };

    case types.uiModalEditUnidadesSetHide:
      return {
        ...state,
        editUnidades: false,
      };

    case types.uiModalNumeroEditShow:
      return {
        ...state,
        editNumero: true,
      };

    case types.uiModalNumeroEditHide:
      return {
        ...state,
        editNumero: false,
      };
    case types.uiModalEstadoEditShow:
      return {
        ...state,
        editEstado: true,
      };

    case types.uiModalEstadoEditHide:
      return {
        ...state,
        editEstado: false,
      };

    case types.uidModalDescriptionSetShow:
      return {
        ...state,
        editDescription: true,
      };

    case types.uidModalDescriptionSetHide:
      return {
        ...state,
        editDescription: false,
      };
    case types.uiModalTagSetShow:
      return {
        ...state,
        editTag: true,
      };

    case types.uiModalTagSetHide:
      return {
        ...state,
        editTag: false,
      };

    case types.uiModalUpdatePasswordSetShow:
      return {
        ...state,
        modalUpdatePassword: true,
      };

    case types.uiModalUpdatePasswordSetHide:
      return {
        ...state,
        modalUpdatePassword: false,
      };

    case types.uiChekingCreate:
      return {
        ...state,
        isCheking: true,
      };

    case types.uiunChekingCreate:
      return {
        ...state,
        isCheking: false,
      };

    case types.uiModalEditCeldasMtoSetShow:
      return {
        ...state,
        editCeldasMto: true,
      };
    case types.uiModalEditCeldasMtoSetHide:
      return {
        ...state,
        editCeldasMto: false,
      };

    case types.uiModalEditAiresSetShow:
      return {
        ...state,
        editAires: true,
      };
    case types.uiModalEditAiresSetHide:
      return {
        ...state,
        editAires: false,
      };

    case types.uiModalEditUpsSetShow:
      return {
        ...state,
        editUps: true,
      };
    case types.uiModalEditUpsSetHide:
      return {
        ...state,
        editUps: false,
      };

    case types.uiModalEditInversorSetShow:
      return {
        ...state,
        editInversor: true,
      };
    case types.uiModalEditInversorSetHide:
      return {
        ...state,
        editInversor: false,
      };

    case types.uiModalEditRectificadorSetShow:
      return {
        ...state,
        editRectificador: true,
      };
    case types.uiModalEditRectificadorSetHide:
      return {
        ...state,
        editRectificador: false,
      };

    case types.uiModalEditNumeroCeldaSetShow:
      return {
        ...state,
        editNumeroCelda: true,
      };
    case types.uiModalEditNumeroCeldaSetHide:
      return {
        ...state,
        editNumeroCelda: false,
      };

    case types.uiModalEditEquipoRespladoSetShow:
      return {
        ...state,
        editEquipoRespaldo: true,
      };
    case types.uiModalEditEquipoRespladoSetHide:
      return {
        ...state,
        editEquipoRespaldo: false,
      };

    case types.uiSetActiveAlert:
      return {
        ...state,
        activeAlert: action.payload,
      };

    case types.uiSetInactiveAlert:
      return {
        ...state,
        activeAlert: {
          active: false,
          header: "",
          message: "",
        },
      };

    case types.uiSetActiveLoading:
      return {
        ...state,
        activeLoading: true,
      };

    case types.uiSetInactiveLoading:
      return {
        ...state,
        activeLoading: false,
      };

    case types.uiEditModalAddDescriptionShow:
      return {
        ...state,
        editImageDescription: true,
      };
    case types.uiEditModalAddDescriptionHide:
      return {
        ...state,
        editImageDescription: false,
      };

    case types.uiEditModalAddLocalDescriptionShow:
      return {
        ...state,
        editLocalImageDescription: true,
      };
    case types.uiEditModalAddLocalDescriptionHide:
      return {
        ...state,
        editLocalImageDescription: false,
      };

    case types.uiModalEditTransferenciasAutomaticasSetShow:
      return {
        ...state,
        editTransferencias: true,
      };

    case types.uiModalEditTransferenciasAutomaticasSetHide:
      return {
        ...state,
        editTransferencias: false,
      };

    case types.uiModalEditGeneradoresSetShow:
      return {
        ...state,
        editGeneradores: true,
      };

    case types.uiModalEditGeneradoresSetHide:
      return {
        ...state,
        editGeneradores: false,
      };

    case types.uiModalEditTrasformadoresSetShow:
      return {
        ...state,
        editTransformadores: true,
      };

    case types.uiModalEditTrasformadoresSetHide:
      return {
        ...state,
        editTransformadores: false,
      };

    case types.uiModalEditTrasformadoresMedicionSetShow:
      return {
        ...state,
        editTransformadoresM: true,
      };

    case types.uiModalEditTrasformadoresMedicionSetHide:
      return {
        ...state,
        editTransformadoresM: false,
      };

    case types.uiModalEditCircuitosMediaTensionSetShow:
      return {
        ...state,
        editCircuitosMT: true,
      };

    case types.uiModalEditCircuitosMediaTensionSetHide:
      return {
        ...state,
        editCircuitosMT: false,
      };

    case types.uiModalEditAlimentadoresCCSetShow:
      return {
        ...state,
        editAlimentadoresCC: true,
      };

    case types.uiModalEditAlimentadoresCCSetHide:
      return {
        ...state,
        editAlimentadoresCC: false,
      };

    case types.uiModalEditAlimentadoresACSetShow:
      return {
        ...state,
        editAlimentadoresAC: true,
      };

    case types.uiModalEditAlimentadoresACSetHide:
      return {
        ...state,
        editAlimentadoresAC: false,
      };

    case types.uiModalEditCanalizacionSetShow:
      return {
        ...state,
        editCanalizaciones: true,
      };

    case types.uiModalEditCanalizacionSetHide:
      return {
        ...state,
        editCanalizaciones: false,
      };

    case types.uiModalEditMedicionesCCSetShow:
      return {
        ...state,
        editMedicionesCC: true,
      };

    case types.uiModalEditMedicionesCCSetHide:
      return {
        ...state,
        editMedicionesCC: false,
      };

    case types.uiModalEditMedicionesACSetShow:
      return {
        ...state,
        editMedicionesAC: true,
      };

    case types.uiModalEditMedicionesACSetHide:
      return {
        ...state,
        editMedicionesAC: false,
      };
    case types.uiModalEditProteccionesSetShow:
      return {
        ...state,
        editProtecciones: true,
      };

    case types.uiModalEditProteccionesSetHide:
      return {
        ...state,
        editProtecciones: false,
      };

    case types.uiModalEditTablerosSetShow:
      return {
        ...state,
        editTableros: true,
      };

    case types.uiModalEditTablerosSetHide:
      return {
        ...state,
        editTableros: false,
      };
    case types.uiModalEditCapacidadSetShow:
      return {
        ...state,
        editCapacidad: true,
      };

    case types.uiModalEditCapacidadSetHide:
      return {
        ...state,
        editCapacidad: false,
      };

    case types.uiModalEditMarcaSetShow:
      return {
        ...state,
        editMarca: true,
      };

    case types.uiModalEditMarcaSetHide:
      return {
        ...state,
        editMarca: false,
      };
    case types.uiEditFechaInstalacionSetShow:
      return {
        ...state,
        editFechaInstalacion: true,
      };

    case types.uiEditFechaInstalacionSetHide:
      return {
        ...state,
        editFechaInstalacion: false,
      };

    case types.uiModalCentralSetShow:
      return {
        ...state,
        centralModal: true,
      };

    case types.uiModalCentralSetHide:
      return {
        ...state,
        centralModal: false,
      };

    case types.uiModalMarcaSetShow:
      return {
        ...state,
        marcaModal: true,
      };

    case types.uiModalMarcaSetHide:
      return {
        ...state,
        marcaModal: false,
      };

    case types.uiModalTypeSetShow:
      return {
        ...state,
        typeModal: true,
      };

    case types.uiModalTypeSetHide:
      return {
        ...state,
        typeModal: false,
      };

    case types.uiModalLabelSetShow:
      return {
        ...state,
        labelModal: true,
      };

    case types.uiModalLabelSetHide:
      return {
        ...state,
        labelModal: false,
      };

    case types.uiModalCanalSetShow:
      return {
        ...state,
        canalModal: true,
      };

    case types.uiModalCanalSetHide:
      return {
        ...state,
        canalModal: false,
      };

    case types.uiEditModalSetShow:
      return {
        ...state,
        editModal: true,
      };

    case types.uiEditModalSetHide:
      return {
        ...state,
        editModal: false,
      };

    case types.uiEditModeloSetShow:
      return {
        ...state,
        editModelo: true,
      };

    case types.uiEditModeloSetHide:
      return {
        ...state,
        editModelo: false,
      };

    case types.uiEditFrecuenciaSetShow:
      return {
        ...state,
        editFrecuencia: true,
      };

    case types.uiEditFrecuenciaSetHide:
      return {
        ...state,
        editFrecuencia: false,
      };

    case types.uiEditCorrienteNominalSetShow:
      return {
        ...state,
        editCorrienteNominal: true,
      };

    case types.uiEditCorrienteNominalSetHide:
      return {
        ...state,
        editCorrienteNominal: false,
      };

    case types.uiEditVoltajeNominalShow:
      return {
        ...state,
        editVoltajeNominal: true,
      };

    case types.uiEditVoltajeNominalHide:
      return {
        ...state,
        editVoltajeNominal: false,
      };

    case types.uiEditUbicacionSetShow:
      return {
        ...state,
        editUbicacion: true,
      };

    case types.uiEditUbicacionSetHide:
      return {
        ...state,
        editUbicacion: false,
      };

    case types.uiModalEquipoSetShow:
      return {
        ...state,
        equipoModal: true,
      };

    case types.uiModalEquipoSetHide:
      return {
        ...state,
        equipoModal: false,
      };
    case types.uiToastSetShow:
      return {
        ...state,
        toast: {
          color: action.payload.color,
          header: action.payload.title,
          msg: action.payload.msg,
          present: true,
        },
      };
    case types.uiToastSetActive:
      return {
        ...state,
        toastFacil: {
          color: action.payload.color,
          header: action.payload.title,
          msg: action.payload.msg,
          present: action.payload.present,
        },
      };
    case types.uiToastSetInactive:
      return {
        ...state,
        toastFacil: {
          color: "",
          header: "",
          msg: "",
          present: false,
        },
      };
    case types.uiToastSetHide:
      return {
        ...state,
        toast: {
          color: "",
          header: "",
          msg: "",
          present: false,
        },
      };

    default:
      return state;
  }
};
